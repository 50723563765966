
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useContext, useEffect, useState } from "react";
import { apiRequest } from "../../../../Helpers/General";
import { useHistory } from "react-router";
import { MasterContext } from "../../../../Context";
const MembershipUpgrade = (props) => {
  const history = useHistory();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const { genralMasterData } = useContext(MasterContext);
  let upgradeLabel = genralMasterData.SUBSCRIPTION;
  const [pricingDetail, setPricingDetail] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [planDetail, setplanDetail] = useState();
  useEffect(() => {
    var plan = JSON.parse(localStorage.getItem("currentPlan"));
    setplanDetail(plan);
  }, [])
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: "102" },
        };
        const apiResult = await apiRequest(
          "get_package_plan_page_detail",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          setPricingDetail(apiResult.data.data);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating]);
  const goToBuyPlan = (data) => {
    localStorage.setItem("planDetail", JSON.stringify(data.package_master_info));
    history.push("/MembershipCheckout")
  }

  return (
    <div className="inner-page membership-page pricing-page after-login-page">
      <div className="container">
        <div className="row">
          <div className="page-title text-center">
            <h1>{upgradeLabel && upgradeLabel.gm_heading ? upgradeLabel.gm_heading : "Change Subscription Package"}</h1>
            <p className="common-paragraph">
            {/*upgradeLabel && upgradeLabel.gm_short_description ? upgradeLabel.gm_short_description : "Change Subscription Package"*/}
             {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sem nunc, sollicitudin maximus erat eu, vulputate consequat tellus. Vivamus nec luctus metus. */}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="table centered" id="section-price">
            <div className="table-row">
              <div className="column plan-details">
                <ul className="price">
                  <li className="plan-price">{upgradeLabel && upgradeLabel.gm_description ? upgradeLabel.gm_description : "Subscription Plan"}</li>

                  {pricingDetail.package_parameters_list &&
                    pricingDetail.package_parameters_list.map((item, key) => (
                      <li key={key}>
                       <div className="price-name d-flex">
                              {item.parameter_name}
                              
                              {item.help_text && item.help_text !== "" && (
                                <div className="help-link ms-2">
                                  <i className="mdi mdi-help-circle"></i>
                                  <span className="tooltiptext"> {item.help_text ? "" + item.help_text + "" : ""}</span>
                                </div>
                              )}
                            </div>
                        <span className="item-description">
                          {item.description ? "(" + item.description + ")" : ""}
                        </span>
                        
                      </li>
                    ))}

                  <li className="price-action"></li>
                </ul>
              </div>
              {pricingDetail.data_formation &&
                pricingDetail.data_formation.return_data &&
                pricingDetail.data_formation.return_data.map((item, key) => (
                  <div className="column  " key={key}>
                    <ul className="price">
                      <li
                        className="price-header"
                        style={{
                          backgroundColor:
                            item.package_master_info.package_color,
                        }}
                      >
                        {item.package_master_info.package_name}
                      </li>
                      <li className="plan-price">
                        {item.package_master_info.discount_value > 0 && (
                          <span className="featured-ribbon">
                            Save
                            {Math.round(
                              item.package_master_info.discount_value
                            )}
                            %
                          </span>
                        )}

                        {item.package_master_info.discount_value > 0 && (
                          <span className="package-price">
                            {item.package_master_info.package_currency_symbol}
                            {Math.round(item.package_master_info.package_price)}
                          </span>
                        )}
                        <span className="discount-price">
                          {item.package_master_info.package_currency_symbol}
                          {Math.round(
                            item.package_master_info.discounted_price
                          )}
                        </span>
                        {item.package_master_info.discount_value > 0 && (
                          <span className="duration-time">
                            /{item.package_master_info.duration_name}
                          </span>
                        )}
                      </li>

                      {pricingDetail.package_parameters_list.map((row, index) => {
                            const filtered = item.package_params.filter((data) => {
                              return data.package_parameters_id.includes(row.package_parameters_id);
                            });
                            if (filtered.length > 0) {
                              // eslint-disable-next-line
                              return item.package_params.map((items, key) => {
                                if (row.package_parameters_id === items.package_parameters_id) {
                                  if (items.package_item_wise === "No") {
                                    return (<li key={key}>
                                              <span className="mobile-desc">{items.package_parameter_name}</span>
                                              <i className="mdi mdi-check"></i>
                                            </li>);
                                  }
                                  else
                                  {
                                    return (<li key={key}>
                                      <span className="mobile-desc">{items.package_parameter_name}</span>
                                      <span className="value-text">{items.package_txt}</span>
                                    </li>);
                                  }
                                  /* return (
                                    <li key={key}>
                                      <span className="mobile-desc">{items.package_parameter_name}</span>

                                      {items.package_parameters_allow_access === "Yes" &&
                                      items.package_item_wise === "Yes" &&
                                      items.package_parameters_items_count === "0" ? (
                                        "Unlimited"
                                      ) : items.package_parameters_allow_access === "Yes" &&
                                        items.package_item_wise === "Yes" &&
                                        items.package_parameters_items_count > 0 ? (
                                        items.package_parameters_items_count
                                      ) : items.package_parameters_allow_access === "Yes" && items.package_item_wise === "No" ? (
                                        <i className="mdi mdi-check"></i>
                                      ) : (
                                        <i className="mdi mdi-close"></i>
                                      )}
                                    </li>
                                  ); */
                                }
                              });
                            } else {
                              return (
                                <li key={index}>
                                  <span className="mobile-desc">{row.parameter_name}</span>
                                  <i className="mdi mdi-close"></i>
                                </li>
                              );
                            }
                          })}                      
                      {item.package_master_info.package_name === "Free Plan" ?<li className="price-action"> <button className="price-btn" style={{
                            backgroundColor:
                              item.package_master_info.package_color,
                          }} type="button" disabled title="You are not allowed to choose this plan"
                          >Choose Plan</button>
                           
                        </li>
                      
                      :item.package_master_info.package_name !== (planDetail && planDetail.package_name) ?
                        <li className="price-action" >

                          <form action={`${process.env.REACT_APP_BACKEND_URL}upgradeplanaction.html`} method="POST" >
                          <div className="form-input-div">
                          <input
                            type="hidden"
                            id="upgrade_plan_id"
                            name="upgrade_plan_id"
                            className="form-control"
                            defaultValue={item.package_master_info.package_master_id}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="current_plan_id"
                            name="current_plan_id"
                            defaultValue={planDetail && planDetail.package_master_id}
                          />
                           <input
                            type="hidden"
                            id="user_id"
                            name="user_id"
                            className="form-control"
                            defaultValue={props&&props.userProfile.user_master_id}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="amount"
                            name="amount"
                            defaultValue={item.package_master_info.discounted_price}
                          />

                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="package_duration"
                            name="package_duration"
                            className="form-control"
                            defaultValue={item.package_master_info.package_duration_days}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="currency_id"
                            name="currency_id"
                            defaultValue={item.package_master_info.currency_master_id}
                          />
                          </div>
                          <div className="form-input-div">
                          <input
                            type="hidden"
                            id="currency"
                            name="currency"
                            defaultValue={item.package_master_info.package_currency_code}
                          />
                          </div>
                          <div>
                           <input
                            type="hidden"
                            id="username"
                            name="username"
                            className="form-control"
                            defaultValue={props&&props.userProfile.first_name+" "+props.userProfile.last_name}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="email_id"
                            name="email_id"
                            defaultValue={props&&props.userProfile.email}
                          />
                          </div>
                          <div className="form-input-div">
                          <input
                            type="hidden"
                            id="mobile_no"
                            name="mobile_no"
                            defaultValue={props&&props.userProfile.mobile_no}
                          />
                          </div>
                          <button className="price-btn" style={{
                            backgroundColor:
                              item.package_master_info.package_color,
                          }} type="submit"
                          >Choose Plan</button>
                            </form>
                        </li> : <li className="price-action active" style={{
                          backgroundColor:
                            item.package_master_info.package_color,
                        }}>
                          <button className="price-btn" style={{
                            backgroundColor:
                              item.package_master_info.package_color,
                          }}
                            onClick={() => goToBuyPlan(item)}
                          >Get Started</button>
                        </li>}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipUpgrade;
