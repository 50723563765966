import React, { useState, useEffect } from "react";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useParams } from "react-router-dom";
import iconTime from "../../../../asset/svg/icon-time.svg";
import iconDate from "../../../../asset/svg/icon-date.svg";
import iconType from "../../../../asset/svg/icon-type.svg";
import iconSeat from "../../../../asset/svg/icon-seat.svg";
import { apiRequest } from "../../../../Helpers/General";
import moment from "moment";
import Modal from "../../../../componants/Modal/Modal";
import SignInModal from "../SignInModal/SignInModal";
import swal from "sweetalert";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import { HashLink } from "react-router-hash-link";
const NetworkingDetail = (props) => {
  let userId = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  let { id } = useParams();
  let ID = id.split("-").pop();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const [networkMetingDetail, setNetworkMetingDetail] = useState([]);
  const [networkMetingDetailTrn, setNetworkMetingDetailTrn] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  // eslint-disable-next-line
  const [bookStatus, setbookStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            country_id: "102", network_meeting_id: ID, user_id: userId,
            user_type: props.userProfile ? props.userProfile.user_type : ""
          },
        };
        const apiResult = await apiRequest(
          "get_network_meetings_detail",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          setNetworkMetingDetail(
            apiResult.data.data.modify_data.return_data[0]
              .meeting_master_details
          );

          setNetworkMetingDetailTrn(
            apiResult.data.data.modify_data.return_data[0].meeting_trans
          );
          setIsPageUpdating(false);
        } else {

        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
    // eslint-disable-next-line
  }, [isPageUpdating, id, userId]);

  const cancelBooking = async (rowid) => {

    try {
      setLoading(true);
      let parms = {
        network_meeting_id: rowid.meeting_id,
        booking_type: "networkmeeting",
        booking_date_time: moment().format("YYYY-MM-DD HH:MM:SS"),
        booking_status: "Booked",
        user_id: props.userProfile && props.userProfile.user_master_id,
        country_id: "102",

      };
      const apiSetting = {
        method: "POST",
        apiParams: parms,
      };
      const apiResult = await apiRequest(
        "regester_for_network_meeting",
        apiSetting
      );
      if (apiResult.data.settings.success === "1") {
        setIsPageUpdating(true);
        swal({
          title: "Success",
          text: "Registered Successfully.",
          icon: "success",
          button: {
            text: "Done",
            closeModal: true,
          },
          dangerMode: false,
        });

      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const meta = {
    title: networkMetingDetail.meta_title && networkMetingDetail.meta_title,
    description: networkMetingDetail.meta_description && networkMetingDetail.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: networkMetingDetail.meta_key_words && networkMetingDetail.meta_key_words,
      },
    },
  };

  if (isPageUpdating) {
    return <Loader />
  } else if (networkMetingDetail.length === 0) {
    return <NoRecord pageName="Data" />
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page networking-detail-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                
                <div className={"common-page-content networking-detail-content " + networkMetingDetail.meeting_for.toLowerCase()}>
                  <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="event-head d-inline-flex align-item-center m-auto">
                        <div className="date-stats d-flex flex-column align-items-center">
                          <span> {networkMetingDetail.meeting_confrence_mode && networkMetingDetail.meeting_confrence_mode === "Offline"
                              ? "Offline"
                              : "Online"}</span>
                          <label>
                            {moment(
                              networkMetingDetail &&
                              networkMetingDetail.meeting_date
                            ).format("DD")}
                            <small>
                              {moment(
                                networkMetingDetail &&
                                networkMetingDetail.meeting_date
                              ).format("MMM YYYY")}
                            </small>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                      <div className="detail-content">
                        <div className="detail-content-top">
                          <div className="detail-page-title page-title">
                            <h2>
                              {networkMetingDetail &&
                                networkMetingDetail.meeting_title}
                            </h2>

                          </div>

                          {networkMetingDetailTrn && <div className="detail-page-status our-speakers">
                            <h3>Keynote Speakers</h3>
                            <div className="d-flex flex-row flex-wrap">

                              {networkMetingDetailTrn.map((item, key) => (
                                <div className="card d-flex flex-row" key={key}>
                                  <div className="card-header">
                                    <div className="profile-pic">
                                      <img src={item.trn_image || `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`} alt="" />
                                    </div>
                                  </div>
                                  <div className="card-content">
                                    <HashLink
                                      to={"/networking-details/" + id + "#key-not-speker" + item.trn_title}
                                      scroll={(el) =>
                                        el.scrollIntoView({ behavior: "auto", block: "end", bottom: 0 })
                                      }
                                    >
                                      <div className="card-title">{item.trn_title}</div>
                                    </HashLink>
                                    {/* <p className="common-paragraph">
                                        {item.trn_description}
                                      </p> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>}
                          <div className="detail-page-status d-none">
                            <div className="post-time">
                              {networkMetingDetail &&
                                networkMetingDetail.meeting_time}
                              <span>
                                {" (" + networkMetingDetail.gmt_off_set + ")"}
                              </span>
                            </div>
                            <div className="post-type ms-auto">
                              {networkMetingDetail &&
                                networkMetingDetail.meeting_for}
                            </div>
                          </div>
                        </div>
                        <div className="detail-content-mdl ">
                          <p className="common-paragraph">
                            <strong className="highlight-text">
                              {networkMetingDetail &&
                                networkMetingDetail.meeting_title}
                            </strong>
                          </p>

                          <p
                            className="common-paragraph"
                            dangerouslySetInnerHTML={{
                              __html: networkMetingDetail.meeting_long_description,
                            }}
                          >
                            {/* {networkMetingDetail &&
                          networkMetingDetail.meeting_description} */}
                          </p>
                        </div>
                      </div>
                      {networkMetingDetailTrn &&
                        <div className="row our-speaker-title" >
                          <div className="page-title  text-start">
                            <h2>More About Speakers</h2>
                          </div>
                        </div>}

                      {networkMetingDetailTrn &&
                        <div className="row">
                          <div className="our-speakers">


                            {networkMetingDetailTrn.map((item, key) => (

                              <div key={key} className="card d-flex flex-row" >
                                <div className="card-header">
                                  <div className="profile-pic">
                                    <img src={item.trn_image || `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`} alt="" />
                                  </div>
                                </div>
                                <div className="card-content" id={"key-not-speker" + item.trn_title}>
                                  <div className="card-title">{item.trn_title}</div>
                                  <p className="common-paragraph">
                                    {item.trn_description}
                                  </p>
                                </div>
                              </div>

                            ))}

                          </div>
                        </div>}
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="right-search-block">
                  <div className="right-block-title">
                    <h2>
                      Register <span>for the Meeting</span>
                    </h2>
                  </div>

                  <div className="card">
                    <div className="card-content">
                      <ul>
                        <li>
                          <div className="reg-time">
                            <img src={iconTime} alt="" />
                            <strong>
                              {networkMetingDetail &&
                                networkMetingDetail.meeting_time}
                            </strong>
                            {networkMetingDetail &&
                              networkMetingDetail.host_country_name}
                            {networkMetingDetail &&
                              networkMetingDetail.country_name}
                          </div>
                        </li>
                        <li>
                          <div className="reg-date">
                            <img src={iconDate} alt="" />
                            <strong>
                              {moment(
                                networkMetingDetail &&
                                networkMetingDetail.meeting_date
                              ).format("ll")}
                            </strong>
                            {moment(
                              networkMetingDetail &&
                              networkMetingDetail.meeting_date
                            ).format("dddd")}
                          </div>
                        </li>
                        <li>
                          <div className="reg-seats">
                            <img src={iconSeat} alt="" />
                            <strong>
                              {networkMetingDetail &&
                                networkMetingDetail.meeting_allowed_members}
                            </strong>
                            Seats
                          </div>
                        </li>
                        <li>
                          <div className="reg-type">
                            <img src={iconType} className="svg" alt="" />
                            <strong>
                              {networkMetingDetail &&
                                networkMetingDetail.meeting_for}
                            </strong>
                          </div>
                        </li>
                        <li>
                          {loading ? <button
                            className=" btn-green" style={{ "height": "2rem !important" }}>
                            <span className="spinner-border text-white  text-center" role="status">
                              <span className="sr-only">Loading...</span>
                            </span> </button> : props.userProfile ? networkMetingDetail.booking_status === "Booked" ?

                              <button className={"btn-green event-" + (networkMetingDetail.booking_status).toLowerCase()}>Already Registered</button> : (
                                <button
                                  className="btn-green"
                                  onClick={() => cancelBooking(networkMetingDetail)}
                                >
                                  {bookStatus ? "Already Registered" : "Register Now"}
                                </button>
                              ) : (
                            <Modal
                              btnCoverClass="btn-green"
                              title="Sign In To Continue"
                              id="registerPopup"
                              btnTitle="Register Now"
                              btnClass=""
                              child={SignInModal}
                            />
                          )}
                        </li>
                        <li>
                          <p className="common-paragraph">
                          You can cancel your booking upto <b>{networkMetingDetail && networkMetingDetail.meeting_cancellation_allowed_hours ? networkMetingDetail.meeting_cancellation_allowed_hours : "--"}</b> hours before the
                            event start time.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default NetworkingDetail;
