import React, { useContext, useEffect, useRef } from "react";
import SelectBox from "../../../../componants/SelectBox/SelectBox";
import DatePicker from "react-date-picker";
import { useForm } from "react-hook-form";
import { useState } from "react";
import swal from "sweetalert";
import { apiRequest } from "../../../../Helpers/General";
import { MasterContext } from "../../../../Context";
import moment from "moment";
const AddJob = (props) => {
  let id = props.id || "";
  let userid = props.userId || "";
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { countrydata } = useContext(MasterContext);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [stateOption, setStateoption] = useState([]);
  const [jobDetail, setjobDetail] = useState();
  const [jobAppclosedate, setjobAppclosedate] = useState();
  const [cityOption, setcityOption] = useState([]);
  const stateRef = useRef([]);
  const countryRef = useRef([]);
  const cityJobRef = useRef([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => { if (countrydata) { setCountryOptions(countrydata) } }, [countrydata])
  useEffect(() => {
    const GetData = async () => {
      var width =  window.innerWidth;
      if(width > 800){
      window.scrollTo({ top: 50, behavior: "smooth" });
      }
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { job_master_id: id },
        };
        const apiResult = await apiRequest("get_job_detail", apiSetting);
        if (apiResult.data.settings.success === "1") {
          
          let data = apiResult.data.data.get_job_detail[0]
           let jobdeddate = data && data.job_application_deadline ? moment(data.job_application_deadline) : "";
          setjobAppclosedate(jobdeddate ? jobdeddate._d : null)
          setValue("jobAppdedline", data && data.job_application_deadline);
          setValue("designation", data && data.job_designation);
          setValue(
            "preferd_education_qualification",
            data && data.job_education_qualification
          );
          setValue("job_location", data && data.job_location);
          setValue(
            "maximum_experience_required",
            data && data.job_minimum_experiecnce
          );
          setValue("country", data && data.job_country_id);
          setValue("state", data && data.job_state_id);
          setValue("city", data && data.job_city_id);
          setValue("job_description", data && data.job_description);
          setjobDetail(data);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        // setIsPageUpdating(false);
      }
    };

    const GetState = async () => {
      try {
        if (jobDetail.job_country_id) {
          const apiSetting = {
            method: "GET",
            apiParams: { country_id: jobDetail.job_country_id },
          };
          const apiResult = await apiRequest("get_state_master", apiSetting);
          if (apiResult.data.settings.success === "1") {
            setStateoption(apiResult.data.data);
          }
        } else {
          setStateoption([]);

        }
      } catch (error) {
        console.log("error", error);
      } finally {
        // setIsPageUpdating(false);
      }
    };
    const GetCity = async () => {
      try {
        if (jobDetail && jobDetail.job_state_id) {
          const apiSetting = {
            method: "GET",
            apiParams: { state_id: jobDetail.job_state_id },
          };
          const apiResult = await apiRequest("get_city_master", apiSetting);
          if (apiResult.data.settings.success === "1") {
            setcityOption(apiResult.data.data);
          } else {
            setcityOption([]);
          }
        } else {
          setcityOption([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
      }
    };
    if (id) {
      GetData();
    }
    if (jobDetail && jobDetail.job_country_id) {
      setCountryOptions(countrydata)
      GetState();
    }
    if (jobDetail && jobDetail.job_state_id) {
      GetCity();
    }
     // eslint-disable-next-line
  }, [    id,    jobDetail && jobDetail.job_country_id,    jobDetail && jobDetail.job_state_id  ]);

  const changeCountry = async (e) => {
    setValue("country", e[0] && e[0].country_id);
    clearErrors("country");
    setSelectedCountry(e);
    if (selectedState.length > 0) {

      stateRef.current.clear();
      setSelectedState([]);
    }
    
    if (selectedCity.length > 0) {
       cityJobRef.current.clear();
      setSelectedCity([]);
      setcityOption([]);
    }
    if (e[0] && e[0].country_id) {
      const apiSetting = {
        method: "GET",
        apiParams: { country_id: e[0].country_id },
      };
      const apiResult = await apiRequest("get_state_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setStateoption(apiResult.data.data);
      }
    } else {
      setStateoption([]);
    }
  };
  const changeState = async (e) => {
    setSelectedState(e);
    setValue("state", e[0] && e[0].state_id);
    clearErrors("state");
    
    if (selectedCity.length > 0) {
       cityJobRef.current.clear();
      setSelectedCity([]);
    }
    if (e[0] && e[0].state_id) {
      const apiSetting = {
        method: "GET",
        apiParams: { state_id: e[0].state_id },
      };
      const apiResult = await apiRequest("get_city_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setcityOption(apiResult.data.data);
      } else {
        setcityOption([]);
      }
    } else {
      setcityOption([]);
    }
  };
  const onDatechange = (value) => {
    console.log("value",value)
    let datevalue = moment(value).format("YYYY-MM-DD");
    setValue("jobAppdedline", datevalue);
    setjobAppclosedate(value);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    let parms = {
      user_id: userid,
      designation: data.designation,
      education_qualification: data.preferd_education_qualification,
      minimum_experiecnce: data.maximum_experience_required,
      job_location: data.job_location,
      job_description: data.job_description,
      application_deadline: data.jobAppdedline,
      mode: id ? "edit" : "add",
      job_master_id: id ? id : "0",
      country_id: id
        ? data.country
        : selectedCountry && selectedCountry[0].country_id,
      state_id: id ? data.state : selectedState && selectedState[0].state_id,
      city_id: id
        ? data.city
        : selectedCity && selectedCity[0]
          ? selectedCity[0].city_id
          : "",
    };
    try {
      const apiSetting = {
        method: "POST",
        apiParams: parms,
      };
      const apiResult = await apiRequest("add_and_edit_job", apiSetting);
      
      if (apiResult.data.settings.success === "1") {
        swal({
          title: "Success",
          text: apiResult.data.settings.message,
          icon: "success",
          buttons: "OK",
          dangerMode: false,
        }).then((shouldCreateNew) => {
          props.setDoRefresh(true);
          props.closeModal();
          
        });
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("date",jobAppclosedate)
  return (
    <div className="inner-page add-job after-login-page">
      <div className="container">
        <div className="row">
          <form id="documents-add-hook-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="form-section">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor="username" className="float-start mb-1">
                        Designation <em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type="text"
                          id="username"
                          className="form-control"
                          placeholder="Enter Designation"
                          {...register("designation", {
                            required: "Designation is required",
                          })}
                          defaultValue={jobDetail && jobDetail.job_designation}
                          autoFocus
                        />
                        <div className="input-err">
                          {" "}
                          {errors?.designation?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor="username" className="float-start mb-1">
                        Preferred Educational Qualifications<em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type="text"
                          id="username"
                          className="form-control"
                          placeholder="Enter Preferred Educational Qualifications"
                          {...register("preferd_education_qualification", {
                            required:
                              "Preferred Educational Qualifications is required",
                          })}
                          defaultValue={
                            jobDetail && jobDetail.job_education_qualification
                          }

                        />
                        <div className="input-err">
                          {errors?.preferd_education_qualification?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor="username" className="float-start mb-1">
                        Minimum Experienced Required <em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type="number"
                          id="minimumexperincerequired"
                          min="0"
                          className="form-control"
                          placeholder="Enter Minimum Experienced Required"
                          {...register("maximum_experience_required", {
                            required:
                              "Minimum Experienced Required is required",
                            min: 0
                          })}
                          defaultValue={
                            jobDetail && jobDetail.job_minimum_experiecnce
                          }

                        />
                        <div className="input-err">
                          {errors?.maximum_experience_required?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor="username" className="float-start mb-1">
                        Job Location <em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type="text"
                          id="username"
                          className="form-control"
                          placeholder="Enter Job Location"
                          {...register("job_location", {
                            required: "Job Location is required",
                          })}
                          defaultValue={jobDetail && jobDetail.job_location}

                        />
                        <div className="input-err">
                          {errors?.job_location?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor=" " className="float-start mb-1">
                        Counrty <em>*</em>
                      </label>
                      {countryOptions && (jobDetail || !id) && <div className="form-input-div">
                        <SelectBox
                          className="mdi mdi-chevron-down"
                          name="country"
                          labelKey="country"
                          valueKey="country_id"
                          //  defaultSelected={selectedCountry}
                          defaultValue={selectedCountry}
                          options={countryOptions}
                          OnChange={changeCountry}
                          placeholder="Select value from dropdown"
                          {...register("country", {
                            required: "Counrty is required",
                          })}
                          defaultSelected={
                            countryOptions &&
                            countryOptions.filter(function (row) {
                              return (
                                jobDetail &&
                                jobDetail.job_country_id
                                  .split(",")
                                  .includes(row.country_id)
                              );
                            })
                          }
                          ref={countryRef}
                        />
                        <div className="input-err">
                          {errors?.country?.message}
                        </div>
                      </div>}
                    </div>
                  </div>
                  {stateOption && ((jobDetail &&jobDetail.job_state_id) || !id)  && <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor="username" className="float-start mb-1">
                        State <em>*</em>
                      </label>
                      <div className="form-input-div">
                        <SelectBox
                          className="mdi mdi-chevron-down"
                          name="state"
                          labelKey="state"
                          valueKey="state_id"
                          // defaultSelected={selectedState}
                          defaultValue={selectedState}
                          options={stateOption || []}
                          OnChange={changeState}
                          placeholder="Select value from dropdown"
                          {...register("state", {
                            required: " State is required",
                          })}
                          ref={stateRef}
                          defaultSelected={
                            stateOption &&
                            stateOption.filter(function (row) {
                              return (
                                jobDetail &&
                                jobDetail.job_state_id
                                  .split(",")
                                  .includes(row.state_id)
                              );
                            })
                          }
                        />
                        <div className="input-err">
                          {errors?.state?.message}
                        </div>
                      </div>
                    </div>
                  </div>}
                  {cityOption && ((jobDetail &&jobDetail.job_city_id) || !id) && <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor="city" className="float-start mb-1">
                        City
                      </label>
                      <div className="form-input-div">
                        <SelectBox
                          className="mdi mdi-chevron-down"
                          name="city"
                          labelKey="city"
                          valueKey="city_id"
                          defaultValue={selectedCity || []}
                          options={cityOption || []}
                          OnChange={(e) => {
                            setValue("city", e[0] ? e[0].city_id :"");
                            setSelectedCity(e);

                          }}
                          placeholder="Select value from dropdown"
                          {...register("city")}
                          ref={cityJobRef}
                          defaultSelected={ cityOption ? cityOption.filter(function (row) {
                              return (
                                jobDetail &&
                                jobDetail.job_city_id
                                  .split(",")
                                  .includes(row.city_id)
                              );
                            }) : []
                          }
                        />
                      </div>
                    </div>
                  </div>}
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4">
                      <label htmlFor="username" className="float-start mb-1">
                        Job Description <em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type="text"
                          id="username"
                          className="form-control"
                          placeholder="Enter  Job Description"
                          {...register("job_description", {
                            required: "Job Description is required",
                          })}
                          defaultValue={jobDetail && jobDetail.job_description}
                        />
                        <div className="input-err">
                          {errors?.job_description?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row mb-4 custom-date">
                      <label htmlFor="username" className="float-start mb-1">
                        Application Deadline <em>*</em>
                      </label>
                      <div className="form-input-div">
                        <DatePicker
                          format="dd/MM/y"
                          onChange={onDatechange}
                          name="jobAppdedline"
                           minDate={jobAppclosedate && jobAppclosedate < new Date() ? jobAppclosedate : new Date()}
                          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                          value={jobAppclosedate}
                          rules={{
                            ...register("jobAppdedline", {
                              required: " Application Deadline is required.",
                            }),
                          }}
                        />
                        <div className="input-err">{errors?.dob?.message}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-action-area">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row">
                    {loading ? <button
                      form="contact-person-add-hook-form"
                      className="w-20 btn btn-primary m-auto">
                      <div className="spinner-border text-white  text-center" role="status">
                        <span className="sr-only">Loading...</span>
                      </div> </button> : <button className="btn-primary" type="submit">
                      Submit
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddJob;
