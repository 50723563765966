
const CoreValues = (props) => {
  return (
    <>
      {props.data.map((row, key) => (
        <div
          key={key}
           className={"card-itm core-itm-0"+(key+1)}
          alt={row.core_values_title}
        >
          <div className="card">
            <div className="card-header">
              <div className={"core-head d-flex align-item-center core-icon-0"+(key+1)}>
                
                <img src={row.core_values_image} className="svg" alt=""/>
              </div>
            </div>
            <div className="card-content">
              <div className="card-title">{row.core_values_title}</div>
              <p className="common-paragraph">{row.core_values_description}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default CoreValues;
