import React, { useState } from "react";
import { checkLogin } from "./Helpers/Session";
import Layout from "./pages/Layout";
import { imgSvg } from "./Helpers/General";
import CookieConsent, { Cookies } from "react-cookie-consent";
import cookieIcon from './asset/images/cookie.svg'
function App() {
  const [userProfile, setUserProfile] = useState(checkLogin());

  imgSvg();
  return (
    <div className="AppWrapper">
      <Layout userProfile={userProfile} setUserProfile={setUserProfile} />
      <CookieConsent
        location="bottom"
        buttonText="Accept Cookie"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#fff",  left:"10px",  color:"#222222", borderRadius: "0.5rem", boxShadow: "0 4px 8px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 8%)", padding: "2rem 1rem", display:"block", width:"400px", height:"320px",  margin:"auto auto 10px" }}
        buttonStyle={{ background: "var(--theme-secondary-color)", color: "#ffffff", fontSize: "14px", margin:"0px 10px", padding:"10px 15px" }}
        expires={150}
      > 
          <div className="cookie-icon"><img src={cookieIcon} alt="Cookie Iconn" width="100" /></div>
          <div className="cookie-text">By clicking “Accept all cookies”, you agree Stack Exchange can store cookies on your device and disclose information in accordance with our Cookie Policy.</div>
        
      </CookieConsent>
    </div>
  );
}

export default App;
