import { useEffect, useState,useContext } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { apiRequest } from "../../../../Helpers/General";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
const Ranking = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const{genralMasterData}= useContext(MasterContext);
  let rankingLabel=genralMasterData.RANKING_LAWYER
  const [lawyerRankingData, setLawyerRankingData] = useState([]);
  const [lawyerRankingMetaData, setLawyerRankingMetaData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);

  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            country_id: "102",
          },
        };
        const apiResult = await apiRequest("get_lawyer_ranking", apiSetting);

        if (apiResult.data.settings.success === "1") {
          setLawyerRankingData(apiResult.data.data.rank_master_data);
          setLawyerRankingMetaData(apiResult.data.data.ranking_meta_data[0]);
          setIsPageUpdating(false);
        } else {
          setLawyerRankingData([]);
          setLawyerRankingMetaData([]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating]);
  const meta = {
    title: lawyerRankingMetaData&&lawyerRankingMetaData.meta_title,
    description: lawyerRankingMetaData&&lawyerRankingMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: lawyerRankingMetaData&&lawyerRankingMetaData.meta_key_words,
      },
    },
  };
  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page ranking-page">
          <div className="container">
            <div className="row">
              <div className="page-title text-center col-lg-8 m-auto">
                <h1>
                  {rankingLabel&&rankingLabel.gm_heading ?rankingLabel.gm_heading                  
                  :"Ranking Of Lawyers"}
                </h1>
                <p className="common-paragraph"> {rankingLabel&&rankingLabel.gm_short_description ?rankingLabel.gm_short_description                  
                  :"We rank the lawyers based on the documents produced by them and the market research done by our research team. A detailed guidelines about the ranking can be downloaded from the link at the bottom of the page."}</p>
              </div>
              <div className="ranking-list">
                {lawyerRankingData.length > 0 ? (
                  lawyerRankingData.map((row, key) => (
                    <div
                      className={
                        "ranking-itm ranking-upcoming ranking-color-" +
                        row.rank_level
                      }
                      key={key}
                    >
                      <div className="row">
                        <div
                          className={
                            "col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center" +
                            ((key === 1 ||
                              key === 3 ||
                              key === 5 ||
                              key === 7) &&
                              " order-last")
                          }
                        >
                          <div className="ranking-banner text-center">
                            <img src={row.rank_image} alt={row.alt_for_rank_image?row.alt_for_rank_image:""} 
                            className="svg"
                            onContextMenu={(e)=>
                            {
                            return false}
                            }
                            />
                            <p className="common-paragraph mt-4">
                              Your Expertise
                            </p>
                          </div>
                        </div>

                        <div
                          className={"col-lg-8 col-md-12 col-sm-12 col-xs-12 "}
                        >
                          <div className="ranking-content">
                            <h2>{row.rank_name}</h2>
                            <div
                              className="ranking-description"
                              dangerouslySetInnerHTML={{
                                __html: row.rank_description,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoRecord pageName={props.componentInfo.title} />
                )}
              </div>
            </div>
            <div className="row">
              <div className="page-title text-center">
                <p className="common-paragraph">
                  Download the PDF for more details on ranking
                </p>
                <div className="custom-action d-flex text-center justify-content-center mt-3">
                  {/* {lawyerRankingData[0].download_pdf} */}
                  <a
                    href={
                      lawyerRankingData &&
                      lawyerRankingData[0] &&
                      lawyerRankingData[0].download_pdf
                    }
                    className="btn-green"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Pdf
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default Ranking;
