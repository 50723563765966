import ProfileNavigation from "../ProfileNavigation";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import React, { useContext, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Linetable from "../../../../componants/Table/LineTable";
import { useForm } from "react-hook-form";
import { useState } from "react";
import SelectBox from "../../../../componants/SelectBox/SelectBox";
import Award from "../../../LoginNotRequired/Auth/AwardForm";
import EducationForm from "../../../LoginNotRequired/Auth/EducationForm";
import OrganisationHistory from "../../../LoginNotRequired/Auth/OrganisationHistory";
import EventAttended from "../../../LoginNotRequired/Auth/EventAttended";
import ReactToast from "../../../../componants/Toast/ReactToast";
import { useHistory } from "react-router";
import { MasterContext } from "../../../../Context/index";
import { apiRequest } from "../../../../Helpers/General";
import Loader from "../../../../componants/Loader/Loader";
import ImageUpload from "../../../../componants/ImageUpload/ImageUpload";
import AdditionalRankingForm from "../../../LoginNotRequired/Auth/AdditionalRankingForm";
import moment from "moment";
import CropImageUpload from "../../../../componants/ImageUpload/CropImageUpload";
import DatePicker from "react-date-picker";
const EditProfile = (props) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();
  const {
    countrydata,
    expertiesmasterdata,
    courtMasterData,
    languagemasterdata,
  } = useContext(MasterContext);
   // eslint-disable-next-line
  const regexUrl = new RegExp('http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+');
  const [isPageLoading, setIsPageLoading] = useState(true);
   // eslint-disable-next-line
  const [doRefresh, setDoRefresh] = useState(0);
  const [userInfo, setUserInfo] = useState([]);
  const [awardData, setAwardData] = useState([]);
  const [additionalRankingData, setAdditionalRankingData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [organisationHistoryData, setOrganisationHistoryData] = useState([]);
  const [eventAttended, setEventAttended] = useState([]);
  const [profilePic, setProfilePic] = useState(null);
  const [userOrgLogo, setUserOrgLogo] = useState(null);
  const [dobdate, setDobDate] = useState();
  const [imgSrc, setImgSrc] = useState(
    `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
  );
  const [orgName, setorgName] = useState();
  const [countryCode, setCountryCode] = useState("IN");
  const [whatsupCountryCode, setwhatsupCountryCode] = useState("IN");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    var width =  window.innerWidth;
    if(width > 800)
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setCurRouteDetails(props.componentInfo);
    const getData = async () => {
      const apiSetting = {
        method: "GET",
        apiParams: {
          user_id: props.userProfile && props.userProfile.user_master_id,
        },
      };
      const apiResult = await apiRequest("get_user_profile_info", apiSetting);
      if (apiResult.data.settings.success === "1") {
        let data = apiResult.data.data.return_data[0];
        setUserInfo(data);
        let dobDateformate = data && data.user_birth_date && data.user_birth_date !=="0000-00-00" ? moment(data.user_birth_date) : null
        setDobDate(dobDateformate ? dobDateformate._d : null);
        setValue("dob", data && data.user_birth_date);
        let mobile = data.user_mobile_no.includes("+") ? data.user_mobile_no : "+91" + data.user_mobile_no
        changeMobile(mobile);
        changeMobileWhatsup(data.user_whats_up_no);

        if (data.user_profile_pic !== "") setImgSrc(data.user_profile_pic);
        if (data.awards) setAwardData(data.awards);
        if (data.education) setEducationData(data.education);
        if (data.rank_data) setAdditionalRankingData(data.rank_data);
        if (data.work_org) setOrganisationHistoryData(data.work_org);
        if (data.event_data) setEventAttended(data.event_data)
        setorgName(data.user_organizatin_name);
      }
      setIsPageLoading(false);
    };
    if (isPageLoading) {
      getData();
    }
     // eslint-disable-next-line
  }, [isPageLoading]);

  const lineTable_lables_Award = ["Award Name", "Year", "Action"];
  const lineTable_lables_Additional_Ranking = [
    "Rank Title",
    "Credential Link",
    "Action",
  ];
  const lineTable_lables_Event_Attended = [
    "Title",
    "Event Date",
    "Venue",
    "Action",
  ];
  const lineTable_lables_Education_Qualification = [
    "School/University Name",
    "Degree Name",
    "From(Year)",
    "To (Year)",
    "Action",
  ];
  const lineTable_lables_user_work_organisation = [
    "Designation",
    "Organization Title",
    "Is It Current Organization?",
    "From Date",
    "To Date",
    "Action",
  ];

  const shouldValidate = { shouldValidate: true };
  const onOrgNameChange = (data) => {
    setValue("organigation_name", data);
  };
  const changeMobile = (value) => {
    if (value) {
      let number = value.trim();
      if (isValidPhoneNumber(number) === true) {
        setValue("mobile", number);
        clearErrors("mobile");
        let whatsAppNum = getValues("whatsup");
        if (!whatsAppNum) {
          changeMobileWhatsup(number);
          // setWhatsAppNum(number);
        }
      } else {
        setError("mobile", {
          type: "invalid",
          message: "Invalid Mobile number.",
        });
      }
    } else {
      setError("mobile", {
        type: "required",
        message: "Mobile number is required.",
      });
    }
  };
  const changeMobileWhatsup = (value) => {
    if (value) {
      if (isValidPhoneNumber(value) === true) {
        setValue("whatsup", value);
        clearErrors("whatsup");
      } else {
        setError("whatsup", {
          type: "invalid",
          message: "Invalid Whatsup number.",
        });
      }
    } else {
      // setError("whatsup", {
      //   type: "required",
      //   message: "WhatsApp number is required",
      // });
    }
  };
  const onCountryChange = (e) => {
    setCountryCode(e);
  };
  const onWhatsupCountryChange = (e) => {
    setwhatsupCountryCode(e);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    try {

      if (educationData.length === 0) {
        ReactToast({ type: "error", message: "Education is required" });
        return false;
      }

      let countryId =
        data.country_of_practice && data.country_of_practice.length > 0
          ? data.country_of_practice[0].country_id
          : 0;
      let areasOfExperties =
        data.areas_of_experties && data.areas_of_experties.length > 0
          ? data.areas_of_experties.map((item) => item["expertise_master_id"])
          : "";
      let courtsPractice =
        data.courts_practice && data.courts_practice.length > 0
          ? data.courts_practice.map((item) => item["court_master_id"])
          : "";
      let languageKnown =
        data.language_known && data.language_known.length > 0
          ? data.language_known.map((item) => item["language_master_id"])
          : "";
      if (areasOfExperties.length > 3)
        throw new Error("Maximum 3 selection allowed in Area Of Expertise");
        
      if (courtsPractice.length > 3)
        throw new Error("Maximum 3 selection allowed in Court Of Practice");

      if (data.from_time > data.to_time) {
        ReactToast({ type: "error", message: "From time is not less than To" });

        return false

      }

      let formData = {
        user_id: props.userProfile.user_master_id || "",
        user_type: props.userProfile.user_type || "",
        country_id: props.userProfile.country_id || "",
        first_name: data.firstname || "",
        last_name: data.lastname || "",
        email_id: userInfo.user_email || "",
        phone_code: countryCode || "",
        mobile_no: data.mobile  || "",
        birth_date: data.dob || userInfo.user_birth_date,
        practice_country_id: countryId || "",
        bar_council: data.bar_council || "",
        enrollment_year: data.year_of_enrollment || "",
        expertise_area: areasOfExperties ? areasOfExperties.join() : "",
        practice_courts: courtsPractice ? courtsPractice.join() : "",
        language_known: languageKnown ? languageKnown.join() : "",
        experience: data.experience_years || "",
        organisation_name: data.organigation_name || "",
        awards: awardData || "",
        about: data.about || "",
        additional_ranks: additionalRankingData || "",
        educational_qualification: educationData || "",
        from_time: data.from_time || "",
        to_time: data.to_time || "",
        whats_up_no_country_phone_code: whatsupCountryCode || "",
        whats_up_no: data.whatsup || "",
        facebook_profile_link: data.facebook || "",
        twitter_profile_link: data.twitter || "",
        you_tube_link: data.you_tube || "",
        linked_in_link: data.linkdin || "",
        work_organisation: organisationHistoryData || "",
        outside_meetings: eventAttended || "",
        address1: data.address1 || "",
        address2: data.address2 || "",
        gstn_no: data.gstNumber || "",
        //whats_up_no: data.to_date,
      };

      let file = {
        organisation_logo: [userOrgLogo],
        profile_pic: [profilePic],
      };

      const apiSetting = {
        method: "POST",
        apiParams: formData,
        fileInput: file,
      };
      const apiResult = await apiRequest("edit_user_profile", apiSetting);
      if (apiResult.data.settings.success === "1") {
        ReactToast({
          type: "success",
          message: apiResult.data.settings.message,
        });
        history.push("./MyProfile");
        setTimeout(() =>
          window.location.reload(), 1000)
      } else {
        throw new Error(apiResult.data.settings.message);
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  };

  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const changeCountryOfPractice = (selectedOptions) => {
    //Integrating Controlled Inputs
    setValue("country_of_practice", selectedOptions, shouldValidate);
  };
  const onDatechange = (value) => {
    let datevalue = moment(value).format("YYYY-MM-DD");
    setValue("dob", datevalue);
    setDobDate(value);
  };

  
  function validateGstnNumber(gstn_no) {
    //same logc as Adesk, given by Sanjay Rao
    var REGEX_1 = /^([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1})+$/;
    var REGEX_2 = /^([0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][A-Z0-9]{1})+$/;
    var REGEX_3 = /^([0-9]{2}[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}[0-9]{1}[Z]{1}[0-9]{1})+$/;
    var REGEX_4 = /^([0-9]{4}[a-zA-Z]{3}[0-9]{5}[N][R][0-9a-zA-Z]{1})+$/;
    var REGEX_5 = /^([0-9]{2}[a-zA-Z]{4}[a-zA-Z0-9]{1}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[D]{1}[0-9a-zA-Z]{1})+$/;
    var REGEX_6 = /^([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[C]{1}[0-9a-zA-Z]{1})+$/;
    var REGEX_7 = /^([9][9][0-9]{2}[a-zA-Z]{3}[0-9]{5}[O][S][0-9a-zA-Z]{1})+$/;

    if (gstn_no === "") {
      return true;
    }

    gstn_no = gstn_no.toUpperCase();
    if (gstn_no.match(REGEX_1)) {
      return true;
    } else if (gstn_no.match(REGEX_2)) {
      return true;
    } else if (gstn_no.match(REGEX_3)) {
      return true;
    } else if (gstn_no.match(REGEX_4)) {
      return true;
    } else if (gstn_no.match(REGEX_5)) {
      return true;
    } else if (gstn_no.match(REGEX_6)) {
      return true;
    } else if (gstn_no.match(REGEX_7)) {
      return true;
    } else {
      return false;
    }
  }
  const checkGstNo = (e) => {
    const gstNo = e.target.value;
    if (gstNo === "" || gstNo === undefined || gstNo === null) {
      clearErrors("gstNumber");
    } else {
      const isValid = validateGstnNumber(gstNo);
      if (isValid) {
        clearErrors("gstNumber");
      } else {
        setError("gstNumber", {
          type: "invalid",
          message: "Invalid Gst Number",
        });
      }
    }
  };
  if (isPageLoading) {
    return <Loader />;
  } else {
    return (
      <div className="inner-page editprofile-page after-login-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
              <div className="common-page-content publication-content">
                {/*  <div className="page-title  text-left">
                <h1>Edit Profile</h1>
              </div> */}
              </div>
              <div className="signup-form-block">
                <form
                  id="documents-add-hook-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12 mb-5">
                    <div className="inner-title">
                      <h3>Personal Information</h3>
                    </div>

                    <div className="form-section">
                      <div className="row upload-photo-label">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <CropImageUpload imgSrc={imgSrc} setProfilePic={setProfilePic} title="Set Profile Picture" />
                          <label
                            htmlFor="inputCurrency"
                            className="float-start mb-2 mt-2 d-flex"
                          > Upload photo </label>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputCurrency"
                              className="float-start mb-1 d-flex"
                            >
                              First Name <em>*</em>
                            </label>
                            <div className="form-input position-relative">
                              <input
                                type="text"
                                id="inputFirstName"
                                className="form-control"
                                placeholder="FirstName"
                                defaultValue={userInfo.user_first_name}
                                {...register("firstname", {
                                  required: "First Name is required",
                                  maxLength: {
                                    value: 30,
                                    message:
                                      "First Name must be less than 30 char ",
                                  },
                                })}
                              />
                              <div className="input-err">
                                {errors?.firstname?.message}
                              </div>
                            </div>
                          </div>
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputCurrency"
                              className="float-start mb-1 d-flex"
                            >
                              Last Name <em>*</em>
                            </label>
                            <div className="form-input position-relative">
                              <input
                                type="text"
                                id="inputLastName"
                                className="form-control"
                                placeholder="LastName"
                                defaultValue={userInfo.user_last_name}
                                {...register("lastname", {
                                  required: "Last Name is required",
                                  maxLength: {
                                    value: 30,
                                    message:
                                      " Last Name must be less than 30 char ",
                                  },
                                })}
                              />
                              <div className="input-err">
                                {errors?.lastname?.message}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputCurrency"
                              className="float-start mb-1 d-flex"
                            >
                              Email Address <em>*</em>
                            </label>
                            <div className="form-input position-relative">
                              <input
                                disabled
                                type="email"
                                id="inputEmail"
                                className="form-control"
                                placeholder="Email address"
                                autoFocus
                                defaultValue={userInfo.user_email}
                                {...register("email", {
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Enter a valid Email",
                                  }
                                  ,
                                })}
                              />
                              <div className="input-err">
                                {errors?.email?.message}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label htmlFor="" className="float-start mb-1 d-flex">
                              Phone Number <em>*</em>
                            </label>
                            <div className="form-input-div form-input position-relative">
                              <div className="input-group">
                                <PhoneInput
                                  id="mobile"
                                  placeholder="Mobile Number"
                                  defaultCountry="IN"
                                  value={userInfo.user_mobile_no.includes("+") ? userInfo.user_mobile_no : "+91" + userInfo.user_mobile_no}
                                  onChange={changeMobile}
                                  rules={{
                                    ...register("mobile", {
                                      required: "Phone number is required.",
                                    }),
                                  }}
                                  onCountryChange={onCountryChange}
                                />
                              </div>
                              <div className="input-err">
                                {errors?.mobile?.message}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputCurrency"
                              className="float-start mb-1 d-flex"
                            >
                              WhatsApp Number
                            </label>
                            <div className="form-input-div form-input position-relative">
                              <div className="input-group">
                                <PhoneInput
                                  id="mobile"
                                  placeholder="Mobile Number"
                                  defaultCountry="IN"
                                  onChange={changeMobileWhatsup}
                                  value={userInfo.user_whats_up_no}
                                  // defaultValue={userInfo.user_whats_up_no}
                                  rules={{
                                    ...register("whatsup"),
                                  }}
                                  onCountryChange={onWhatsupCountryChange}
                                />
                              </div>
                              <div className="input-err">
                                {errors?.whatsup?.message}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown d-flex flex-column custom-date">
                            <label
                              htmlFor="inputDOB"
                              className="float-start mb-1 d-flex w-100"
                            >
                              Date Of Birth <em>*</em>
                            </label>
                            <div className="form-input position-relative w-100">
                              <DatePicker
                              disabled={userInfo.user_birth_date &&
                                  userInfo.user_birth_date !== "" &&
                                  userInfo.user_birth_date !== "0000-00-00" ? true : false}
                                  format="dd/MM/y"
                              onChange={onDatechange}
                              selected={new Date(new Date().setFullYear(new Date().getFullYear() -20))}
                              name="DOB"
                              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() -20))}
                              defaultValue={ userInfo.user_birth_date &&
                                    userInfo.user_birth_date !== "" &&
                                    userInfo.user_birth_date !== "0000-00-00"
                                    ? moment(userInfo.user_birth_date).format(
                                      "YYYY-MM-DD"
                                    )
                                    : new Date(new Date().setFullYear(new Date().getFullYear() -20))}
                              value={dobdate}
                              rules={{
                                ...register("dob", {
                                  required: " Date Of Birth is required.",
                                }),
                              }}
                            />
                              {/* <input
                                // disabled={userInfo.user_birth_date &&
                                //   userInfo.user_birth_date !== "" &&
                                //   userInfo.user_birth_date !== "0000-00-00" ? true : false}
                                type="date"
                                id="start"
                                name="trip-start"
                                max="2002-12-31"
                                defaultValue={
                                  userInfo.user_birth_date &&
                                    userInfo.user_birth_date !== "" &&
                                    userInfo.user_birth_date !== "0000-00-00"
                                    ? moment(userInfo.user_birth_date).format(
                                      "YYYY-MM-DD"
                                    )
                                    : ""
                                }
                                {...register("dob", {
                                })}
                              /> */}
                              <div className="input-err">
                                {errors?.dob?.message}
                              </div>
                            </div>
                          </div>
                        </div>

                        {userInfo.user_user_type === "Lawyer" && (
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-row custom-dropdown d-flex flex-column custom-date">
                              <label
                                htmlFor="inputDOB"
                                className="float-start mb-1 d-flex w-100"
                              >
                                Preferred Time To call
                              </label>
                              <label>From</label>
                              <div className="form-input position-relative w-100">
                                <input
                                  type="time"
                                  id="start"
                                  name="trip-start"
                                  placeholder="From Time"
                                  defaultValue={userInfo.from_time}
                                  {...register("from_time", {
                                    // required: "Form Time is required.",
                                  })}
                                />
                                <div className="input-err">
                                  {errors?.from_time?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {userInfo.user_user_type === "Lawyer" && (
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-row custom-dropdown d-flex flex-column custom-date">
                              <label>&nbsp;</label>
                              <label>To</label>

                              <div className="form-input position-relative w-100">
                                <input
                                  type="time"
                                  id="start"
                                  name="trip-start"
                                  placeholder="To Time"
                                  defaultValue={userInfo.to_time}
                                  {...register("to_time", {
                                    // required: "To Time is required.",
                                  })}
                                />
                                <div className="input-err">
                                  {errors?.to_time?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputCurrency"
                              className="float-start mb-1 d-flex"
                            >
                              Country of Practice <em>*</em>
                            </label>
                            <div className="form-input position-relative">
                              <SelectBox
                                className="mdi mdi-chevron-down"
                                name="country"
                                labelKey="country"
                                valueKey="country_id"
                                defaultValue={userInfo.user_country_of_practice}
                                defaultSelected={
                                  countrydata &&
                                  countrydata.filter(function (row) {
                                    return (
                                      userInfo.user_country_of_practice &&
                                      userInfo.user_country_of_practice
                                        .split(",")
                                        .includes(row.country_id)
                                    );
                                  })
                                }
                                options={countrydata}
                                OnChange={changeCountryOfPractice}
                                setValue={setValue}
                                placeholder="Select value from dropdown"
                                {...register("country_of_practice", {
                                  required: "Country of Practice is required",
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        {userInfo.user_user_type === "Lawyer" && (
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-row custom-dropdown">
                              <label
                                htmlFor="inputCurrency"
                                className="float-start mb-1 d-flex"
                              >
                                Facebook Url (Optional)
                              </label>
                              <div className="form-input position-relative">
                                <input
                                  type="text"
                                  id="inputfacebook"
                                  className="form-control"
                                  placeholder="Facebook Url"
                                  autoFocus
                                  defaultValue={
                                    userInfo.user_face_book_profile_link
                                  }
                                  {...register("facebook", {
                                    pattern: {
                                      value: regexUrl,
                                      message: "Enter a valid Facebook Url",
                                    },
                                  })}
                                />
                                <div className="input-err">
                                  {errors?.facebook?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {userInfo.user_user_type === "Lawyer" && (
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-row custom-dropdown">
                              <label
                                htmlFor="inputCurrency"
                                className="float-start mb-1 d-flex"
                              >
                                Twitter URL (Optional)
                              </label>
                              <div className="form-input position-relative">
                                <input
                                  type="text"
                                  id="inputtwitter"
                                  className="form-control"
                                  placeholder="Twitter Url"
                                  autoFocus
                                  defaultValue={
                                    userInfo.user_twitter_profile_link
                                  }
                                  {...register("twitter", {
                                    pattern: {
                                      value: regexUrl,
                                      message: "Enter a valid Twitter URL",
                                    },
                                  })}
                                />
                                <div className="input-err">
                                  {errors?.twitter?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* {userInfo.user_user_type === "Lawyer" && ( */}
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputCurrency"
                              className="float-start mb-1 d-flex"
                            >
                              LinkedIn URL (Optional)
                            </label>
                            <div className="form-input position-relative">
                              <input
                                type="text"
                                id="inputlinkden"
                                className="form-control"
                                placeholder="LinkedIn Url"
                                autoFocus
                                defaultValue={userInfo.user_linked_in_link && userInfo.user_linked_in_link}
                                {...register("linkdin", {
                                  pattern: {
                                    value: regexUrl,
                                    message: "Enter a valid Url",
                                  },
                                })}
                              />
                              <div className="input-err">
                                {errors?.linkdin?.message}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* )} */}
                        {userInfo.user_user_type === "Lawyer" && (
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-row custom-dropdown">
                              <label
                                htmlFor="inputCurrency"
                                className="float-start mb-1 d-flex"
                              >
                                You Tube URL (Optional)
                              </label>
                              <div className="form-input position-relative">
                                <input
                                  type="text"
                                  id="inputyou_tube"
                                  className="form-control"
                                  placeholder="You Tube URL"
                                  autoFocus
                                  defaultValue={userInfo.user_you_tube_link}
                                  {...register("you_tube", {
                                    pattern: {
                                      value: regexUrl,
                                      message: "Enter a valid Url",
                                    },
                                  })}
                                />
                                <div className="input-err">
                                  {errors?.you_tube?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="input_address1"
                              className="float-start mb-1 d-flex"
                            >
                              Address 1
                            </label>
                            <div className="form-input position-relative">
                              <input
                                type="text"
                                id="input_address1"
                                className="form-control"
                                placeholder="Enter Address 1"
                                autoFocus
                                defaultValue={userInfo.user_address1}
                                {...register("address1")}
                              />
                              <div className="input-err">
                                {errors?.address1?.message}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputaddress2"
                              className="float-start mb-1 d-flex"
                            >
                              Address 2
                            </label>
                            <div className="form-input position-relative">
                              <input
                                type="text"
                                id="input_address2"
                                className="form-control"
                                placeholder="Enter Address 2"
                                autoFocus
                                defaultValue={userInfo.user_address2}
                                {...register("address2")}
                              />
                              <div className="input-err">
                                {errors?.address2?.message}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="inner-title">
                      <h3>Professional Information</h3>
                    </div>
                    <div className="row">

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div className="form-row d-flex flex-column">
                          <label
                            htmlFor="inputLogo"
                            className="float-start mb-1 d-flex"
                          >
                            Current Organization Logo
                          </label>
                          {/* <CropImageUpload imgSrc={userInfo.user_organization_logo || `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`} setProfilePic={setUserOrgLogo}/> */}
                          <div className="form-input position-relative">

                            <ImageUpload
                              name="user_org_logo"
                              imgSrc={userInfo.user_organization_logo || `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`}
                              {...register("user_org_logo")}
                              onChange={(data) => {
                                setUserOrgLogo(data);
                              }}
                            />


                            <div className="input-err"></div>
                          </div>

                        </div>

                      </div>

                      {userInfo.user_user_type === "Lawyer" ? (
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-row custom-dropdown">
                              <label
                                htmlFor="inputOrganigationName"
                                className="float-start mb-1 d-flex"
                              >
                                Organization Name
                              </label>
                              <div className="form-input position-relative">
                                <input
                                  type="text"
                                  id="inputBarCouncil"
                                  className="form-control"
                                  placeholder="Organization Name"
                                  defaultValue={orgName}
                                  onChange={onOrgNameChange}
                                  {...register("organigation_name", {
                                    maxLength: {
                                      value: 50,
                                      message: "About must be less than 50 char ",
                                    },
                                  })}
                                />
                                <div className="input-err">
                                  {errors?.organigation_name?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="form-row ">
                              <label
                                htmlFor="inputGstNumber"
                                className="float-start mb-1 d-flex"
                              >
                                GST Number
                              </label>
                              <div className="form-input position-relative">
                                <input
                                  type="text"
                                  id="inputGstNumber"
                                  className="form-control"
                                  placeholder="GST Number"
                                  defaultValue={userInfo.user_gstno}
                                  // onChange={checkGstNo}
                                  {...register("gstNumber",{
                                    onChange: checkGstNo
                                  })}
                                />
                                <div className="input-err">
                                  {errors?.gstNumber?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <input
                          type="hidden"
                          id="inputBarCouncil"
                          className="form-control"
                          placeholder="Organigation Name"
                          defaultValue={orgName}
                          {...register("organigation_name")}
                        />
                      )}
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="form-row custom-dropdown">
                          <label
                            htmlFor="inputCurrency"
                            className="float-start mb-1 d-flex"
                          >
                            Experience (in years) <em>*</em>
                          </label>
                          <div className="form-input position-relative">
                            <input
                              type="number"
                              id="experience_years"
                              className="form-control"
                              placeholder="Experience (in years)"
                              defaultValue={userInfo.user_experience}
                              min="0"
                              {...register("experience_years", {
                                required: " Experience is required",
                                min: 0,
                                maxLength: {
                                  value: 2,
                                  message: "Invalid Value",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Invalid Value",
                                },
                              })}
                            />
                            <div className="input-err">
                              {errors?.experience_years?.message}
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="form-row custom-dropdown">
                          <label
                            htmlFor="inputyearofenrollment"
                            className="float-start mb-1 d-flex"
                          >
                            Enrolment  <em>*</em>
                          </label>
                          <div className="form-input position-relative">
                            <input
                              type="text"
                              id="inputyear_enrollment"
                              className="form-control"
                              placeholder="e.g. 2015"
                              defaultValue={userInfo.user_year_of_enrollment}
                              {...register("year_of_enrollment", {
                                required: "Enrolment is required",
                                maxLength: {
                                  value: 4,
                                  message: "Invalid Value",
                                },
                                minLength: {
                                  value: 4,
                                  message: "Invalid Value",
                                },
                              })}
                            />
                            <div className="input-err">
                              {errors?.year_of_enrollment?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="form-row custom-dropdown">
                          <label
                            htmlFor="inputCurrency"
                            className="float-start mb-1 d-flex"
                          >
                            Bar Council <em>*</em>
                          </label>
                          <div className="form-input position-relative">
                            <input
                              type="text"
                              id="inputBarCouncil"
                              className="form-control"
                              placeholder="Bar Council"
                              defaultValue={userInfo.user_bar_council}
                              {...register("bar_council", {
                                required: "Enter Bar Council",
                                maxLength: {
                                  value: 40,
                                  message:
                                    "Bar Council must be less than 40 char ",
                                },
                              })}
                            />
                            <div className="input-err">
                              {errors?.bar_council?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-row custom-dropdown">
                          <label
                            htmlFor="inputCurrency"
                            className="float-start mb-1 d-flex"
                          >
                            Languages Known <em>*</em>
                          </label>
                          <div className="form-input position-relative">
                            <SelectBox
                              multiple
                              placeholder="Language Known"
                              className="mdi mdi-chevron-down"
                              name="language_known"
                              labelKey="language_name"
                              valueKey="language_master_id"
                              options={languagemasterdata}
                              OnChange={(selectedOptions) => {
                                setValue(
                                  "language_known",
                                  selectedOptions,
                                  shouldValidate
                                );
                              }}
                              {...register("language_known")}
                              setValue={setValue}
                              defaultValue={userInfo.user_known_languages}
                              defaultSelected={
                                languagemasterdata &&
                                languagemasterdata.filter(function (row) {
                                  return (
                                    userInfo.user_known_languages &&
                                    userInfo.user_known_languages
                                      .split(",")
                                      .includes(row.language_master_id)
                                  );
                                })
                              }
                            />
                            <div className="input-err">
                              {errors?.language_known?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                      {userInfo.user_user_type === "Lawyer" ?
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputAreasofExperties"
                              className="float-start mb-1 d-flex"
                            >
                              Areas of Expertise (maximum 3 allowed) {userInfo.user_user_type === "Lawyer" && <em>*</em>}
                            </label>
                            <div className="form-input position-relative">
                              <SelectBox
                                multiple
                                className="mdi mdi-chevron-down"
                                name="type_of_business"
                                labelKey="expertise_name"
                                valueKey="expertise_master_id"
                                placeholder="Areas of Expertise"
                                options={expertiesmasterdata}
                                OnChange={(selectedOptions) => {
                                  setValue(
                                    "areas_of_experties",
                                    selectedOptions,
                                    shouldValidate
                                  );
                                }}
                                {...register("areas_of_experties", {
                                  required: "Areas of Expertise is required",
                                })}
                                setValue={setValue}
                                defaultValue={userInfo.user_expertise_master_ids}
                                // defaultSelected={
                                //   userInfo.expertise ? userInfo.expertise : []
                                // }
                                defaultSelected={
                                  expertiesmasterdata &&
                                  expertiesmasterdata.filter(function (row) {
                                    return (
                                      userInfo.user_expertise_master_ids &&
                                      userInfo.user_expertise_master_ids
                                        .split(",")
                                        .includes(row.expertise_master_id)
                                    );
                                  })
                                }
                              />
                              <div className="input-err">
                                {errors?.areas_of_experties?.message}
                              </div>
                            </div>
                          </div>
                        </div> :
                        ""
                      }
                      {userInfo.user_user_type === "Lawyer" &&
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-row custom-dropdown">
                            <label
                              htmlFor="inputCourtsPractice"
                              className="float-start mb-1 d-flex"
                            >
                              Court Of Practice (maximum 3 allowed) <em>*</em>
                            </label>
                            <div className="form-input position-relative">
                              <SelectBox
                                multiple
                                placeholder=" Courts Practice"
                                className="mdi mdi-chevron-down"
                                name="courts_practice"
                                labelKey="court_name"
                                valueKey="court_master_id"
                                options={courtMasterData}
                                OnChange={(selectedOptions) => {
                                  setValue(
                                    "courts_practice",
                                    selectedOptions,
                                    shouldValidate
                                  );                                  
                                }}
                                {...register("courts_practice",{
                                  required: "Court Of Practice is required",
                                })}
                                setValue={setValue}
                                defaultValue={userInfo.user_court_master_ids}
                                // defaultSelected={
                                //   userInfo.court_data ? userInfo.court_data : []
                                // }
                                defaultSelected={
                                  courtMasterData &&
                                  courtMasterData.filter(function (row) {
                                    return (
                                      userInfo.user_court_master_ids &&
                                      userInfo.user_court_master_ids
                                        .split(",")
                                        .includes(row.court_master_id)
                                    );
                                  })
                                }
                              />
                              <div className="input-err">
                                {errors?.courts_practice?.message}
                              </div>
                            </div>
                          </div>
                        </div>}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-row custom-dropdown">
                          <label
                            htmlFor="inputAbout"
                            className="float-start mb-1 d-flex"
                          >
                            About
                          </label>
                          <div className="form-input position-relative">
                            <textarea
                              type="text"
                              id="user_about"
                              className="form-control custom-textarea"
                              rows="4"
                              placeholder="About"
                              {...register("about", {
                                minLength: {
                                  value: 3,
                                  message: "About must be max than 5 char ",
                                },
                                // maxLength: {
                                //   value: 150,
                                //   message: "About must be less than 150 char ",
                                // },
                              })}
                              defaultValue={userInfo.user_about}
                            />

                            <div className="input-err">
                              {errors?.about?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {userInfo.user_user_type === "Lawyer" && (          
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="inner-title">
                            <h3>Awards (Optional) </h3>
                          </div>
                          <div className="additional-form-info mt-3  signup-form-table ">
                            <Linetable
                              childComponant={Award}
                              addBtn="true"
                              setLineInfo={setAwardData}
                              lineInfo={awardData}
                              labels={lineTable_lables_Award}
                              moduleCode="userAwards"
                              setDoRefresh={setDoRefresh}
                              title="Add Award"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="inner-title">
                          <h3>Educational Qualifications<em>*</em></h3>
                        </div>
                        <div className="additional-form-info mt-3 signup-form-table">
                          <Linetable
                            childComponant={EducationForm}
                            addBtn="true"
                            setLineInfo={setEducationData}
                            lineInfo={educationData}
                            labels={lineTable_lables_Education_Qualification}
                            title="Add Educational Qualifications"
                            moduleCode="userEducation"
                            setDoRefresh={setDoRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    {userInfo.user_user_type === "Lawyer" && (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="inner-title">
                            <h3>Ranking Achieved from Other Websites (Optional)</h3>
                          </div>
                          <div className="additional-form-info mt-3  signup-form-table">
                            <Linetable
                              childComponant={AdditionalRankingForm}
                              addBtn="true"
                              setLineInfo={setAdditionalRankingData}
                              lineInfo={additionalRankingData}
                              labels={lineTable_lables_Additional_Ranking}
                              moduleCode="Addition Ranking"
                              setDoRefresh={setDoRefresh}
                              title="Add Ranking Achieved from Other Websites"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {userInfo.user_user_type === "Gc" && (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="inner-title">
                            <h3>Organization History</h3>
                          </div>
                          <div className="additional-form-info mt-3  signup-form-table">
                            <Linetable
                              childComponant={OrganisationHistory}
                              addBtn="true"
                              setLineInfo={setOrganisationHistoryData}
                              lineInfo={organisationHistoryData}
                              labels={lineTable_lables_user_work_organisation}
                              moduleCode="organisationHistory"
                              setDoRefresh={setDoRefresh}
                              onOrgNameChange={onOrgNameChange}
                              title="Add Organization History"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {userInfo.user_user_type === "Gc" && (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="inner-title">
                            <h3>Events attended</h3>
                          </div>
                          <div className="additional-form-info mt-3  signup-form-table">
                            <Linetable
                              childComponant={EventAttended}
                              addBtn="true"
                              setLineInfo={setEventAttended}
                              lineInfo={eventAttended}
                              labels={lineTable_lables_Event_Attended}
                              moduleCode="eventattendent"
                              setDoRefresh={setDoRefresh}
                              onOrgNameChange={onOrgNameChange}
                              title="Add Events attended"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-row mt-4 text-center custom-action ">
                          {loading ? <button
                            form="contact-person-add-hook-form"
                            className="w-20 btn btn-primary m-auto">
                            <div className="spinner-border text-white  text-center" role="status">
                              <span className="sr-only">Loading...</span>
                            </div> </button> : <button className="btn-green m-auto" type="submit">
                            Submit
                          </button>}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className={
                props.userProfile && props.userProfile.profile_completed === "No"
                  ? "col-lg-3 col-md-3 col-sm-12 col-xs-12 d-none"
                  : "col-lg-3 col-md-3 col-sm-12 col-xs-12"
              }
            >
              <ProfileNavigation />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default EditProfile;
