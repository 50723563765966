import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useHistory } from "react-router";
import DocumentMeta from "react-document-meta";
// import MembershipCheckout from "../MembershipCheckout/MembershipCheckout";
import "./Pricing.css";
import { apiRequest, smoothScroll } from "../../../../Helpers/General";
import { MasterContext } from "../../../../Context";
const Pricing = (props) => {
  const history = useHistory();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
    if (props.userProfile) {
      history.push("/myprofile");
    }
  });
  const { genralMasterData } = useContext(MasterContext);
  let pricingLabel = genralMasterData.READY_TO_ONBOARD;
  let upgradeLabel = genralMasterData.SUBSCRIPTION;
  const [pricingDetail, setPricingDetail] = useState([]);
  const [pricingMetaDetail, setPricingMetaDetail] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: "102", from_page: "pricing" },
        };
        const apiResult = await apiRequest("get_package_plan_page_detail", apiSetting);
        if (apiResult.data.settings.success === "1") {
          setPricingDetail(apiResult.data.data);
          setPricingMetaDetail(apiResult.data.data.pricing_meta_data[0]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating]);

  const goToBuyPlan = (data) => {
    localStorage.setItem("planDetail", JSON.stringify(data.package_master_info));
    history.push("/MembershipCheckout");
  };

  const meta = {
    title: pricingMetaDetail && pricingMetaDetail.meta_title,
    description: pricingMetaDetail && pricingMetaDetail.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: pricingMetaDetail && pricingMetaDetail.meta_key_words,
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="inner-page pricing-page">
        <div className="container">
          <div className="row">
            <div className="page-title text-center">
              <h1>{pricingLabel && pricingLabel.gm_heading ? pricingLabel.gm_heading : "Ready to Onboard"}</h1>
              <p className="common-paragraph">
                {pricingLabel && pricingLabel.gm_short_description
                  ? pricingLabel.gm_short_description
                  : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vitae dolor dignissim sagittis nisl quis, egestas leo."}
              </p>
              <div className="custom-action d-flex text-center justify-content-center ">
                {/* <HashLink
                  to="/Pricing#section-price"
                  className="btn-green"
                  scroll={(el) => el.scrollIntoView({ behavior: "auto", block: "end" })}
                  // activeClassName="selected"
                  // activeStyle={{ color: "red" }}
                >
                  Lawyer Membership
                </HashLink> 
                 <HashLink
                  to="/Pricing#section-signup"
                  className="btn-green"
                  scroll={(el) => el.scrollIntoView({ behavior: "auto", block: "end" })}
                  // activeClassName="selected"
                  // activeStyle={{ color: "red" }}
                >
                  General Counsel Membership
                </HashLink> */}
                <a className="btn-green" onClick={() => smoothScroll(document.getElementById('section-price-block'), 'start')}>Lawyer Membership</a>
                <a className="btn-green" onClick={() => smoothScroll(document.getElementById('section-signup'))}>General Counsel Membership</a>
                
              </div>
            </div>
            <div className="row" id="section-price-block">&nbsp;</div>
            <div className="row">
              <div className="page-title text-center">
                <div className="features-list-outer">
                  <ul className="features-list" >
                    <li>
                      <span className="mdi mdi-arrow-right-bold"></span>
                      {genralMasterData.GENERAL_COUNCIL && genralMasterData.GENERAL_COUNCIL.gm_description
                        ? genralMasterData.GENERAL_COUNCIL.gm_description
                        : "General Counsel membership is free."}
                    </li>
                    <li>
                      <span className="mdi mdi-arrow-right-bold"></span>
                      {pricingLabel && pricingLabel.gm_description ? pricingLabel.gm_description : "Lawyer Membership as free and paid."}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row" >
              <div className="table centered" id="section-price">
                <div className="table-row">
                  <div  className="column plan-details">
                    <ul className="price">
                      <li className="plan-price">
                        {upgradeLabel && upgradeLabel.gm_description ? upgradeLabel.gm_description : "Subscription Plan"}
                      </li>

                      {pricingDetail.package_parameters_list &&
                        pricingDetail.package_parameters_list.map((item, key) => (
                          <li key={key}>
                            <div className="price-name d-flex">
                              {item.parameter_name}
                              
                              {item.help_text && item.help_text !== "" && (
                                <div className="help-link ms-2">
                                  <i className="mdi mdi-help-circle"></i>
                                  <span className="tooltiptext"> {item.help_text ? "" + item.help_text + "" : ""}</span>
                                </div>
                              )}
                            </div>
                            <span className="item-description">{item.description ? "(" + item.description + ")" : ""}</span>
                          </li>
                        ))}

                      <li className="price-action"></li>
                    </ul>
                  </div> 
                  {pricingDetail.data_formation &&
                    pricingDetail.data_formation.return_data &&
                    pricingDetail.data_formation.return_data.map((item, key) => (
                      <div className="column  " key={key}>
                        <ul className="price">
                          <li
                            className="price-header"
                            style={{
                              backgroundColor: item.package_master_info.package_color,
                            }}
                          >
                            {item.package_master_info.package_name}

                            
                          </li>
                          <li className="plan-price" id="plan-price">                        
                            {item.package_master_info.discount_value > 0 && (
                              <span className="featured-ribbon">
                                Save
                                {Math.round(item.package_master_info.discount_value)}%
                              </span>
                            )}

                            {item.package_master_info.discount_value > 0 && (
                              <span className="package-price">
                                {item.package_master_info.package_currency_symbol}
                                {Math.round(item.package_master_info.package_price)}
                              </span>
                            )}
                            <span className="discount-price">
                              {item.package_master_info.package_currency_symbol}
                              {Math.round(item.package_master_info.discounted_price)}
                            </span>

                            <span className="duration-time">/{item.package_master_info.duration_name}</span>
                          </li>
                          
                          {pricingDetail.package_parameters_list.map((row, index) => {
                            const filtered = item.package_params.filter((data) => {
                              return data.package_parameters_id.includes(row.package_parameters_id);
                            });
                            if (filtered.length > 0) {
                              // eslint-disable-next-line
                              return item.package_params.map((items, key) => {
                                if (row.package_parameters_id === items.package_parameters_id) {
                                  if (items.package_item_wise === "No") {
                                    return (<li key={key}>
                                              <span className="mobile-desc">{items.package_parameter_name}</span>
                                              <i className="mdi mdi-check"></i>
                                            </li>);
                                  }
                                  else
                                  {
                                    return (<li key={key}>
                                      <span className="mobile-desc">{items.package_parameter_name}</span>
                                      <span className="value-text">{items.package_txt}</span>
                                    </li>);
                                  }
                                  /* return (
                                    <li key={key}>
                                      <span className="mobile-desc">{items.package_parameter_name}</span>

                                      {items.package_parameters_allow_access === "Yes" &&
                                      items.package_item_wise === "Yes" &&
                                      items.package_parameters_items_count === "0" ? (
                                        "Unlimited"
                                      ) : items.package_parameters_allow_access === "Yes" &&
                                        items.package_item_wise === "Yes" &&
                                        items.package_parameters_items_count > 0 ? (
                                        items.package_parameters_items_count
                                      ) : items.package_parameters_allow_access === "Yes" && items.package_item_wise === "No" ? (
                                        <i className="mdi mdi-check"></i>
                                      ) : (
                                        <i className="mdi mdi-close"></i>
                                      )}
                                    </li>
                                  ); */
                                }
                              });
                            } else {
                              return (
                                <li key={index}>
                                  <span className="mobile-desc">{row.parameter_name}</span>
                                  <i className="mdi mdi-close"></i>
                                </li>
                              );
                            }
                          })}
                          <li className="price-action">
                            <button
                              className="price-btn"
                              style={{
                                backgroundColor: item.package_master_info.package_color,
                              }}
                              onClick={() => goToBuyPlan(item)}
                            >
                              Choose Plan
                            </button>
                          </li>
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="page-title text-center signup-form" id="section-signup">
                <h2>
                  {genralMasterData.GENERAL_COUNCIL && genralMasterData.GENERAL_COUNCIL.gm_heading
                    ? genralMasterData.GENERAL_COUNCIL.gm_heading
                    : "Join as GC"}
                </h2>
                {genralMasterData.GENERAL_COUNCIL && genralMasterData.GENERAL_COUNCIL.gm_short_description ? (
                  <p>{genralMasterData.GENERAL_COUNCIL.gm_short_description}</p>
                ) : (
                  <p className="common-paragraph">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sem nunc, sollicitudin maximus erat eu, vulputate consequat
                    tellus. Vivamus nec luctus metus.
                  </p>
                )}
                <div className="custom-action d-flex text-center justify-content-center ">
                  <Link to="/gcsignupform" className="btn-green">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Pricing;
