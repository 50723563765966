import axios from 'axios';
import { checkLogin } from './Session';
import numeral from "numeral";
import moment from "moment";
//capitalize only the first letter of the string. 
export const ucFirst = (string) => (string.charAt(0).toUpperCase() + string.slice(1));
export const getNumberValue = (number, shouldFormat = false) => {
  if (shouldFormat) return numeral(number).format('0,0.[00]');
  else {
    return parseFloat(numeral(number).format('0.[00]'));
  }
}
export const countryId = "102";
export const formatDate = (dateType = "", dateValue = undefined, calc = undefined) => {
  let value = "";
  dateValue = moment(dateValue);
  if (calc && calc.type && calc.period && calc.periodVal) {
    if (calc.type === "add") dateValue = dateValue.add(calc.periodVal, calc.period);
    if (calc.type === "subtract") dateValue = dateValue.subtract(calc.periodVal, calc.period);
  }
  if (dateType === "DateTime") value = dateValue.format(process.env.REACT_APP_DATE_TIME_FORMAT);
  else if (dateType === "DateTimeSecond") value = dateValue.format("DD/MM/YYYY hh:mm:ss");
  else if (dateType === "12HourFormate") value = dateValue.format("DD/MM/YYYY hh:mm A");
  else if (dateType === "Time") value = dateValue.format(process.env.REACT_APP_TIME_FORMAT);
  else if (dateType === "Date") value = dateValue.format(process.env.REACT_APP_DATE_FORMAT);
  else if (dateType === "systemDate") value = dateValue.format("YYYY-MM-DD");
  else if (dateType === "systemDateTime") value = dateValue.format("YYYY-MM-DD HH:mm:ss");
  else if (dateType === "systemTime") value = dateValue.format("HH:mm");
  else value = dateValue.format("YYYY-MM-DD HH:mm:ss");
  return value;
};

export const apiRequest = async (apiFunc, apiSetting = {}) => {

  let baseFunc = apiFunc;
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;multipart/form-data';
  axios.defaults.headers.get['Content-Type'] = 'application/json;charset=UTF-8';
  axios.defaults.timeout = 1000 * 60; // Wait for 60 seconds
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const method = apiSetting.method ? apiSetting.method : "GET";
  const apiParams = apiSetting.apiParams ? apiSetting.apiParams : {};
  const fileInput = apiSetting.fileInput ? apiSetting.fileInput : null;

  apiParams['method'] = apiFunc;
  apiParams['lang_id'] = apiParams.lang_id || "EN";
  //apiParams['@@err'] = "true";
  //apiParams['ws_debug'] = "1";
  const headerObj = {};
  const axiosConfig = { cancelToken: source.token };
  const loginInfo = checkLogin();
  if (loginInfo && loginInfo.id) {
    headerObj['AUTHTOKEN'] = loginInfo.id;
  }

  if (apiSetting.headers && apiSetting.headers.lenght > 0) {

    for (const [hk, hv] of Object.entries(apiSetting.headers)) {
      headerObj[hk] = hv;
    }
  }
  if (Object.keys(headerObj).length > 0) {
    axiosConfig['headers'] = headerObj;
  }


  if (method === "POST") {

    //return true;
    let formData = new FormData();
    for (const [key, value] of Object.entries(apiParams)) {
      if (typeof value === "object") formData.append(key, value && JSON.stringify(value));
      else formData.append(key, value && value);
    }
    //loop on file input keys
    if (fileInput) {

      for (const [key, fileObjects] of Object.entries(fileInput)) {

        if (fileObjects) {
          //formData.append(`${key}`, fileObjects)
          for (const [fKey, fileObj] of Object.entries(fileObjects)) {
            if (fileObj) {

              formData.append(`${key}[${fKey}]`, fileObj)
            }
          }
        }

      }
    }
    //loop on file input key's array
    return await axios.post(baseFunc, formData, axiosConfig);
  }
  else {
    axiosConfig['params'] = apiParams;
    return await axios.get(baseFunc, axiosConfig);
  }
}

export const imgSvg = () => {
  let imgdata = document.querySelectorAll('img.svg');
  imgdata.forEach((el) => {
    const imgID = el.getAttribute('id');
    const imgClass = el.getAttribute('class');
    const imgURL = el.getAttribute('src');

    fetch(imgURL).then(res => res.text()).then(data => {
      const parser = new DOMParser();
      const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');
      if (svg && typeof imgID !== 'undefined') {
        svg.setAttribute('id', imgID);
      }
      if (svg && typeof imgClass !== 'undefined') {
        svg.setAttribute('class', imgClass + ' replaced-svg');
      }
      svg && svg.removeAttribute('xmlns:a');
      svg && el.parentNode.replaceChild(svg, el);
    })
  });
}


export const MobileFtrMenu = () => {

  /* var d = document;
  var ul = d.querySelector('#mobileMenu');
  var h2s = d.querySelectorAll('h2');
  if (ul) {
    ul.addEventListener('click', function (e) {
      alert(11);
      for (var i = 0; i < h2s.length; i++) {
        h2s[i].classList.remove('active');
      }

      if (e.target && e.target.nodeName === 'H2') {
        e.target.classList.toggle('active');
      }
    });
  } */

  
}


export const directoryMobile = () => {

  /* 
  
  var d = document;
  var ul = d.querySelector('#directoryMobile');
  var h2s = d.querySelectorAll('h2');
  if (ul) {
    ul.addEventListener('click', function (e) {
      
      for (var i = 0; i < h2s.length; i++) {
        h2s[i].classList.remove('active');
      }
      if (e.target && e.target.nodeName === 'H2') {
        e.target.classList.toggle('active');
      }
    });
  } 
  
  */


  

 var acc = document.getElementsByClassName("mobile-view");
  var i;
  
  for (i = 0; i < acc.length; i++) {
      acc[i].onclick = function(){
          this.classList.toggle("active");
          this.nextElementSibling.classList.toggle("show");
    }
  } 


   

  /* var accItem = document.getElementsByClassName('tab-inner-content');
  var accHD = document.getElementsByClassName('mobile-view');
  for (var i = 0; i < accHD.length; i++) {
      accHD[i].addEventListener('click', toggleItem, false);
  }
  function toggleItem() {
      var itemClass = this.parentNode.className;
      for (i = 0; i < accItem.length; i++) {
          accItem[i].className = 'tab-inner-content close';
      }
      if (itemClass == 'tab-inner-content close') {
          this.parentNode.className = 'tab-inner-content open';
      }
  } */

}

export const mobileEventMenu = () => {
  /* var d = document;
  var ul = d.querySelector('#mobileEventMenu');
  var h2s = d.querySelectorAll('h2');
  if (ul) {
    ul.addEventListener('click', function (e) {

      for (var i = 0; i < h2s.length; i++) {
        h2s[i].classList.remove('active');
      }

      if (e.target && e.target.nodeName === 'H2') {
        e.target.classList.toggle('active');
      }
    });
  } */

}



export const mobileDomesticEventMenu = () => {
  /* var d = document;
  var ul = d.querySelector('#mobileDomesticEventMenu');
  var h2s = d.querySelectorAll('h2');
  if (ul) {
    ul.addEventListener('click', function (e) {

      for (var i = 0; i < h2s.length; i++) {
        h2s[i].classList.remove('active');
      }

      if (e.target && e.target.nodeName === 'H2') {
        e.target.classList.toggle('active');
      }
    });
  }
 */
}

export const mobileInternationalEventMenu = () => {
 /*  var d = document;
  var ul = d.querySelector('#mobileInternationalEventMenu');
  var h2s = d.querySelectorAll('h2');
  if (ul) {
    ul.addEventListener('click', function (e) {

      for (var i = 0; i < h2s.length; i++) {
        h2s[i].classList.remove('active');
      }

      if (e.target && e.target.nodeName === 'H2') {
        e.target.classList.toggle('active');
      }
    });
  } */

}

export const formatUrlLink = (linkUrl) => {
let value = "";
  if(linkUrl){
   value = ((linkUrl.split("/")[0] === "https:" || linkUrl.split("/")[0] === "http:") ? "" : "//" ) + linkUrl
  } 
  return value;
};

export const smoothScroll = (target, defaultBlock = 'end') => {
  target.scrollIntoView({
    block: defaultBlock,
    behavior: 'smooth',
    inline: 'start'
  });
}