import { useEffect, useState,useContext } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { Link } from "react-router-dom";
import iconTime from "./../../../../asset/svg/icon-time.svg";
import iconSearch from "./../../../../asset/svg/icon-search.svg";
import { apiRequest, formatDate } from "../../../../Helpers/General";
import moment from "moment";
import Pagination from "react-bootstrap-4-pagination";
import HomeSearch from "../../Landing/HomeSearch";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Modal from "../../../../componants/Modal/Modal";
import AddJob from "../../../LoginRequired/MyProfile/JobOpportunity/AddJob";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
const Employment = (props) => {
  let id = "";
  if (props.id) id = props.id;
  if (props.userProfile && props.userProfile.user_master_id)
    id = props.userProfile.user_master_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const{genralMasterData}= useContext(MasterContext);
  let employmentLabel=genralMasterData.EMPLOYEMENT
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [employmentData, setEmployementData] = useState([]);
  const [employmentMetaData, setEmployementMetaData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [searchParam, setSearchParam] = useState();
  const [filterData, setFilterData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [pageIndex, setpageIndex] = useState("1");
  const [doRefresh, setDoRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            country_id:
              searchParam && searchParam.country && searchParam.country[0]
                ? searchParam.country[0].country_id
                : "102",
            state_id:
              searchParam && searchParam.statecity && searchParam.statecity[0]
                ? searchParam.statecity[0].state_id
                : "",
                city_id: searchParam && searchParam.statecity && searchParam.statecity[0] ? searchParam.statecity[0].city_id : "",
            job_posted_date_from:
              searchParam && searchParam.startDate ? searchParam.startDate : "",
            job_posted_date_to:
              searchParam && searchParam.endDate ? searchParam.endDate : "",
              current_date: formatDate("systemDate"),
            page_index: pageIndex,
          },
        };
        const apiResult = await apiRequest("get_job_master_list", apiSetting);
          setPaginationData(apiResult.data.settings);
        
        if (apiResult.data.settings.success === "1") {
          if (pageIndex > 1) {
            employmentData.push(...(apiResult.data.data.job_master_data));
            setIsPageUpdating(false);
          } else {
          setEmployementData(apiResult.data.data.job_master_data);
          setEmployementMetaData(apiResult.data.data.employment_meta_data[0]);
          setFilterData(apiResult.data.data.job_master_data);
          setTotalRecords(apiResult.data.settings.count);
          setIsPageUpdating(false);
          }
        } else {
          setEmployementData([]);
          setEmployementMetaData(apiResult.data.data ? apiResult.data.data[0] : [] );
          setFilterData([]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setSearchParam();
        localStorage.removeItem("searchParam");
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating, searchParam, pageIndex]);

  const OnChange = (e) => {
    setSearchParam(e);
    setIsPageUpdating(true);
  };
  const onSearch = (e) => {
    let Text = e.target.value;
    if (Text) {
      const filtered = employmentData.filter((row) => {
        return (
          (row.job_designation &&
            row.job_designation.toLowerCase().includes(Text.toLowerCase())) ||
          (row.job_location &&
            row.job_location.toLowerCase().includes(Text.toLowerCase())) ||
          (row.job_posted_by &&
            row.job_posted_by.toLowerCase().includes(Text.toLowerCase())) ||
          (row.job_posted_date &&
            row.job_posted_date.toLowerCase().includes(Text.toLowerCase())) ||
          (row.job_description &&
            row.job_description.toLowerCase().includes(Text.toLowerCase()))
        );
      });
      setFilterData(filtered);
    } else {
      setFilterData(employmentData);
    }
  };
  let paginationConfig = {
    totalPages: Math.ceil(paginationData.count / 20),
    currentPage: parseInt(pageIndex),
    showMax: 5,
    size: "lg",
    // threeDots: true,
    // prevNext: true,
    onClick: function (index) {
      setpageIndex(index.toString());
      setIsPageUpdating(true);
    },
  };
  const meta = {
    title: employmentMetaData&&employmentMetaData.meta_title,
    description: employmentMetaData&&employmentMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: employmentMetaData&&employmentMetaData.meta_key_words,
      },
    },
  };
  let loadMore = Math.ceil(totalRecords / 20);
  if (isPageUpdating) {
    return <Loader />
  } else {
    return (
      <DocumentMeta {...meta}>
      <div className="inner-page employment-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-head d-flex align-item-center">
                <div className="inner-head-left">
                  <div className="page-title  text-start">
                    <h2>{employmentLabel&&employmentLabel.gm_heading?employmentLabel.gm_heading:"Employment Opportunities"}</h2>
                  </div>
                </div>

                <div className="inner-header-rgt ms-auto d-flex align-items center">
                  {props.userProfile !== undefined && (
                    <Modal
                      btnCoverClass="btn-green custom-btn-link"
                      title="Add Employment"
                      btnTitle="New Employment"
                      btnClass="add-icon"
                      child={AddJob}
                      userId={id}
                      setDoRefresh={setIsPageUpdating}
                    // onChange={onChangeProfile}
                    />
                  )}

                  <div className="search-field with-filter ms-4">
                    <div className="form-row">
                      <div className="form-input-div">
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control"
                          onChange={onSearch}
                        />
                        <i className="search-icon">
                          <img src={iconSearch} className="svg" alt="" />
                        </i>
                      </div>
                    </div>
                    <button
                      className="filter-toggler"
                      onClick={toggle}>filter</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 order-1 directory-search order-1 ">
              <div className={(isOpen ? "right-search-block search-hide" : "right-search-block search-show")}>
                <div className="right-block-title d-flex flex-column">
                  <h2>
                    Filter <span>Results</span>
                  </h2>
                  <HomeSearch type="Employment" OnChange={OnChange} searchParam={searchParam} />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <div className="common-page-content publication-content">
                <div className="event-lst-box d-flex justify-content-between flex-column">
                  {filterData.length > 0 ? (
                    filterData.map((item, key) => 
                       {

                        let plainEmpTitle = item.job_designation.substring(0, 180).split(" ").join("-").toLowerCase();
                        
                        if(plainEmpTitle.includes("?") === true){
                          plainEmpTitle = plainEmpTitle.split("?").join("-");  
                        }
                        plainEmpTitle = plainEmpTitle +" "+ item.job_posted_by.trim();
                        plainEmpTitle = plainEmpTitle.split(" ").join("-").toLowerCase();
                        const EmpLinkurl =  "/employment-detail/" + plainEmpTitle  + "-" +  item.job_master_id
                         return <div className="card-itm" key={key}>
                          <div className="card">
                            <div className="card-content pt-0">
                              <div className="card-content-top d-flex">
                                <div className="card-title w-50 float-start">
                                  <Link
                                    to={EmpLinkurl}
                                  >
                                    {" "}
                                    {item.job_designation}
                                  </Link>
                                </div>
                                <div className="post-type ms-auto">
                                  Posted by:
                                  <span className="highlight-text">
                                  <Link to={(item.user_type==="Lawyer"?
                                              "/lawyer-profile/":"/general-counsel/") + item.job_user_master_id
                                            }          >
                                             {item.job_posted_by
                                      ? item.job_posted_by
                                      : "--"}
                                            </Link>
                                   
                                  </span>
                                </div>
                              </div>
                              <small className="w-100 d-block m-auto"> 
                                {item.job_location} {item.job_city ? ", " + item.job_city : "" } {" , "}{item.job_state}{" , "}
                            {item.job_country}  
                              </small>
                              <p className="common-paragraph">
                                {item.job_description}
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-action">
                                <Link
                                  to={"/employment-detail/" +
                                  (item.job_designation+" "+item.job_posted_by).trim().split(" ").join("-").toLowerCase()+"-"+
                                  item.job_master_id}
                                  className="btn-border btn-back btn-view"
                                >
                                  <i className="back-icon"></i>
                                  View Details
                                </Link>
                              </div>
                              <div className="application-date">
                                <i className="">
                                  <img src={iconTime} className="svg" alt="" />
                                </i>
                                Application Closes :&nbsp;
                                {moment(item.job_application_deadline).format(
                                  "DD/MM/YYYY"
                                )}
                              </div>
                              <div className="publication-date">
                                <i className="">
                                  <img src={iconTime} className="svg" alt="" />
                                </i>
                                Posted On : &nbsp;
                                {moment(item.job_posted_date).format(
                                  "DD/MM/YYYY"
                                )}
                                
                              </div>
                            </div>
                          </div>
                        </div>}
                 
                    )
                  ) : (
                    <NoRecord pageName="Employment Opportunities" />
                  )}
                </div>
                <div className="footer-action">
                    <h3> Count : <span>{filterData.length} / {totalRecords}</span></h3>
              
                    {loadMore> pageIndex && <button 
                    // ref={ref} 
                    className="btn-primary" onClick={() => {
                      setIsPageUpdating(true);
                      setpageIndex((parseInt(pageIndex) + 1).toString())
                    }}>Load More</button>
                    }
                  </div>
              </div>
              {/* {paginationData.count > 0 && <Pagination {...paginationConfig} />} */}
            </div>

          </div>
        </div>
      </div>
      </DocumentMeta>
    );
  }
};

export default Employment;
