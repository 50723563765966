import "./EventCard.css";
import { Link } from "react-router-dom";
import iconTime from "./../../asset/svg/icon-time.svg";
import moment from "moment";

export const formatDate = (dateType = "", dateValue = undefined, calc = undefined) => {
  let value = "";
  dateValue = moment(dateValue);
  if (calc && calc.type && calc.period && calc.periodVal) {
    if (calc.type === "add") dateValue = dateValue.add(calc.periodVal, calc.period);
    if (calc.type === "subtract") dateValue = dateValue.subtract(calc.periodVal, calc.period);
  }
  if (dateType === "DateTime") value = dateValue.format(process.env.REACT_APP_DATE_TIME_FORMAT);
  else if (dateType === "DateTimeSecond") value = dateValue.format("DD/MM/YYYY hh:mm:ss");
  else if (dateType === "12HourFormate") value = dateValue.format("DD/MM/YYYY hh:mm A");
  else if (dateType === "Time") value = dateValue.format(process.env.REACT_APP_TIME_FORMAT);
  else if (dateType === "Date") value = dateValue.format(process.env.REACT_APP_DATE_FORMAT);
  else if (dateType === "systemDate") value = dateValue.format("YYYY-MM-DD");
  else if (dateType === "systemTime") value = dateValue.format("HH:mm");
  else value = dateValue.format("YYYY-MM-DD HH:mm:ss");
  return value;
};


const EventCard = (props) => {
  let day = moment(props.row.meeting_date).format("DD");
  let Month = moment(props.row.meeting_date).format("MMM YYYY");
  let redircturl =
    props.title === "Events"
      ? "/event-detail/"
      : !props.row.meeting_type
        ? "/networking-details/"
        : "/event-detail/";

  var myStr1 = (props.row.meeting_title).split(" ").join("-").toLowerCase();
  var myStrLength1 = myStr1.toLowerCase();
  let onlineOffline = props.row.meeting_confrence_mode && props.row.meeting_confrence_mode;
  onlineOffline = props.row.confrence_mode ? props.row.confrence_mode : onlineOffline;
  onlineOffline = props.row.skill_confrence_mode ? props.row.skill_confrence_mode : onlineOffline;
  onlineOffline = props.row.meeting_confrence_mode ? props.row.meeting_confrence_mode : onlineOffline;
  onlineOffline = onlineOffline === undefined || onlineOffline === "" ? "Online" : onlineOffline;

  return (
    <>

      <div className={"card-itm " + props.row.meeting_for.toLowerCase()}>
        <div className="card">
          <div className="card-banner">
            <img
              alt={props.row.alt_for_event_image ? props.row.alt_for_event_image : ""}
              src={
                props.row.meeting_image
                  ? props.row.meeting_image
                  : `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
              }
            />
          </div>
          <div className="card-header">
            <div className="event-head d-flex align-item-center">
              <div className="date-stats d-flex flex-column align-items-center">
                <span>
                  {onlineOffline}
                </span>
                <label>
                  {day} <small>{Month}</small>
                </label>
              </div>
              <div className="d-flex flex-column events-meeting-desc">

                <div className="events-meeting-for">
                  <span className={props.row.meeting_for.toLowerCase()}>
                    {props.row.meeting_for}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start w-100">
                  <div className="time-stats d-flex m-0">
                    <label>
                      <strong>
                        {props.row.meeting_time}</strong>
                      {"(" + props.row.gmt_off_set + ")"}
                    </label>

                  </div>
                  <div className="completed-date ">
                    {props.row.end_date_text  && props.row.end_date_text  !== ""  &&
                      <div className="end-date d-flex flex-column">
                        <label><b>{props.row.conference_days} </b> Days </label>
                        <span>Complete on <b>{formatDate("Date", props.row.meeting_end_date)}</b> </span>
                      </div>

                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="card-content">



            <div className="card-title"><Link
              to={redircturl + myStrLength1 + "-" + props.row.meeting_id}>{props.row.meeting_title}</Link></div>
            <p className="common-paragraph">
              {props.row.meeting_description}
            </p>
          </div>
          <div className="card-footer">
            <div className="card-action">
              <Link
                to={redircturl + myStrLength1 + "-" + props.row.meeting_id}
                className="btn-border btn-view btn-back"
              >
                <i className="back-icon"></i> View Details
              </Link>
            </div>
            <div className="publication-date">
              <i className="">
                <img src={iconTime} className="svg" alt="" />
              </i>
              Posted On : {props.row.publidation_date}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default EventCard;
