import ArticleSearch from "./ArticleSearch";
import React, { useState, useEffect, useContext } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import moment from "moment";
import { apiRequest } from "../../../../Helpers/General";
import Pagination from "react-bootstrap-4-pagination";
import { Link } from "react-router-dom";
import iconTime from "./../../../../asset/svg/icon-time.svg";
import iconSearch from "./../../../../asset/svg/icon-search.svg";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Modal from "../../../../componants/Modal/Modal";
import AddPublication from "../../../LoginRequired/MyProfile/Publication/AddPublication";
//import LazyLoad from "react-lazyload";
//import Spinner from "../../../../componants/Spinner/Spinner";
import Loader from "../../../../componants/Loader/Loader";
import Avatar from "../../../../componants/Avatar/Avatar";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
import ImageZoom from "react-medium-image-zoom";
import { useInView } from 'react-intersection-observer';

const Publications = (props) => {
  
  let id = "";
  if (props.id) id = props.id;
  if (props.userProfile && props.userProfile.user_master_id)
    id = props.userProfile.user_master_id;
    const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const [totalRecords, setTotalRecords] = useState(0);
   // eslint-disable-next-line
  const [ref, inView] = useInView({
    threshold: 0,
  })
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const { genralMasterData } = useContext(MasterContext);
  let publicationLabel = genralMasterData.PUBLICATION
  const [publicationData, setPublicationData] = useState([]);
  const [publicationMetaData, setPublicationMetaData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [selectedValue, setSelectedValue] = useState();
  const [filterData, setfilterData] = useState([]);
   // eslint-disable-next-line
  const [paginationData, setPaginationData] = useState([]);
  const [pageIndex, setpageIndex] = useState("1");
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            user_id: "",
            country_id: "102",
            current_date: moment().format("YYYY-MM-DD"),
            page_index: pageIndex,
          },
        };
        const apiResult = await apiRequest(
          "get_publication_master_list",
          apiSetting
        );
        //   setPaginationData(apiResult.data.settings);
        if (apiResult.data.settings.success === "1") {

          if (pageIndex > 1) {
            publicationData.push(...(apiResult.data.data.publication_list));
            setIsPageUpdating(false);
          } else {
          setPublicationData(apiResult.data.data.publication_list);
          setPublicationMetaData(apiResult.data.data.publication_meta_data[0]);
          setTotalRecords(apiResult.data.settings.count);
          setfilterData(apiResult.data.data.publication_list);
          setIsPageUpdating(false);
          }
        } else {
          setPublicationData([]);
          setPublicationMetaData(apiResult.data.data ? apiResult.data.data[0] : []);
          setfilterData([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
     // eslint-disable-next-line
  }, [isPageUpdating, pageIndex]);

  useEffect(() => {
    if (inView) {
      setIsPageUpdating(true);
      setpageIndex((parseInt(pageIndex) + 1).toString())
    }
     // eslint-disable-next-line
  }, [inView])
  const onSearch = (e) => {
    let Text = e.target.value;
    if (Text) {
      const filtered = publicationData.filter((row) => {
        return (
          (row.category_data &&
            row.category_data[0] &&
            row.category_data[0].category_name
              .toLowerCase()
              .includes(Text.toLowerCase())) ||
          (row.master_data.publicaton_publication_date &&
            row.master_data.publicaton_publication_date
              .toLowerCase()
              .includes(Text.toLowerCase())) ||
          (row.master_data.publicaton_title &&
            row.master_data.publicaton_title
              .toLowerCase()
              .includes(Text.toLowerCase())) ||
          (row.master_data.publicaton_about &&
            row.master_data.publicaton_about
              .toLowerCase()
              .includes(Text.toLowerCase()))
        );
      });
      setfilterData(filtered);
    } else {
      setfilterData(publicationData);
    }
  };
  const OnFilterChange = (e) => {
    let Text = e;
    var dataFilter = [];
    if (Text) {
      publicationData.length > 0 &&
        publicationData.filter((row) => {
          return (
            row.category_data &&
            row.category_data.forEach((element) => {
              if (element.category_master_id === Text) {
                setSelectedValue(element.category_name);
                dataFilter.push(row);
              }
            })
          );
        });
      setfilterData(dataFilter);
    } else {
      setfilterData(publicationData);
    }
  };
  let paginationConfig = {
    totalPages: Math.ceil(paginationData.count / 20),
    currentPage: parseInt(pageIndex),
    showMax: 10,
    size: "lg",
    onClick: function (index) {
      setpageIndex(index.toString());
      setIsPageUpdating(true);
    },
  };

  const meta = {
    title: publicationMetaData && publicationMetaData.meta_title,
    description: publicationMetaData && publicationMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: publicationMetaData && publicationMetaData.meta_key_words,
      },
    },
  };
  let loadMore = Math.ceil(totalRecords / 20);
  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page publication-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-head d-flex align-item-center">
                  <div className="inner-head-left">
                    <div className="page-title  text-start">
                      <h2>{publicationLabel && publicationLabel.gm_heading ? publicationLabel.gm_heading : "Topics"}</h2>
                    </div>
                  </div>
                  <div className="inner-header-rgt ms-auto d-flex align-items center">
                    {props.userProfile !== undefined && props.userProfile.user_type !== 'Gc'&& (
                      <Modal
                        btnCoverClass="btn-green custom-btn-link"
                        title="Add Publication"
                        btnTitle="New Publication"
                        btnClass="add-icon"
                        child={AddPublication}
                        id={id}
                      // onChange={onChangeProfile}
                      />
                    )}
                    <div className="search-field with-filter ms-4 ">
                      <div className="form-row">
                        <div className="form-input-div">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control"
                            onChange={onSearch}
                          />
                          <i className="search-icon">
                            <img src={iconSearch} className="svg" alt="" />
                          </i>
                        </div>
                      </div>
                      <button
                      className="filter-toggler"
                      onClick={toggle}>filter</button>
                    </div>         
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 order-1 directory-search ">
                <div className={(isOpen ? "right-search-block search-hide" : "right-search-block search-show")}>
              <ArticleSearch
                OnChange={OnFilterChange}
                setSelectedValue={setSelectedValue}
                selectedValue={selectedValue}
              />
              </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                <div className="common-page-content publication-content publication-content-listing">
                  {/*  <h2 className="inner-sub-heading mb-5">Recent Publications</h2> */}

                  <div className="event-lst-box d-flex justify-content-between flex-column">
                    {filterData.length > 0 ? (
                      filterData.map((row, key) => {
                        let plainPubTitle = row.master_data.publicaton_title.substring(0, 180).split(" ").join("-").toLowerCase();
                        
                        if(plainPubTitle.includes("?") === true){
                          plainPubTitle = plainPubTitle.split("?").join("-");  
                        }
                        plainPubTitle = plainPubTitle +" "+ row.master_data.user_name.trim();
                        plainPubTitle = plainPubTitle.split(" ").join("-").toLowerCase();
                        const pubLinkurl =  "/publications-details/" + plainPubTitle  + "-" +  row.master_data.publication_master_id
                        return (
                          // <LazyLoad
                          //   key={key}
                          //   height={200}
                          //   offset={[-100, 0]}
                          //   // once={[6, 7]}
                          //   placeholder={<Spinner />}
                          // >
                            <div className="card-itm" key={key}>
                              <div className="card">
                                <div className="row">
                                  <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                                    <div className="card-content">
                                      {row.category_data &&
                                        row.category_data.map((item, index) => (
                                          <span
                                            className="practice-type"
                                            key={index}
                                            onClick={() =>
                                              OnFilterChange(
                                                item.category_master_id
                                              )
                                            }
                                          >
                                            {item.category_name}
                                            {row.category_data.length >
                                              index + 1
                                              ? " / "
                                              : ""}
                                          </span>
                                        ))}
                                      <div className="card-title">
                                        <Link
                                          to={pubLinkurl}
                                        >
                                          {row.master_data.publicaton_title}
                                        </Link>
                                      </div>
                                      <p className="common-paragraph">
                                        {row.master_data.publicaton_about}
                                      </p>
                                      <div className="publication-posted-by d-flex flex-row">
                                        {row.master_data.vProfilePic ? <ImageZoom>
                                          <div className="posted-pic">
                                            <Avatar
                                              id={row.master_data.id}
                                              fullName={row.master_data.user_name}
                                              pictureUrl={
                                                row.master_data.vProfilePic
                                                  ? row.master_data.vProfilePic
                                                  : ""
                                              }
                                              alt={row.master_data.alt_for_profile_pic ? row.master_data.alt_for_profile_pic : ""}
                                              size="500px"
                                              fontSize="12px"
                                            ></Avatar>

                                          </div></ImageZoom> : <div className="posted-pic">
                                          <Avatar
                                            id={row.master_data.id}
                                            fullName={row.master_data.user_name}
                                            pictureUrl={
                                              row.master_data.vProfilePic
                                                ? row.master_data.vProfilePic
                                                : ""
                                            }
                                            alt={row.master_data.alt_for_profile_pic ? row.master_data.alt_for_profile_pic : ""}
                                            size="40px"
                                            fontSize="12px"
                                          ></Avatar>

                                        </div>}
                                        <div className="posted-content">
                                          <div className="publication-by">
                                            <Link to={
                                              "/lawyer-profile/" + row.master_data.user_master_id
                                            }          >
                                              {row.master_data.user_name}
                                            </Link>
                                          </div>

                                          <div className="publication-date">
                                            <i className="">
                                              <img
                                                src={iconTime}
                                                className="svg"
                                                alt=""
                                              />
                                            </i>
                                            Posted On :{" "}
                                            {moment(
                                              row.master_data
                                                .publicaton_publication_date
                                            ).format("DD/MM/YYYY")}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                    <div className="card-footer d-flex flex-column border-0">
                                      <div className="card-action">
                                        <Link
                                          to={
                                            "/publications-details/" +
                                            (row.master_data.publicaton_title).split(" ").join("-").toLowerCase() + "-" +
                                            row.master_data
                                              .publication_master_id
                                          }
                                          className="btn-border btn-back btn-view"
                                        >
                                          <i className="back-icon"></i>
                                          Continue Reading
                                        </Link>
                                      </div>
                                      {row.master_data
                                        .user_organization_logo && (
                                          <div className="emp-award">
                                           {row.master_data
                                                  .user_organization_logo? <ImageZoom>
                                            <img
                                              src={
                                                row.master_data
                                                  .user_organization_logo ||
                                                `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                                              }
                                              alt={row.master_data.alt_for_org_image ? row.master_data.alt_for_org_image : ""}
                                            />
                                            </ImageZoom>:<img
                                              src={
                                                row.master_data
                                                  .user_organization_logo ||
                                                `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                                              }
                                              alt={row.master_data.alt_for_org_image ? row.master_data.alt_for_org_image : ""}
                                            />}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          // </LazyLoad>
                        );
                      })
                    ) : (
                      <NoRecord pageName={props.componentInfo.title} />
                    )}
                  </div>
                  <div>
                  <div className="footer-action">
                    <h3> Count : <span>{filterData.length} / {totalRecords}</span></h3>
              
                    {loadMore> pageIndex && <button 
                    // ref={ref} 
                    className="btn-primary" onClick={() => {
                      setIsPageUpdating(true);
                      setpageIndex((parseInt(pageIndex) + 1).toString())
                    }}>Load More</button>
                    }
                  </div>
                </div>
                </div>
                {paginationData.count > 0 && (
                  <Pagination {...paginationConfig} />
                )}
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default Publications;
