import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import ImageUpload from "../../../../componants/ImageUpload/ImageUpload";
import { apiRequest } from "../../../../Helpers/General";
import swal from "sweetalert";
const ContactPerson = (props) => {
    var { userData } = props
    const [toBeDeleted, setToBeDeleted] = useState([]);
    const [ImagSrc, setImagSrc] = useState();
    const [document, setDocument] = useState("");
    const recordData = props.recordData || null;
    const [countryCode, setCountryCode] = useState("IN");
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        setValue("name", userData && userData.first_name + " " + userData.last_name)
        setValue("email", userData && userData.email)
    })
    const changeMobile = (value) => {
        // let code= getCountryCallingCode(value)
        if (value) {
            if (isValidPhoneNumber(value) === true) {
                setValue("mobile", value);
                clearErrors("mobile");
            } else {
                setError("mobile", {
                    type: "invalid",
                    message: "Invalid Mobile number.",
                });
            }
        } else {
            setError("mobile", {
                type: "required",
                message: "Mobile number is mandatory.",
            });
        }
    };
    const onChange = (e) => {
        const type =  e.name.split('.').pop()
        if (type === "msword"|| type === "doc" || type === "pdf" || type === "docx") {
            setDocument(e)
            //  setValue("attachments", e.name)
        } else {
            setDocument("");
            swal({
                title: "Error",
                text: "File format should be pdf or doc",
                icon: "warning",
                button: {
                    text: "Close",
                    closeModal: true,
                },
                dangerMode: true,
            });
        }



    }
    const onCountryChange = (e) => {
        setCountryCode(e)
    }
    const onSubmit = async (data) => {
        
        if (!document) {
            return false
        }
        setLoading(true);
        let param = {
            job_master_id: recordData.jjob_master_id,
            employer_id: recordData.job_user_master_id,
            applicant_name: data.name,
            email_id: data.email,
            phone_code: countryCode,
            mobile_number: data.mobile,
            country_id: "",
        }
        try {
            const apiSetting = {
                method: "POST",
                apiParams: param,
                fileInput: { file_name: { 0: document } }
            };
            const apiResult = await apiRequest("apply_for_job", apiSetting);
            if (apiResult.data.settings.success === "1") {
                
                swal({
                    title: "Success",
                    text: apiResult.data.settings.message,
                    icon: "success",
                    button: {
                        text: "Done",
                        closeModal: true,
                    },
                    dangerMode: false,
                }).then((willDelete) => {
                    props.setIsPageUpdating && props.setIsPageUpdating(true);
                    props.closeModal();  
                })
               
            } else {
                swal({
                    title: "Error",
                    text: apiResult.data.settings.message,
                    icon: "warning",
                    button: {
                        text: "Close",
                        closeModal: true,
                    },
                    dangerMode: true,
                })
                props.closeModal();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div
            className="ReactModal-namechange-content-container ReactModal-Content-Container"
            id="name_change_form"
        >
            <div className="ReactModal-middle-container">
                <form
                    id="contact-person-add-hook-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <input
                        type="hidden"
                        id="inputId"
                        className="form-control"
                        {...register("id")}
                    />
                    {/* <div className="form-group">
            <div className="profile-content width75">  */}
                    <div className="form-group">
                        <div className="form-row mb-3 ">
                            <label htmlFor="inputUnivercityName" className="float-start mb-1">
                                Name <em>*</em>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    id="inputUnivercityName"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    {...register("name", {
                                        required: "Name is required ",
                                    })}
                                    defaultValue={userData && userData.first_name + userData.last_name}
                                />
                            </div>
                            <div className="input-err">{errors?.name?.message}</div>
                        </div>
                    </div>
                    <div className="form-group">

                        <div className="form-row mb-3 ">
                            <label htmlFor="inputDegreeName" className="float-start mb-1">
                                Email Address <em>*</em>
                            </label>
                            <div className="input-group ">
                                <input
                                    type="text"
                                    id="email"
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Enter a valid Email.",
                                        },
                                    })}
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    autoFocus
                                />
                            </div>
                            <div className="input-err">{errors?.email?.message}</div>
                        </div>

                    </div>
                    <div className="form-group">
                        <div className="form-row mb-3 ">
                            <label htmlFor="inputFrom" className="float-start mb-1">
                                Phone Number <em>*</em>
                            </label>
                            <div className="input-group form-input-div">
                                <PhoneInput
                                    id="mobile"
                                    placeholder="Phone Number"
                                    defaultCountry="IN"
                                    onChange={changeMobile}
                                    rules={{
                                        ...register("mobile", {
                                            required: " Phone Number is required.",
                                        }),
                                    }}
                                    onCountryChange={onCountryChange}
                                />
                            </div>
                            <div className="input-err">{errors?.mobile?.message}</div>
                        </div>
                    </div>
                    <div className="form-group">

                        <div className="form-row mb-3 upload-resume">
                            <label htmlFor="inputTo" className="float-start mb-1">
                                Upload Resume <em>*</em>
                            </label>
                            <div className="input-group">
                                <ImageUpload
                                    type="Document"
                                    name="attachments"
                                    id="inputAttachments"
                                    className="form-control"
                                    placeholder="Document Attachments"
                                    {...register("attachments")}
                                    setValue={setValue}
                                    onChange={onChange}
                                    setImgSrc={setImagSrc}
                                    toBeDeleted={toBeDeleted}
                                    setToBeDeleted={setToBeDeleted}
                                    suportedFormate="pdf, doc, docx"
                                />
                            </div>
                            <div className="input-err">{document ? "" : "Resume is required"}</div>
                        </div>

                    </div>

                </form>
            </div>
            <div className="ReactModal-Footer">
                <div className="ReactModal-ActionBlock profile-action-area">
                    <div className="btn-grp m-auto">
                        {loading ?<button
                            form="contact-person-add-hook-form"
                            className="w-20 btn btn-primary m-auto"> 
                            <div className="spinner-border text-white  text-center" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> </button>: 
                        <button
                            form="contact-person-add-hook-form"
                            className="w-20 btn btn-primary m-auto"
                            onClick={handleSubmit(onSubmit)}
                        >
                            <i className="mdi mdi-check"></i> Submit
                        </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPerson;
