import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const HowItWorks = (props) => {
  return (
    <>
      <Splide
        options={{
          type: "slide",
          arrows: false,
          gap: "30px",
          width: "100%",
          perPage: 3,
          padding: { right: "15px", left: "15px" },
          breakpoints: {
            1300: {
              perPage: 3,
            },
            980: {
              perPage: 2,
            },
            680: {
              perPage: 1,
            },
          },
        }}
      >
        {props.data.map((row, key) => 
         { 
          let embedId =(row.how_it_work_video_link).replace("watch?v=", "").split("/").pop()
           return <SplideSlide key={key}>
            <div className="custom-itm w-100" key={key}>
              <div className="custom-itm-content">
                <iframe
                title='Video'
                  width="420"
                  height="250"
                  src={`https://www.youtube.com/embed/${embedId}`}
                  alt={row.how_it_work_title}
                ></iframe>
              </div>
            </div>
          </SplideSlide>}
        )}
      </Splide>
    </>
  );
};
export default HowItWorks;
