import { useState, useEffect, useContext, useRef } from "react";
import SelectBox from "../../../componants/SelectBox/SelectBox";
import { MasterContext } from "../../../Context/index";
import { apiRequest } from "../../../Helpers/General";
import { useHistory } from "react-router";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
import ReactToast from "../../../componants/Toast/ReactToast";
const HomeSearch = (props) => {
  const stateRef = useRef([]);
  const cityRef = useRef([]);
  const statecityRef = useRef([]);
  const history = useHistory();
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [startDate, setStarDate] = useState(moment(firstDay).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(lastDay).format("YYYY-MM-DD"))
  const [cityOption, setcityOption] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    (props.searchParam && props.searchParam.country) || [{ country_id: '102', country: 'India', country_code: 'IN', country_dial_code: '+91' }]
  );
  const [selectedState, setSelectedState] = useState(
    (props.searchParam && props.searchParam.state) || []
  );
  const [selectedCity, setSelectedCity] = useState((props.searchParam && props.searchParam.city) || []);
  const [selectedStateCity, setSelectedStateCity] = useState((props.searchParam && props.searchParam.statecity) || []);
  const [statecityOption, setstatecityOption] = useState([]);
  const [selectedArea, setselectedArea] = useState(
    props.searchParam && props.searchParam.Area
  );
  const [selectedCourt, setselectedCourt] = useState(
    props.searchParam && props.searchParam.Court
  );
  const [selectedName, setselectedName] = useState(
    props.searchParam && props.searchParam.Name
  );
  const { countrydata, courtMasterData, expertiseMasterData } =
    useContext(MasterContext);
  const [stateOption, setStateoption] = useState([]);
  const [getStateDefault, setGetStateDefault] = useState(true);
  const[filtercourtData, setFiltercourtData] = useState([]);
    
  const changeCountry = async (e,istrue) => {
    setSelectedCountry(e);
     if (!istrue) {
      /*if (selectedState.length > 0) {
        stateRef.current && stateRef.current.clear();
        setSelectedState([]);
      }
      if (selectedCity.length > 0) {
        cityRef.current && cityRef.current.clear();
        setSelectedCity([]);
        setcityOption([]);
      }*/
      if (selectedStateCity.length > 0) {
        statecityRef.current && statecityRef.current.clear();
        setSelectedStateCity([]);
      }
     }
    
    if (e[0] && e[0].country_id) {     
        const court_Data = courtMasterData.filter((row1) => {
            return (
                (row1.cm_country_id && row1.cm_country_id === e[0].country_id)
            );
        });
        setFiltercourtData(court_Data);

      const apiSetting = {
        method: "GET",
        apiParams: { country_id: e[0].country_id },
      };
      /*const apiResult = await apiRequest("get_state_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setStateoption(apiResult.data.data);
        setGetStateDefault(false);
      }*/
      const apiResult = await apiRequest("get_city_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        const city_state_data = apiResult.data.data.filter((row1) => {
            return (
              (row1.city = row1.city+" - "+row1.state)
            );
        })
        setstatecityOption(city_state_data);
        setGetStateDefault(false);
      }
    } else {
      setStateoption([]);
      setstatecityOption([]);
      setGetStateDefault(false);
      setFiltercourtData([]);
    }
  };
  const changeState = async (e,istrue) => {
    // setSelectedState(e);
    // if (selectedCity.length > 0 && !istrue) {
    //   cityRef.current &&  cityRef.current.clear();
    //    setSelectedCity([]);
    // }
    setSelectedStateCity(e);
    if (e[0] && e[0].state_id) {
        const courtData = courtMasterData.filter((row) => {
            return (
                (row.cm_state_id && row.cm_state_id == e[0].state_id)
            );
        });
        setFiltercourtData(courtData);

      /*const apiSetting = {
        method: "GET",
        apiParams: { state_id: e[0].state_id },
      };
      const apiResult = await apiRequest("get_city_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setcityOption(apiResult.data.data);
      } else {
        setcityOption([]);
      }*/
    } else {
      // setcityOption([]);
      setFiltercourtData(courtMasterData);
    }
  };
  useEffect(() => {
    if (getStateDefault) {
      changeCountry(selectedCountry , true);
    }
    if (props.searchParam && props.searchParam.country) {
      changeCountry(props.searchParam.country, true);
    }
    /*if (props.searchParam && props.searchParam.state) {
      changeState(props.searchParam.state, true);
    }*/
    if (props.searchParam && props.searchParam.statecity) {
      changeState(props.searchParam.statecity, true);
    }
  },[]);

  const OnSearch = () => {
    let selecteddata = {
      country: selectedCountry,
      /*state: selectedState,
      city: selectedCity,*/
      statecity : selectedStateCity,
      Area: selectedArea,
      Court: selectedCourt,
      Name: selectedName,
      startDate: startDate,
      endDate: endDate,
      pageIndex : 1,
    };
    if (props.page === "Landing") {
      if (selecteddata.country === undefined &&
        // selecteddata.state === undefined &&
        selecteddata.statecity === undefined &&
        selecteddata.Area === undefined &&
        selecteddata.Court === undefined &&
        selecteddata.Name === undefined
      ) {
        ReactToast({ type: "error", message: "Selecet Dropdown to Search" });
        return false;
      } else {
        localStorage.setItem("searchParam", JSON.stringify(selecteddata));
        history.push("/lawyers-directory");
      }
    } else {
      localStorage.setItem("searchParam", JSON.stringify(selecteddata));
      props.OnChange(selecteddata);
      //localStorage.removeItem("searchParam");
    }
  };
  const onDateChange = (event, picker) => {
    let start = moment(picker.startDate).format("YYYY-MM-DD")
    let end = moment(picker.endDate).format("YYYY-MM-DD")
    setStarDate(start);
    setEndDate(end);
  }
  return (
    <div className="search-block">
      <div className={props.row ? "row" : ""}>
        <div className="form-group col-lg-12">
          <div className="form-row custom-dropdown">
            {props.row && (
              <label
                htmlFor="inputCurrency"
                className="float-start mb-1 d-flex"
              >
                Country
              </label>
            )}
            <div className="form-input position-relative">
              <SelectBox
                className="mdi mdi-chevron-down"
                name="country"
                labelKey="country"
                valueKey="country_id"
                defaultSelected={selectedCountry}
                defaultValue={selectedCountry}
                options={countrydata}
                OnChange={changeCountry}
                placeholder="Select Country"
              />
              <div className="input-err"></div>
            </div>
          </div>

          {/*<div className="form-row ustom-dropdown">
            {props.row && (
              <label
                htmlFor="inputCurrency"
                className="float-start mb-1 d-flex"
              >
                State
              </label>
            )}
            <div className="form-input position-relative">
              <SelectBox
                className="mdi mdi-chevron-down"
                name="country"
                labelKey="state"
                valueKey="state_id"
                defaultSelected={selectedState}
                defaultValue={selectedState}
                options={stateOption}
                OnChange={changeState}
                placeholder="State"
                ref={stateRef}
              />
              <div className="input-err"></div>
            </div>
          </div>*/}

          <div className="form-row ustom-dropdown">
            {props.row && (
              <label
                htmlFor="inputCurrency"
                className="float-start mb-1 d-flex"
              >
                City / State
              </label>
            )}
            <div className="form-input position-relative">
              <SelectBox
                className="mdi mdi-chevron-down city_state_id"
                name="statcity"
                labelKey="city"
                valueKey="city_state_id"
                defaultSelected={selectedStateCity}
                defaultValue={selectedStateCity}
                options={statecityOption}
                OnChange={changeState}
                placeholder="City / State"
                ref={statecityRef}
              />
              <div className="input-err"></div>
            </div>
          </div>
          {/*{props.type !== "Employment" &&
            <div className="form-row ustom-dropdown">
              {props.row && (
                <label
                  htmlFor="inputCurrency"
                  className="float-start mb-1 d-flex"
                >
                  City
                </label>
              )}
              <div className="form-input position-relative">
                <SelectBox
                  className="mdi mdi-chevron-down"
                  name="city"
                  labelKey="city"
                  valueKey="city_id"
                  ref={cityRef}
                  disabled = {selectedState.length ? false : true}
                  defaultValue={selectedCity || []}
                  options={cityOption || []}
                  OnChange={(e) => {
                    setSelectedCity(e);

                  }}
                  placeholder="City"
                  defaultSelected={selectedCity || []}
                />
                <div className="input-err"></div>
              </div>
            </div>}*/}
          {props.type !== "GC" && props.type !== "Employment" && (
            <div className="form-row custom-dropdown">
              {props.row && (
                <label
                  htmlFor="inputCurrency"
                  className="float-start mb-1 d-flex"
                >
                 Court of Practice
                </label>
              )}
              <div className="form-input position-relative">
                <SelectBox
                  className="mdi mdi-chevron-down"
                  name="state_dropdown "
                  labelKey="court_name"
                  valueKey="court_master_id"
                  defaultSelected={selectedCourt}
                  defaultValue={selectedCourt}
                  OnChange={(e) => setselectedCourt(e)}
                  options={filtercourtData || courtMasterData}
                  placeholder="Court of Practice"
                />
                <div className="input-err"></div>
              </div>
            </div>
          )}
          {props.type !== "GC" && props.type !== "Employment" && <div className="form-row custom-dropdown">
            {props.row && (
              <label
                htmlFor="inputCurrency"
                className="float-start mb-1 d-flex"
              >
                Practice Area
              </label>
            )}
            <div className="form-input position-relative">
              <SelectBox
                className="mdi mdi-chevron-down"
                name="state_dropdown "
                labelKey="expertise_name"
                valueKey="expertise_master_id"
                defaultSelected={selectedArea}
                defaultValue={selectedArea}
                OnChange={(e) => setselectedArea(e)}
                options={expertiseMasterData}
                placeholder="Practice Area"
              />
              <div className="input-err"></div>
            </div>
          </div>}

          {props.type !== "GC" && props.type !== "Employment" && (
            <div className="form-row custom-dropdown lawyer_input_wrap">
              {props.row && (
                <label
                  htmlFor="inputCurrency"
                  className="float-start mb-1 d-flex"
                >
                  Lawyer Name
                </label>
              )}
              <div className="form-input form-input-div position-relative">
                <input
                  type=""
                  className="form-control"
                  name="Lawyer_Name"
                  placeholder="Name"
                  defaultValue={selectedName}
                  onChange={(e) => setselectedName(e.target.value)}
                />
                <div className="input-err"></div>
              </div>
            </div>
          )}
          {props.type === "Employment" && (
            <div className="form-row custom-dropdown">
              {props.row && (
                <label
                  htmlFor="inputCurrency"
                  className="float-start mb-1 d-flex"
                >
                  Date
                </label>
              )}
              <div className="form-input form-input-div position-relative">
                <DateRangePicker
                  initialSettings={{
                    startDate: moment().startOf('month').toDate(),
                    endDate: moment().endOf('month').toDate(),
                    locale: {
                      format: 'DD/MM/YYYY',
                    },

                    ranges: {
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                      ],
                      'Last 7 Days': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                      ],
                      'Last 30 Days': [
                        moment().subtract(29, 'days').toDate(),
                        moment().toDate(),
                      ],
                      'This Month': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                      ],
                      'Last Month': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                      ],
                    },
                  }}
                  onApply={onDateChange}
                >

                  <input type="text" className="form-control" />

                </DateRangePicker>
                <div className="input-err"></div>
              </div>
            </div>
          )}
          <div className="search-action">
            <button
              type="submit"
              className="btn-primary"
              onClick={OnSearch}
            >
              {props.row ? "Search" : "Apply"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSearch;
