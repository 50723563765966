import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { apiRequest } from "../../../../Helpers/General";
import iconJobOpp from "./../../../../asset/svg/icon-jonopp.svg";
import iconProfile from "./../../../../asset/svg/icon-profile.svg";
import EmployeeCardDetail from "../../../../componants/EmployeeCard/EmployeeCardDetail";
import { useParams } from "react-router-dom";
import moment from "moment";
import JobOpportunity from "../../../LoginRequired/MyProfile/JobOpportunity/JobOpportunity";
import { directoryMobile } from "../../../../Helpers/General";
import Loader from "../../../../componants/Loader/Loader";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import DocumentMeta from "react-document-meta";
const GcDirectoryDetail = (props) => {
  directoryMobile();
  let { id } = useParams();
  let ID = id.split("-").pop();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [GcDetail, setGcDetail] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [isOnPage, setIsOnPage] = useState("");
  const [collg, setCollg] = useState(6);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: "102", user_id: ID },
        };
        const apiResult = await apiRequest("get_user_profile_info", apiSetting);
        if (apiResult.data.settings.success === "1") {
          setGcDetail(apiResult.data.data.return_data[0]);
          if (
            !apiResult.data.data.return_data[0].rank_data &&
            !apiResult.data.data.return_data[0].awards
          ) {
            setCollg(12);
          } else if (
            (!apiResult.data.data.return_data[0].rank_data &&
              apiResult.data.data.return_data[0].awards) ||
            (apiResult.data.data.return_data[0].rank_data &&
              !apiResult.data.data.return_data[0].awards)
          ) {
            setCollg(6);
          }
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
    // eslint-disable-next-line
  }, [isPageUpdating, id]);
  const meta = {
    title: GcDetail.meta_title && GcDetail.meta_title,
    description: GcDetail.meta_description && GcDetail.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: GcDetail.meta_key_words && GcDetail.meta_key_words,
      },
    },
  };
  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <DocumentMeta {...meta}>
        <div
          className="inner-page gcdirectory-detail-page directory-detail-page"
          id="directoryMobileMenu"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  order-1 directory-search">
                <div className="right-search-block">
                  <div className="right-block-title d-flex flex-column">
                    <h2 className="mb-5">
                      Explore <span>More</span>
                    </h2>
                    <div className="card">
                      <div className="card-content">
                        <ul
                          className="nav nav-tabs tabs flex-column d-flex"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#profileTab"
                              type="button"
                              role="tab"
                            >
                              <img src={iconProfile} alt="" />
                              Profile
                            </button>
                          </li>
                          {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="publicaton-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#publicatonTab"
                          type="button"
                          role="tab"
                        >
                          <img src={iconPublication} alt="" />
                          Publication
                        </button>
                      </li> */}

                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              data-bs-toggle="tab"
                              data-bs-target="#jobOpportunitiesTab"
                              type="button"
                              role="tab"
                              onClick={() => setIsOnPage("Job Opportunities")}
                            >
                              <img src={iconJobOpp} alt="" />
                              Job Opportunities
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                <div
                  className="common-page-content publication-content"
                  id="directoryMobile"
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div
                        className={
                          "event-lst-box d-flex justify-content-between without-shadow " +
                          (GcDetail.expertise_ranking ? "" : "no-ranking-badge")
                        }
                      >
                        <EmployeeCardDetail
                          page="Gc"
                          row={GcDetail}
                          {...props}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="tab_container tab-content" id="myTabContent">
                    <div
                      className="tab-pane tab_content publication-tab-content active"
                      id="profileTab"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      {/* <!-- start --> */}
                      <div className="tab-inner-content">
                        <h2 className="mobile-view">Profile</h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          <div className="common-block common-block-merge">
                            <div className="common-block-lft">
                             
                               {GcDetail.user_about && <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="">
                                      <div className="common-block-title">
                                        <h3>About The General Counsel</h3>
                                      </div>
                                      <div className="common-block-content">
                                        <p className="common-paragraph">
                                          {GcDetail.user_about
                                            ? GcDetail.user_about
                                            :  <NoRecord pageName={
                                              "about detail provided by user"
                                            }/>}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                            
                              {GcDetail.education && (
                                <div className="">
                                  <div className="common-block-title">
                                    <h3>Educational Qualifications</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block">
                                      {GcDetail.education ? (
                                        GcDetail.education.map((item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <span>
                                              {item.user_university_name}
                                            </span>
                                            <label>
                                              {item.user_degree_name +
                                                " - " +
                                                item.user_from_year +
                                                " - " +
                                                item.user_to_year}
                                            </label>
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord
                                          pageName={
                                            "Educational Qualifications"
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {GcDetail.event_data && (

                                <div className="">
                                  <div className="common-block-title">
                                    <h3>Events Attended</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block timeline-without-doticon">
                                      {GcDetail.event_data ? (
                                        GcDetail.event_data.map((item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <label>
                                              {moment(item.attened_date).format(
                                                "ll"
                                              )}
                                            </label>
                                            <span>{item.event_name}</span>
                                            <label>Venue : {item.venue}</label>
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord
                                          pageName={"Events Attended"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>

                              )}
                            </div>
                            <div className="common-block-rgt">
                              {GcDetail.awards && (

                                <div className="">
                                  <div className="common-block-title">
                                    <h3>Awards</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block">
                                      {GcDetail.awards ? (
                                        GcDetail.awards.map((item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <span>
                                              {item.user_award_name}
                                            </span>
                                            <label>
                                              {item.user_award_year}
                                            </label>
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord pageName={"Awards"} />
                                      )}
                                    </div>
                                  </div>
                                </div>

                              )}

                              {GcDetail.work_org && (
                                <div className="">
                                  <div className="common-block-title">
                                    <h3>Employment Information</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block">
                                      {GcDetail.work_org ? (
                                        GcDetail.work_org.map((item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <span>{item.user_org_title}</span>
                                            <label>
                                              {item.user_designation}
                                            </label>
                                            <label>
                                              {moment(item.user_from_date).format("MMM YYYY")}
                                              {item.user_current_org === "Yes" || item.user_to_date === "" ? " - Present"  : " - " + moment(item.user_to_date).format("MMM YYYY")}
                                            </label>
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord
                                          pageName={"Employment Information"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- end --> */}

                      <div className="tab-inner-content d-none">
                        <h2 className="mobile-view">Profile</h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {GcDetail.user_about && (
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>About The general counsel</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <p className="common-paragraph" >
                                      {GcDetail.user_about
                                        ? GcDetail.user_about
                                        : "--"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {(GcDetail.user_year_of_enrollment ||
                            GcDetail.user_bar_council) && (
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="common-block">
                                      <div className="common-block-title">
                                        <h3>Practice Information</h3>
                                      </div>
                                      <div className="common-block-content">
                                        <div className="common-list-block">
                                          {GcDetail.user_year_of_enrollment && (
                                            <div className="common-list">
                                              <label>Year of Enrollment</label>
                                              <span>
                                                {GcDetail.user_year_of_enrollment
                                                  ? GcDetail.user_year_of_enrollment
                                                  : "--"}
                                              </span>
                                            </div>
                                          )}
                                          {GcDetail.user_bar_council && (
                                            <div className="common-list">
                                              <label>Bar Council</label>
                                              <span>
                                                {GcDetail.user_bar_council
                                                  ? GcDetail.user_bar_council
                                                  : "--"}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Birth Date</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block">
                                      <div className="common-list">
                                        <span className="font-large">
                                          {GcDetail.user_birth_date!=="" &&
                                            GcDetail.user_birth_date !==
                                            "0000-00-00"
                                            ? moment(
                                              GcDetail.user_birth_date
                                            ).format("DD")
                                            : "--"}
                                        </span>
                                        <label>
                                          {GcDetail.user_birth_date &&
                                            GcDetail.user_birth_date !==
                                            "0000-00-00"
                                            ? moment(
                                              GcDetail.user_birth_date
                                            ).format("MMMM YYYY")
                                            : ""}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Experience</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block">
                                      <div className="common-list">
                                        <span className="font-large">
                                          {GcDetail.user_experience?GcDetail.user_experience:"--"}
                                        </span>
                                        {GcDetail.user_experience&&<label>Years</label>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                              </div>
                            )}
                          {GcDetail.education && (
                            <div className="row">
                              <div
                                className={
                                  "col-lg-" +
                                  collg +
                                  " col-md-6 col-sm-12 col-xs-12"
                                }
                              >
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Educational Qualifications</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block">
                                      {GcDetail.education ? (
                                        GcDetail.education.map((item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <span>
                                              {item.user_university_name}
                                            </span>
                                            <label>
                                              {item.user_degree_name +
                                                " - " +
                                                item.user_from_year +
                                                " - " +
                                                item.user_to_year}
                                            </label>
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord
                                          pageName={
                                            "Educational Qualifications"
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {GcDetail.awards && (
                                <div
                                  className={
                                    "col-lg-" +
                                    collg +
                                    " col-md-6 col-sm-12 col-xs-12"
                                  }
                                >
                                  <div className="common-block">
                                    <div className="common-block-title">
                                      <h3>Awards</h3>
                                    </div>
                                    <div className="common-block-content">
                                      <div className="common-list-block timeline-list-block">
                                        {GcDetail.awards ? (
                                          GcDetail.awards.map((item, key) => (
                                            <div
                                              className="common-list"
                                              key={key}
                                            >
                                              <span>
                                                {item.user_award_name}
                                              </span>
                                              <label>
                                                {item.user_award_year}
                                              </label>
                                            </div>
                                          ))
                                        ) : (
                                          <NoRecord pageName={"Awards"} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {GcDetail.lang_data && (
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Languages Known </h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-badges-list">
                                      {GcDetail.lang_data ? (
                                        GcDetail.lang_data.map((item, key) => (
                                          <div
                                            className="common-gray-badge"
                                            key={key}
                                          >
                                            {item.vLanguageName}
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord
                                          pageName={"Languages Known"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="row">
                            {GcDetail.work_org && (
                              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Employment Information</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block">
                                      {GcDetail.work_org ? (
                                        GcDetail.work_org.map((item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <span>{item.user_org_title}</span>
                                            <label>
                                              {item.user_designation}
                                            </label>
                                            <label>
                                              {moment(
                                                item.user_from_date
                                              ).format("MMM YYYY")}{" "}
                                              - Present
                                            </label>
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord
                                          pageName={"Employment Information"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {GcDetail.event_data && (
                              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Events Attended</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block timeline-without-doticon">
                                      {GcDetail.event_data ? (
                                        GcDetail.event_data.map((item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <label>
                                              {moment(item.attened_date).format(
                                                "ll"
                                              )}
                                            </label>
                                            <span>{item.event_name}</span>
                                            <label>Venue : {item.venue}</label>
                                          </div>
                                        ))
                                      ) : (
                                        <NoRecord
                                          pageName={"Events Attended"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab_content jobopp-tab-content"
                      id="publicatonTab"
                      role="tabpanel"
                      aria-labelledby="publication-tab"
                    >
                      {/* <Publication id={id} type="ViewOnly"/> */}
                    </div>
                    <div
                      className="tab-pane tab_content jobopp-tab-content"
                      id="jobOpportunitiesTab"
                      role="tabpanel"
                      aria-labelledby="jobopp-tab"
                    >
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => setIsOnPage("Job Opportunities")}
                        >
                          Job Opportunities
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Job Opportunities" && (
                            <JobOpportunity id={ID} type="ViewOnly" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default GcDirectoryDetail;
