import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useParams } from "react-router-dom";
import iconPublication from "./../../../../asset/svg/icon-publication.svg";
import iconPhoto from "./../../../../asset/svg/icon-photos.svg";
import iconVideo from "./../../../../asset/svg/icon-videos.svg";
import iconJobOpp from "./../../../../asset/svg/icon-jonopp.svg";
import iconProfile from "./../../../../asset/svg/icon-profile.svg";
import EmployeeCardDetail from "../../../../componants/EmployeeCard/EmployeeCardDetail";
import { apiRequest, formatUrlLink } from "../../../../Helpers/General";
import Photos from "../../../LoginRequired/MyProfile/Photos/Photos";
import Videos from "../../../LoginRequired/MyProfile/Videos/Videos";
import JobOpportunity from "../../../LoginRequired/MyProfile/JobOpportunity/JobOpportunity";
import Publication from "../../../LoginRequired/MyProfile/Publication/Publication";
import Loader from "../../../../componants/Loader/Loader";
import { directoryMobile } from "../../../../Helpers/General";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import DocumentMeta from "react-document-meta";
import ImageZoom from "react-medium-image-zoom";
const LawyerDirectoryDetail = (props) => {
  directoryMobile();
  let { id } = useParams();
  let ID = id.split("-").pop();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [LawyerDetail, setLawyerDetail] = useState([]);
  const [isOnPage, setIsOnPage] = useState("");
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [toggle, settoggle] = useState(true);
  // eslint-disable-next-line
  const [collg, setCollg] = useState(4);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(() => {
    if (isOnPage) {
      var isMobile = navigator.userAgentData.mobile;

      if (isMobile) {
        window.scrollTo({ top: 200, behavior: "smooth" });
      }
    }
  }, [isOnPage]);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: "102", user_id: ID },
        };
        const apiResult = await apiRequest("get_user_profile_info", apiSetting);
        if (apiResult.data.settings.success === "1") {
          setLawyerDetail(apiResult.data.data.return_data[0]);
          if (
            !apiResult.data.data.return_data[0].rank_data &&
            !apiResult.data.data.return_data[0].awards
          ) {
            setCollg(12);
          } else if (
            (!apiResult.data.data.return_data[0].rank_data &&
              apiResult.data.data.return_data[0].awards) ||
            (apiResult.data.data.return_data[0].rank_data &&
              !apiResult.data.data.return_data[0].awards)
          ) {
            setCollg(6);
          }
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating, ID]);
  const meta = {
    title: LawyerDetail.meta_title && LawyerDetail.meta_title,
    description: LawyerDetail.meta_description && LawyerDetail.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: LawyerDetail.meta_key_words && LawyerDetail.meta_key_words,
      },
    },
  };
  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <DocumentMeta {...meta}>
        <div
          className="inner-page gcdirectory-detail-page directory-detail-page"
          id="directoryMobileMenu"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 order-1 directory-search ">
                <div className="right-search-block">
                  <div className="right-block-title d-flex flex-column">
                    <h2 className="mb-5">
                      Explore <span>More</span>
                    </h2>
                    <div className="card">
                      <div className="card-content">
                        <ul
                          className="nav nav-tabs tabs flex-column d-flex"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#profileTab"
                              type="button"
                              role="tab"
                            >
                              <img src={iconProfile} alt="" />
                              Profile
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="publicaton-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#publicatonTab"
                              type="button"
                              role="tab"
                              onClick={() => setIsOnPage("Publication")}
                            >
                              <img src={iconPublication} alt="" />
                              Publication
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="photos-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#photosTab"
                              type="button"
                              role="tab"
                              onClick={() => setIsOnPage("Photos")}
                            >
                              <img src={iconPhoto} alt="" />
                              Photos
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="videos-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#videosTab"
                              type="button"
                              role="tab"
                              onClick={() => setIsOnPage("Videos")}
                            >
                              <img src={iconVideo} alt="" />
                              Videos
                            </button>
                          </li>

                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="jobs-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#jobsTab"
                              type="button"
                              role="tab"
                              onClick={() => setIsOnPage("Job Opportunities")}
                            >
                              <img src={iconJobOpp} alt="" />
                              Job Opportunities
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                <div
                  className="common-page-content publication-content"
                  id="directoryMobile"
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div
                        className={
                          "event-lst-box d-flex justify-content-between without-shadow " +
                          (LawyerDetail.expertise_ranking
                            ? ""
                            : "no-ranking-badge")
                        }
                      >
                        <EmployeeCardDetail
                          page="Lowyer"
                          row={LawyerDetail}
                          {...props}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="tab_container tab-content" id="myTabContent">
                    <div
                      className="tab-pane tab_content profile-tab-content active"
                      id="profileTab"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      {/* <!-- start --> */}
                      <div className="tab-inner-content">
                      <h2 className="mobile-view">Profile</h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        <div className="common-block common-block-merge">
                          <div className="common-block-lft">
                           
                              {LawyerDetail.user_about && <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="common-block-title">
                                    <h3>About The Lawyer</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <p className="common-paragraph">
                                      {LawyerDetail.user_about
                                        ? LawyerDetail.user_about
                                        :  <NoRecord pageName={
                                          "about detail provided by user"
                                        }/>}
                                    </p>
                                  </div>
                                </div>
                              </div>}
                        

                            {LawyerDetail.education && (
                              <div className="">
                                <div className="common-block-title">
                                  <h3>Educational Qualifications</h3>
                                </div>
                                <div className="common-block-content">
                                  <div className="common-list-block timeline-list-block">
                                    {LawyerDetail.education ? (
                                      LawyerDetail.education.map(
                                        (item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <span>
                                              {item.user_university_name}
                                            </span>
                                            <label>
                                              {item.user_degree_name +
                                                " - " +
                                                item.user_from_year +
                                                " - " +
                                                item.user_to_year}
                                            </label>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <NoRecord
                                        pageName={"Educational Qualifications"}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="common-block-rgt">

                            <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {LawyerDetail.expertise_ranking && (
                              <div className="directory-ranking mb-4">
                                <div className="common-block-title">
                                  <h3>Xperts Legal Ranking</h3>
                                </div>
                                <div className="emp-award d-flex flex-row ">
                                  {
                                    // props.page === "Lowyer" &&
                                    LawyerDetail.expertise_ranking &&
                                      LawyerDetail.expertise_ranking.map(
                                        (item, key) => {
                                          let expertieSName =
                                            LawyerDetail.expertise.filter(
                                              (row) =>
                                                row.user_expertise_master_id ===
                                                item.expertise_master_id
                                            );
                                          return (
                                            <div
                                              className={
                                                " m-2 emp_ranking ranking-color-" +
                                                item.rank_level
                                              }
                                              key={key}
                                            >
                                              {" "}
                                              <ImageZoom>
                                                <img
                                                  className="svg"
                                                  src={
                                                    item.rank_image ||
                                                    `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                                                  }
                                                  alt=""
                                                />
                                                <h4>
                                                  {expertieSName &&
                                                  expertieSName[0]
                                                    .expertise_name
                                                    ? expertieSName[0]
                                                        .expertise_name
                                                    : ""}
                                                </h4>
                                              </ImageZoom>
                                            </div>
                                          );
                                        }
                                      )
                                  }
                                </div>
                              </div>
                            )}
                            {LawyerDetail.rank_data && (
                              <div className="">
                                <div className="common-block-title">
                                  <h3>Additional Rankings</h3>
                                </div>
                                <div className="common-block-content">
                                  <div className="common-list-block timeline-list-block">
                                    {LawyerDetail.rank_data ? (
                                      LawyerDetail.rank_data.map(
                                        (item, key) => (
                                          <div
                                            className="common-list"
                                            key={key}
                                          >
                                            <a
                                            href={formatUrlLink(item.user_credential_link)}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {item.user_rank_title}
                                            </a>
                                            <label>&nbsp;</label>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <NoRecord
                                        pageName={"Additional Rankings"}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                            {LawyerDetail.awards && (
                              <div className="">
                                <div className="common-block-title">
                                  <h3>Awards</h3>
                                </div>
                                <div className="common-block-content">
                                  <div className="common-list-block timeline-list-block">
                                    {LawyerDetail.awards ? (
                                      LawyerDetail.awards.map((item, key) => (
                                        <div className="common-list" key={key}>
                                          <span>{item.user_award_name}</span>
                                          <label>{item.user_award_year}</label>
                                        </div>
                                      ))
                                    ) : (
                                      <NoRecord pageName={"Awards"} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

</div></div>
                          </div>
                        </div></div>
                      </div>
                      {/* <!-- end --> */}

                    </div>

                    <div
                      className="tab-pane tab_content publication-tab-content"
                      id="publicatonTab"
                      role="tabpanel"
                      aria-labelledby="publicaton-tab"
                    >
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => {
                            settoggle(!toggle);
                            setIsOnPage(toggle ? "Publication" : "");
                          }}
                        >
                          Publication
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Publication" && (
                            <Publication id={ID} type="ViewOnly" />
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane tab_content photos-tab-content"
                      id="photosTab"
                      role="tabpanel"
                      aria-labelledby="photos-tab"
                    >
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => {
                            settoggle(!toggle);
                            setIsOnPage(toggle ? "Photos" : "");
                          }}
                        >
                          Photos
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Photos" && (
                            <Photos id={ID} type="ViewOnly" {...props} />
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane tab_content videos-tab-content"
                      id="videosTab"
                      role="tabpanel"
                      aria-labelledby="videos-tab"
                    >
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => {
                            settoggle(!toggle);
                            setIsOnPage(toggle ? "Videos" : "");
                          }}
                        >
                          Videos
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Videos" && (
                            <Videos id={ID} type="ViewOnly" {...props} />
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane tab_content jobopp-tab-content"
                      id="jobsTab"
                      role="tabpanel"
                      aria-labelledby="jobs-tab"
                    >
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => {
                            settoggle(!toggle);
                            setIsOnPage(toggle ? "Job Opportunities" : "");
                          }}
                        >
                          Job Opportunities
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Job Opportunities" && (
                            <JobOpportunity id={ID} type="ViewOnly" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default LawyerDirectoryDetail;
