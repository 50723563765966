import React, { useEffect } from "react";
import DocumentMeta from "react-document-meta";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";

export default function Sitemap(props) {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const meta = {
    title: "Xpert Legal Sitemap",
    description: "Xpert Legal Sitemap",
    canonical: "Xpert Legal Sitemap",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Xpert Legal Sitemap",
      },
    },
  };



  return (
    <DocumentMeta {...meta}>

      <div className="inner-page Sitemap">
        <div className="container">
          <div className="inner-head d-flex align-item-center">
            <div className="inner-head-left">
              <div className="page-title  text-start">
                <h2>Xperts Legal Sitemap</h2>
              </div>
            </div>
          </div>
          <div className="sitemap-rw-inner">
            <div className="sitemap-rw-two">
              <div className="sub-hd"><a href="/" target="_blank">All Pages</a></div>
              <ul>
                <li><a href="/" target="_blank">Home</a></li>
                <li><a href="/networking-details" target="_blank">Networking</a></li>
                <li><a href="/lawyers-directory" target="_blank">Lawyers</a></li>
                <li><a href="/About" target="_blank">About Us</a></li>
                <li><a href="/Events" target="_blank">Events</a></li>
                <li><a href="/general-counsel" target="_blank">General Counsel</a></li>
                <li><a href="/ContactUs" target="_blank">Contact Us</a></li>
                <li><a href="/Events/Webinar" target="_blank">Webinars</a></li>
                <li><a href="/Ranking" target="_blank">Ranking</a></li>
                <li><a href="/PrivacyPolicy" target="_blank">Privacy Policy</a></li>
                <li><a href="/Events/Conferences" target="_blank">Conferences</a></li>
                <li><a href="/Publications" target="_blank">Publications</a></li>
                <li><a href="/TermsCondition" target="_blank">Terms &amp; Condition</a></li>
                <li><a href="/Events/Skill" target="_blank">Skill Development Programs</a></li>
                <li><a href="/Employment" target="_blank">Employment</a></li>
                <li><a href="/Faqs" target="_blank">Faq's</a></li>
                <li><a href="/MembershipCheckout" target="_blank">Sign Up</a></li>
                <li><a href="/gcsignupform" target="_blank">Gc Sign Up  </a></li>
                <li><a href="/Pricing" target="_blank">Pricing</a></li>
                <li><a href="/login" target="_blank">Login</a></li>
                <li><a href="/forgotpassword" target="_blank">Forgot password</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
}
