import { useEffect,useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { MasterContext } from "../../../../Context";
import swal from "sweetalert";
import { apiRequest } from "../../../../Helpers/General";
import { useState } from "react";
const ContactUs = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const { genralMasterData } = useContext(MasterContext);
  const [loading ,setLoading] = useState(false);
  let addressData = genralMasterData;
  let contactUsLabel = genralMasterData.CONTACT_US
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let history = useHistory();
  const onSubmit = async(data) => {
  
    try {
      setLoading(true);
      let parms = {
        first_name:data.firstname, 
        last_name:data.lastname,
        email_id:data.email,
        decription:data.description,
        country_id:"102"
      };
      const apiSetting = {
        method: "POST",
        apiParams: parms,
      };
      const apiResult = await apiRequest("contact_us", apiSetting);
      if (apiResult.data.settings.success === "1") {
        swal({
          title: "Success",
          text: apiResult.data.settings.message,
          icon: "success",
          button: {
            text: "Done",
            closeModal: true,
          },
          dangerMode: false,
        });
        
    history.push("./thank-you");
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }

  };
  return (
    <div className="inner-page contact-page common-content-page">
      <div className="container">
        <div className="row">
          <div className="page-title  text-center">
            <h1>{contactUsLabel&&contactUsLabel.gm_heading?contactUsLabel.gm_heading:"Contact Us"}</h1>
          </div>
        </div>
        <div className="row">
          <div className="mt-5 col-lg-7 brd-rgt">
            <form
              id="documents-add-hook-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                    <label
                      htmlFor="inputFirstName"
                      className="float-start mb-1 d-flex"
                    >
                      First Name <em>*</em>
                    </label>
                    <div className="form-input position-relative">
                      <input
                        type="text"
                        id="inputFirstName"
                        className="form-control"
                        placeholder=" Enter Your first name"
                        {...register("firstname", {
                          required: "First Name is required",
                        })}
                      />
                      <div className="input-err">
                        {errors?.firstname?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                    <label
                      htmlFor="inputLastName"
                      className="float-start mb-1 d-flex"
                    >
                      Last Name <em>*</em>
                    </label>
                    <div className="form-input position-relative">
                      <input
                        type="text"
                        id="inputLastName"
                        className="form-control"
                        placeholder="Enter Your last name"
                        {...register("lastname", {
                          required: "Last Name is required",
                        })}
                      />
                      <div className="input-err">
                        {errors?.lastname?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                    <label
                      htmlFor="inputEmail"
                      className="float-start mb-1 d-flex"
                    >
                      Email <em>*</em>
                    </label>
                    <div className="form-input position-relative">
                      <input
                        type="text"
                        id="inputEmail"
                        className="form-control"
                        placeholder="Enter Your email"
                        {...register("email", {
                          required: "Email is requierd",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Enter a valid Email",
                          },
                        })}
                      />
                      <div className="input-err">{errors?.email?.message}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                       Message / Query<em>*</em>
                      </label>
                      <div className="form-input position-relative">
                        <textarea
                          id="inputDescription"
                          className="form-control custom-textarea"
                          placeholder="Enter your message / query here"
                          {...register("description", {
                            required: "Description required",
                          })}
                        />
                        <div className="input-err">
                          {errors?.description?.message}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </form>

            <div className="search-action mt-5">
            {loading ? <button
                            form="contact-person-add-hook-form"
                            className="w-20 btn btn-primary m-auto"> 
                            <div className="spinner-border text-white  text-center" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> </button>: <button
                type="submit"
                className="btn-primary btn-search"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </button>}
            </div>
          </div>
          <div className="mt-5 col-lg-5 brd-lft">
            <div className="address-map-img">
              <img src="/asset/images/address_map.png" className="svg" alt={addressData.company_address} />
            </div>
            <div className="contact-address brd-btm mb-3 mt-3 pb-2">
              {addressData && addressData.company_name != undefined && addressData.company_name != '' && (
                <div className="mb-2 contact_company_name" >
                  <strong>{addressData.company_name}</strong>
                </div>
              )}
              {addressData && addressData.company_address != undefined && addressData.company_address != '' && (
                <h6 className="addess-txt">{addressData.company_address}</h6>
              )}
            </div>
            <div className="contact-communication">
              <ul>
                  {addressData && addressData.company_email != undefined && addressData.company_email != '' && (
                      <li>
                        <label>Email Us: </label>
                        {addressData.company_email}
                      </li>
                  )}
                  {addressData && addressData.company_phone != undefined && addressData.company_phone != '' && (
                      <li>
                        <label>Call Us: </label>
                        {addressData.company_phone}
                      </li>
                    )}
                  {addressData && addressData.preferred_time != undefined && addressData.preferred_time != '' && (
                <li>
                  <label>Prefered Time To Call: </label>
                  {addressData.preferred_time}
                </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
