import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect, useState } from "react";

import { apiRequest } from "../../../../Helpers/General";
import moment from "moment";


const ViewProfile = (props) => {
  let id = props.userProfile.user_master_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [UserDetail, setUserDetail] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: "102", user_id: id },
        };
        const apiResult = await apiRequest("get_user_profile_info", apiSetting);
        if (apiResult.data.settings.success === "1") {
          setUserDetail(apiResult.data.data.return_data[0]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating, id]);
  return (
    <div className="view-profile-page">
     
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="common-block">
            <div className="common-block-title">
              <h2>About</h2>
            </div>
            <div className="common-block-content">
              <p className="common-paragraph">{UserDetail.user_about}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="common-block">
              <div className="common-block-title">
                <h2>Practice Information</h2>
              </div>
              <div className="common-block-content">
                <div className="common-list-block">
                  <div className="common-list">
                    <label>Year of Enrollment</label>
                    <span>{UserDetail.user_year_of_enrollment}</span>
                  </div>
                  <div className="common-list">
                    <label>Bar Council</label>
                    <span>{UserDetail.user_bar_council}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="common-block">
                <div className="common-block-title">
                  <h2>Birth Date</h2>
                </div>
                <div className="common-block-content">
                  <div className="common-list-block">
                    <div className="common-list">
                      <span className="font-large">
                        {UserDetail.user_birth_date !== "0000-00-00" &&
                          moment(UserDetail.user_birth_date).format("DD")}
                      </span>
                      <label>
                        {UserDetail.user_birth_date !== "0000-00-00" &&
                          moment(UserDetail.user_birth_date).format(
                            "MMMM YYYY"
                          )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="common-block">
                <div className="common-block-title">
                  <h2>Experience (In Years)</h2>
                </div>
                <div className="common-block-content">
                  <div className="common-list-block">
                    <div className="common-list">
                      <span className="font-large">
                        {UserDetail.user_experience}
                      </span>
                      <label>Years</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="common-block">
            <div className="common-block-title">
              <h2>Educational Qualifications</h2>
            </div>
            <div className="common-block-content">
              <div className="common-list-block timeline-list-block">
                {UserDetail.education &&
                  UserDetail.education.map((item, key) => (
                    <div className="common-list" key={key}>
                      <span>{item.user_university_name}</span>
                      <label>
                        {item.user_degree_name +
                          " - " +
                          item.user_from_year +
                          " - " +
                          item.user_to_year}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="common-block">
            <div className="common-block-title">
              <h2>Awards</h2>
            </div>
            <div className="common-block-content">
              <div className="common-list-block timeline-list-block">
                {UserDetail.awards &&
                  UserDetail.awards.map((item, key) => (
                    <div className="common-list" key={key}>
                      <span>{item.user_award_name}</span>
                      <label>{item.user_award_year}</label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="common-block">
            <div className="common-block-title">
              <h2>Additional Rankings</h2>
            </div>
            <div className="common-block-content">
              <div className="common-list-block timeline-list-block">
                {UserDetail.rank_data &&
                  UserDetail.rank_data.map((item, key) => (
                    <div className="common-list" key={key}>
                      <span>{item.user_rank_title}</span>
                      <label>&nbsp;</label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="common-block">
            <div className="common-block-title">
              <h2>Areas Of Expertise</h2>
            </div>
            <div className="common-block-content">
              <div className="common-badges-list">
                {UserDetail.expertise &&
                  UserDetail.expertise.map((item, key) => (
                    <div className="common-gray-badge" key={key}>
                      {item.user_expertise_name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="common-block">
            <div className="common-block-title">
              <h2>Courts of Practice</h2>
            </div>
            <div className="common-block-content">
              <div className="common-badges-list">
                {UserDetail.court_data &&
                  UserDetail.court_data.map((item, key) => (
                    <div className="common-gray-badge">
                      {item.user_court_name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="common-block">
            <div className="common-block-title">
              <h2>Languages Known </h2>
            </div>
            <div className="common-block-content">
              <div className="common-badges-list">
                {UserDetail.lang_data &&
                  UserDetail.lang_data.map((item, key) => (
                    <div className="common-gray-badge" key={key}>
                      {item.vLanguageName}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
