import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { Link, useParams } from "react-router-dom";
import { apiRequest, formatUrlLink } from "../../../../Helpers/General";
import iconTime from "./../../../../asset/svg/icon-time.svg";
import moment from "moment";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
//import ImageZoom from "react-medium-image-zoom";
import Avatar from "../../../../componants/Avatar/Avatar";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
const PublicationsDetails = (props) => {
  let { id } = useParams();
  let ID = id.split("-").pop();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [publicationData, setPublicationData] = useState([]);
  const [publicationRefData, setPublicationRefData] = useState([]);
  const [publicationCategoryData, setPublicationCategoryData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            publication_master_id: ID,
          },
        };
        const apiResult = await apiRequest(
          "get_publication_master_detail",
          apiSetting
        );
        if (apiResult.data.settings.success === "1" && (  apiResult.data.data.publication_list[0].master_data.publication_status!=="Inactive" || 
        apiResult.data.data.publication_list[0].master_data.user_master_id === (props.userProfile.user_master_id || 0)) ) { 
          setPublicationData(
            apiResult.data.data.publication_list[0].master_data
          );
          setPublicationRefData(
            apiResult.data.data.publication_list[0].ref_data &&
            apiResult.data.data.publication_list[0].ref_data
          );
          setPublicationCategoryData(
            apiResult.data.data.publication_list[0].category_data &&
            apiResult.data.data.publication_list[0].category_data
          );
          setIsPageUpdating(false);
        } else {
          setPublicationData([]);
          setPublicationRefData([]);
          setPublicationCategoryData([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };

    if (isPageUpdating) {
      GetData();
    }
     // eslint-disable-next-line
  }, [isPageUpdating, id]);

  const meta = {
    title: publicationData.meta_title && publicationData.meta_title,
    description: publicationData.meta_description && publicationData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: publicationData.meta_key_words && publicationData.meta_key_words,
      },
    },
  };
  if (isPageUpdating) {
    return <Loader />
  } else if (publicationData.length === 0) {
    return <div className="inner-page publication-detail-page"><NoRecord pageName="Publication Found" /></div>
    }else{
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page publication-detail-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="common-page-content publication-detail-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="publication-posted-by d-flex flex-row  ">
                      {publicationData.vProfilePic ? 
                                          <div className="posted-pic">
                          <img src={publicationData.vProfilePic || `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`} alt={publicationData.alt_for_profile_pic ? publicationData.alt_for_profile_pic : ""} />
                        </div> : <div className="posted-pic">
                                          <Avatar
                                            id={publicationData.id}
                                            fullName={publicationData.user_name}
                                            pictureUrl={
                                              publicationData.vProfilePic
                                                ? publicationData.vProfilePic
                                                : ""
                                            }
                                            alt={publicationData.alt_for_profile_pic ? publicationData.alt_for_profile_pic : ""}
                                            size="50px"
                                            fontSize="12px"
                                          ></Avatar>

                                        </div>}
                        <div className="posted-content">
                          <div className="publication-by">
                            <Link to={
                              "/lawyer-profile/" + publicationData.user_master_id
                            }
                            >
                              {publicationData.user_name}
                            </Link>
                          </div>
                          {publicationData.user_organization_name &&
                          <div className="publication-by">
                            From: {publicationData.user_organization_name}
                          </div>
  }
                          <div className="publication-date">
                            <i className="">
                              <img src={iconTime} className="svg" alt="" />
                            </i>
                            Posted On :{" "}
                            {moment(
                              publicationData.publicaton_publication_date
                            ).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="publication-posted-by d-flex flex-row float-end org-logo">
                        <div className="posted-pic">
                          <img
                            src={publicationData.user_organization_logo || `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`}
                            alt={publicationData.alt_for_org_image ? publicationData.alt_for_org_image : ""}
                          />
                        </div>
                        <div className="posted-content">
                         

                          {/* <div className="publication-date">
                            {publicationData.user_address}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="detail-content">
                        <div className="detail-content-top">

                          <div className="detail-page-title page-title">
                            <h2>{publicationData.publicaton_title}</h2>
                          </div>
                          <div className="detail-page-status d-flex">
                            {publicationCategoryData.map((item, index) => (
                              <span className="practice-type" key={index}>
                                {item.category_name}
                                {publicationCategoryData.length > index + 1
                                  ? " / "
                                  : ""}
                              </span>
                            ))}
                            {/* <div className="publication-date">
                          <i className="">
                            <img src={iconTime} className="svg" alt="" />
                          </i>
                          Posted On : {moment(publicationData.publicaton_publication_date).format("DD/MM/YYYY")}
                        </div> */}
                            {/* <div className="post-type ms-auto">Posted by: <span className="highlight-text">{publicationData.user_name}</span></div> */}
                          </div>
                        </div>
                        <div className="detail-content-mdl ">
                          <p className="common-paragraph">
                            {publicationData.publicaton_about}
                          </p>
                        </div>
                        {publicationRefData && <div className="publication-reference-list">
                          <h4>Referencess</h4>
                          <ul>
                            {publicationRefData &&
                              publicationRefData.map((item, key) => (
                                <a 
                                href={formatUrlLink(item.ref_link)}
                                 target="_blank" rel="noreferrer">
                                  <li key={key}>{item.ref_title}</li>
                                </a>
                              ))}
                          </ul></div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default PublicationsDetails;
