import React, { useEffect, useState, useContext } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import EmployeeCard from "../../../../componants/EmployeeCard/EmployeeCard";
import iconSearch from "./../../../../asset/svg/icon-search.svg";
import HomeSearch from "../../Landing/HomeSearch";
import { apiRequest } from "../../../../Helpers/General";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
import { useInView } from "react-intersection-observer";

const LawyerDirectory = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const [totalRecords, setTotalRecords] = useState(0);
  // eslint-disable-next-line
  const [ref, inView] = useInView({
    threshold: 0,
  });
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const { genralMasterData } = useContext(MasterContext);
  let lawyerDirectoryLabel = genralMasterData.LAWYER_DIRECTORY;
  const [lowyerDirectoryData, setLowyerDirectoryData] = useState([]);
  const [lowyerDirectoryMetaData, setLowyerDirectoryMetaData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [searchParam, setSearchParam] = useState(localStorage.getItem("searchParam") ? JSON.parse(localStorage.getItem("searchParam")) : "");
  const [filterData, setFilterData] = useState([]);
  const [pageIndex, setpageIndex] = useState("1");
  const [nextPage, setNextPage] = useState(0);
  useEffect(() => {
    const GetData = async () => {
      try {
        if (searchParam.pageIndex) setpageIndex(searchParam.pageIndex);
        const apiSetting = {
          method: "GET",
          apiParams: {
            country_id: searchParam && searchParam.country && searchParam.country[0] ? searchParam.country[0].country_id : "102",
            user_type: "Lawyer",
            state_id: searchParam && searchParam.statecity && searchParam.statecity[0] ? searchParam.statecity[0].state_id : "",
            city_id: searchParam && searchParam.statecity && searchParam.statecity[0] ? searchParam.statecity[0].city_id : "",
            court_id: searchParam && searchParam.Court && searchParam.Court[0] ? searchParam.Court[0].court_master_id : "",
            expertise_id: searchParam && searchParam.Area && searchParam.Area[0] ? searchParam.Area[0].expertise_master_id : "",
            user_name: searchParam && searchParam.Name ? searchParam.Name : "",
            page_index: searchParam.pageIndex ? searchParam.pageIndex : pageIndex,
          },
        };
        delete searchParam.pageIndex;
        const apiResult = await apiRequest("get_lawyer_directory_listing", apiSetting);

        if (apiResult.data.settings.success === "1") {
          let data = apiResult.data.data.return_data;
          setTotalRecords(apiResult.data.settings.count);
          setNextPage(parseInt(apiResult.data.settings.next_page));
          if (props.userProfile) {
            let index = "";
            // eslint-disable-next-line
            data.map((item, inde) => {
              if (item.user_master_id === props.userProfile.user_master_id) {
                index = inde;
              }
            });
            if (index !== "" && index > -1) {
              data.splice(index, 1);
              setTotalRecords(apiResult.data.settings.count - 1);
            }
          }
          if (pageIndex > 1) {
            lowyerDirectoryData.push(...data);
          } else {
            setLowyerDirectoryData(data);
            setLowyerDirectoryMetaData(apiResult.data.data.lawyer_gc_meta_data[0]);

            setFilterData(data);

            const savedSearchParams = JSON.parse(localStorage.getItem("searchParam"));
            if (savedSearchParams) {
              delete savedSearchParams.pageIndex;
              setSearchParam(savedSearchParams);
            } else {
              setSearchParam("");
            }

            /* setSearchParam(
            localStorage.getItem("searchParam")
              ? JSON.parse(localStorage.getItem("searchParam"))
              : ""
          ); */
          }
        } else {
          setTotalRecords(0);
          setNextPage(0);
          setLowyerDirectoryData([]);
          setLowyerDirectoryMetaData(apiResult.data.data ? apiResult.data.data[0] : []);
          setFilterData([]);
          const savedSearchParams = JSON.parse(localStorage.getItem("searchParam"));
          if (savedSearchParams) {
            delete savedSearchParams.pageIndex;
            setSearchParam(savedSearchParams);
          } else {
            setSearchParam("");
          }
          /* setSearchParam(
            localStorage.getItem("searchParam")
              ? JSON.parse(localStorage.getItem("searchParam"))
              : ""
          ); */
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        //localStorage.removeItem("searchParam");
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
    // eslint-disable-next-line
  }, [isPageUpdating, searchParam, pageIndex]);

  useEffect(() => {
    if (inView) {
      setIsPageUpdating(true);
      //((parseInt(pageIndex) + 1).toString())
    }
    // eslint-disable-next-line
  }, [inView]);
  const OnChange = (e) => {
    setSearchParam(e);
    setIsPageUpdating(true);
  };
  const onSearch = (e) => {
    let Text = e.target.value;
    if (Text) {
      const filtered = lowyerDirectoryData.filter((row) => {
        return (
          (row.expertise && row.expertise[0].user_expertise_name.toLowerCase().includes(Text.toLowerCase())) ||
          (row.court_data && row.court_data[0].user_court_name.toLowerCase().includes(Text.toLowerCase())) ||
          (row.user_full_name && row.user_full_name.toLowerCase().includes(Text.toLowerCase())) ||
          (row.emp_membership && row.emp_membership.toLowerCase().includes(Text.toLowerCase())) ||
          (row.user_address1 && row.user_address1.toLowerCase().includes(Text.toLowerCase())) ||
          (row.user_experience && row.user_experience.toLowerCase().includes(Text.toLowerCase()))
        );
      });
      setFilterData(filtered);
    } else {
      setFilterData(lowyerDirectoryData);
    }
  };

  const meta = {
    title: lowyerDirectoryMetaData && lowyerDirectoryMetaData.meta_title,
    description: lowyerDirectoryMetaData && lowyerDirectoryMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: lowyerDirectoryMetaData && lowyerDirectoryMetaData.meta_key_words,
      },
    },
  };
  // eslint-disable-next-line
  //let loadMore = Math.ceil(totalRecords / 20);
  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page ldirectory-page directory-listing-page">
          <div className="container">
            <div className="row">
              <div className="inner-head d-flex align-item-center">
                <div className="inner-head-left">
                  <div className="page-title  text-start">
                    <h2>{lawyerDirectoryLabel && lawyerDirectoryLabel.gm_heading ? lawyerDirectoryLabel.gm_heading : "Lawyer Directory"}</h2>
                  </div>
                </div>
                <div className="inner-header-rgt ms-auto d-flex align-items center">
                  <div className="search-field with-filter">
                    <div className="form-row">
                      <div className="form-input-div">
                        <input type="text" placeholder="Search" className="form-control" onChange={onSearch} />
                        <i className="search-icon">
                          <img src={iconSearch} className="svg" alt="" />
                        </i>
                      </div>
                    </div>
                    <button className="filter-toggler" onClick={toggle}>
                      filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 order-1 directory-search ">
                <div className={isOpen ? "right-search-block search-hide" : "right-search-block search-show"}>
                  <div className="right-block-title d-flex flex-column">
                    <h2 className="mb-5">
                      Filter <span>Results</span>
                    </h2>
                  </div>

                  <HomeSearch page="Lowyer" searchParam={searchParam} OnChange={OnChange} />
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 directory-content">
                <div className="common-page-content publication-content">
                  <div className="event-lst-box d-flex justify-content-between flex-column">
                    {filterData.length > 0 ? (
                      filterData.map((row, key) => <EmployeeCard page="Lowyer" row={row} key={key} {...props} />)
                    ) : (
                      <NoRecord pageName={props.componentInfo.title} />
                    )}
                  </div>
                  <div>
                    <div className="footer-action">
                      <h3>
                        {" "}
                        Count :{" "}
                        <span>
                          {filterData.length} / {totalRecords}
                        </span>
                      </h3>

                      {nextPage > 0 && (
                        <button
                          // ref={ref}
                          className="btn-primary"
                          onClick={() => {
                            setIsPageUpdating(true);
                            setpageIndex((parseInt(pageIndex) + 1).toString());
                          }}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default LawyerDirectory;
