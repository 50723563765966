import "./EmployeeCard.css";
import { Link } from "react-router-dom";
import iconPhone from "./../../asset/svg/icon-phone.svg";
import { formatUrlLink } from "../../Helpers/General";
import moment from "moment";
import Modal from "../Modal/Modal";
import SendEmail from "../../componants/sendEmail/sendEmail";
import swal from "sweetalert";
import Avatar from "../Avatar/Avatar";
import "react-medium-image-zoom/dist/styles.css";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import iconBadgeSmall from "./../../asset/images/badge-small-01.svg";
import { MasterContext } from "../../Context";
import React, { useContext } from "react";

/* import iconFb from ""
import iconTwit from "" 
import iconLinkn from "" 
import iconYtube from "" */

const EmployeeCardDetail = (props) => {
  const numArr =
    props.row &&
    props.row.user_whats_up_no &&
    props.row.user_whats_up_no.match(/\d/g);
  const whatsupno = numArr && numArr.join("");
  const { genralMasterData } = useContext(MasterContext);
  let url = genralMasterData.rank_upgrade_type === 'STEP_WISE' ? "/UpgradeRanking/" : "/Upgraderankingrandom/";


  return (
    <>
      <div className="card-itm emp-card-itm">
        <div className="card">
          <div className="card-left">
            <div className="emp-pic">
              <Avatar
                id={props.id}
                fullName={
                  props.row.user_first_name + " " + props.row.user_last_name
                }
                pictureUrl={
                  props.row.user_profile_pic ? props.row.user_profile_pic : ""
                }
                alt={
                  props.row.alt_for_profile_pic
                    ? props.row.alt_for_profile_pic
                    : ""
                }
                size="120px"
                fontSize="30px"
              ></Avatar>
              {/*  <img
                src={
                  props.row.user_profile_pic ||
                  `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                }
                alt=""
              /> */}
            </div>

            {props.row.user_user_type === "Lawyer" && (
              <div className="emp-exp">
                <div className="d-flex flex-column text-center mt-2">
                  <label>Experience</label>
                  <span>
                    {props.row.user_experience
                      ? props.row.user_experience + " Years"
                      : "--"}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="card-mdl">
            <div className="card-body">
              <h1 className="card-title">
                {(props.row.user_first_name
                  ? props.row.user_first_name
                  : "--") +
                  " " +
                  (props.row.user_last_name ? props.row.user_last_name : "--")}
              </h1>
              <ul>
              {props.row.user_user_type === "Lawyer" && props.row.user_experience && (
              <div className="emp-exp mobile-view">
                <div className="d-flex flex-column mt-2">
                  <label>Experience</label>
                  <span>
                    {props.row.user_experience
                      ? props.row.user_experience + " Years"
                      : "--"}
                  </span>
                </div>
              </div>
            )}

                {props.row.user_user_type === "Lawyer" && (
                  <li className="expertise-list">
                    <label>Areas Of Expertise</label>
                    {/* <span className="d-flex">
                      {
                        // props.page === "Lowyer" &&
                        props.row.expertise_ranking &&
                        props.row.expertise_ranking.map((item, key) => {
                          let expertieSName = props.row.expertise.filter(
                            (row) =>
                              row.user_expertise_master_id ===
                              item.expertise_master_id
                          );
                          return (
                            <>
                              {expertieSName &&
                                expertieSName[0].expertise_name
                                ? expertieSName[0].expertise_name
                                : ""}
                              {props.row.expertise_ranking.length > key + 1 ? ", " : ""}
                            </>
                          );
                        })
                      }
                    </span> */}
                    <div className="ranking-badge d-flex flex-column">
                      {props.row.expertise && props.row.expertise.length > 0
                        ? props.row.expertise.map((item, indx) => {
                          return (
                            <div
                              key={indx}
                              className={
                                "ranking-badge-lst d-flex flex-row align-items-center ranking-color" +
                                (props.row.expertise_ranking &&
                                  props.row.expertise_ranking[
                                  item.user_expertise_master_id
                                  ]
                                  ? "-" +
                                  props.row.expertise_ranking[
                                    item.user_expertise_master_id
                                  ].rank_level
                                  : "")
                              }
                            >

                              <div className="icon-badge me-3">
                                <img
                                  src={iconBadgeSmall}
                                  className=""
                                  alt=""
                                />
                              </div>

                              <div className="content-badge d-flex flex-row">
                                <span className="badge-name">
                                  < >
                                    {item.user_expertise_name}
                                  </>

                                  {props.row.expertise_ranking &&
                                    props.row.expertise_ranking[
                                    item.user_expertise_master_id
                                    ] ? (
                                    <>
                                      &nbsp;({" "}
                                      {
                                        props.row.expertise_ranking[
                                          item.user_expertise_master_id
                                        ].user_rank_name
                                      }
                                      )
                                    </>
                                  ) : (
                                    " "// <span className="badge-type">Novice</span>
                                  )} </span>
                              </div>
                            </div>
                          );
                        })
                        : "--"}
                    </div>
                  </li>
                )}

                {props.row.user_user_type === "Lawyer" && (
                  <li className="mt-2 practicing-courts">
                    <label>Practicing Courts</label>
                    <span className="d-flex">
                      {props.row.court_data ?
                        props.row.court_data.map((item, key) => (
                          <React.Fragment key={key}>{item.user_court_name}  {props.row.court_data.length > key + 1 ? ", " : ""} </React.Fragment>
                        )) : '--'}
                    </span>
                  </li>
                )}

                <li className="d-flex flex-row gc-other-detail">
                  {props.row.user_user_type !== "Lawyer"  && (
                    <div className="emp-exp  me-5">
                      <div className="d-flex flex-column text-start mt-2">
                        <label>Experience</label>
                        <span>
                          {props.row.user_experience
                            ? props.row.user_experience + " Years"
                            : "--"}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="emp-exp">
                    <div className="d-flex flex-column text-start mt-2">
                      <label>Enrolment</label>
                      <span>
                        {props.row.user_year_of_enrollment
                          ? props.row.user_year_of_enrollment + ""
                          : "--"}{" "}
                        &nbsp; - &nbsp;
                        {props.row.user_bar_council
                          ? props.row.user_bar_council + ""
                          : "--"}
                      </span>
                    </div>
                  </div> 

                </li>
                
                <li className="emp-exp ">
                  <div className="d-flex flex-column text-start mt-2">
                    <label>Languages Known </label>
                    <span>
                      {props.row.lang_data ?
                        props.row.lang_data.map((item, key) => (
                          <React.Fragment key={key}>{item.vLanguageName} {props.row.lang_data.length >
                            key + 1
                            ? ", "
                            : ""} </React.Fragment>
                        )) : "--" }
                    </span>
                  </div>
                </li>
                
              </ul>
            </div>
          </div>

          <div className="card-right directory-ranking">
            <div className="card-action mb-3">
              <div className="lawyer-avilibility">
                {
                  // props.page !== "Gc" &&
                  (props.userProfile) &&
                  props.row.from_time !== "" &&
                  props.row.from_time !== "00:00:00" &&
                  props.row.to_time !== "00:00:00" && (
                    <div className="cmn-list">
                      <label>preferred time to call</label>
                      <span>
                        {props.row.from_time
                          ? moment(props.row.from_time, "hh:mm:ss").format(
                            "h:mm a"
                          )
                          : "- -"}
                        {props.row.from_time && " To "}
                        {props.row.to_time
                          ? moment(props.row.to_time, "hh:mm:ss").format(
                            "h:mm a"
                          )
                          : "- -"}
                      </span>
                    </div>
                  )
                }
              </div>
              {/*   {props.page !== "Gc" && ( */}
              {props.page !== "Gc" && ( props.row.user_user_type === "Lawyer" || props.userProfile) ? (
                <span className="btn-green">
                  <a
                    href={"tel:" + props.row.user_mobile_no}
                    className="btn-green contact-link"
                    rel="noreferrer"
                  >
                    <img src={iconPhone} className="svg" alt="" />
                    {formatPhoneNumberIntl(props.row.user_mobile_no) ||
                      props.row.user_mobile_no}
                  </a>{" "}
                </span>
              ) : (
                ""
              )}

              {/* {(props.page==="Lowyer"||props.userProfile)&&props.row.user_whats_up_no && (
    <a
      href={
        "https://wa.me/" +
        whatsupno +
        "?text=I%20came%20from%20Xperts%20Legal%20Websiter"
      }
      target="_blank"
      className="btn-whatsup"
    >
      <img src={iconWhatsup} className="svg" alt="" />
    </a>
  )} */}
         

              {props.row.user_user_type !== "Lawyer" && props.row.user_user_type !== "Gc" &&
                <div className="social-links d-flex flex-row">
                  {props.componentInfo.title !== "My Profile" &&
                    (props.page === "Lowyer" || props.userProfile) ? (
                    <Modal
                      btnCoverClass="social-lst"
                      title="Email to the Lawyer"
                      btnTitle=""
                      btnClass="email-icon"
                      id="AddLogoChildForm"
                      child={SendEmail}
                      data={props.row}
                    // onChange={onChangeProfile}
                    />
                  ) : (
                    ""
                  )}
                  {(props.page === "Lowyer" || props.userProfile) && (props.page === "Gc" || props.userProfile) &&
                    props.componentInfo.title !== "My Profile" && (
                      <a
                        href={
                          "https://wa.me/" +
                          whatsupno +
                          "?text=I%20came%20from%20Xperts%20Legal%20Website"
                        }
                        rel="noreferrer"
                        target="_blank"
                        className={
                          whatsupno === ""
                            ? "social-lst btn-disabled"
                            : "social-lst"
                        }
                      >
                        <i className="social-wapp-icon"></i>
                        {props.row.whatsupno}
                      </a>
                    )}

                  {props.row.user_user_type === "Lawyer" &&
                    props.row.user_face_book_profile_link && (
                      <a
                        href={formatUrlLink(props.row.user_face_book_profile_link)}

                        target="blank"
                        rel="noreferrer noopener"
                        className="social-lst social-fb"
                      >
                        <i className="social-fb-icon"></i>
                      </a>
                    )}
                  {props.row.user_user_type === "Lawyer" &&
                    props.row.user_twitter_profile_link && (
                      <a
                      href={formatUrlLink(props.row.user_twitter_profile_link)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="social-lst social-tt"
                      >
                        <i className="social-tt-icon"></i>
                      </a>
                    )}
                  {
                    props.row.user_linked_in_link && (
                      <a
                      href={formatUrlLink(props.row.user_linked_in_link)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="social-lst social-link"
                      >
                        <i className="social-link-icon"></i>
                      </a>
                    )}
                  {props.row.user_user_type === "Lawyer" &&
                    props.row.user_you_tube_link && (
                      <a
                      href={formatUrlLink(props.row.user_you_tube_link)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="social-lst social-yt"
                      >
                        <i className="social-yt-icon"></i>
                      </a>
                    )}
                      {props.componentInfo.title === "My Profile" &&
                props.userProfile &&
                props.row.user_package_name === "Platinum Plan" ? (
                props.row.expertise && props.row.expertise.length > 0 ? (
                  <Link
                    to={
                      url +
                      (props.userProfile && props.userProfile.user_master_id)
                    }
                    className="btn-border btn-view btn-upgrade-ranking"
                  >
                    Upgrade Ranking
                  </Link>
                ) : (
                  <button
                    onClick={() => {
                      swal({
                        title: "Error",
                        text: "Please add areas of expertise",
                        icon: "warning",
                        button: {
                          text: "Close",
                          closeModal: true,
                        },
                        dangerMode: true,
                      });
                    }}
                    className="btn-border btn-view btn-upgrade-ranking"
                  >
                    Upgrade Ranking
                  </button>
                )
              ) : (
                ""
              )}
                </div>
               
              }
            </div>
            {props.row.user_user_type !== "Gc" &&
              <div className="social-links d-flex flex-row">
                {props.componentInfo.title !== "My Profile" &&
                  (props.page === "Lowyer" || props.userProfile) ? (
                  <Modal
                    btnCoverClass="social-lst"
                    title="Email to the Lawyer"
                    btnTitle=""
                    btnClass="email-icon"
                    id="AddLogoChildForm"
                    child={SendEmail}
                    data={props.row}
                  // onChange={onChangeProfile}
                  />
                ) : (
                  ""
                )}
                {(props.page === "Lowyer" || props.userProfile) &&
                  props.componentInfo.title !== "My Profile" && (
                    <a
                      href={
                        "https://wa.me/" +
                        whatsupno +
                        "?text=I%20came%20from%20Xperts%20Legal%20Website"
                      }
                      rel="noreferrer"
                      target="_blank"
                      className={
                        whatsupno === ""
                          ? "social-lst btn-disabled"
                          : "social-lst"
                      }
                    >
                      <i className="social-wapp-icon"></i>
                      {props.row.whatsupno}
                    </a>
                  )}

                {props.row.user_user_type === "Lawyer" &&
                  props.row.user_face_book_profile_link && (
                    <a
                      href={formatUrlLink(props.row.user_face_book_profile_link)}
                      target="blank"
                      rel="noreferrer noopener"
                      className="social-lst social-fb"
                    >
                      <i className="social-fb-icon"></i>
                    </a>
                  )}
                {props.row.user_user_type === "Lawyer" &&
                  props.row.user_twitter_profile_link && (
                    <a
                      href={formatUrlLink(props.row.user_twitter_profile_link)}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="social-lst social-tt"
                    >
                      <i className="social-tt-icon"></i>
                    </a>
                  )}
                {
                  props.row.user_linked_in_link && (
                    <a
                      href={formatUrlLink(props.row.user_linked_in_link)}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="social-lst social-link"
                    >
                      <i className="social-link-icon"></i>
                    </a>
                  )}
                {props.row.user_user_type === "Lawyer" &&
                  props.row.user_you_tube_link && (
                    <a
                      href={formatUrlLink(props.row.user_you_tube_link)}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="social-lst social-yt"
                    >
                      <i className="social-yt-icon"></i>
                    </a>
                  )}
                       {props.componentInfo.title === "My Profile" &&
                props.userProfile &&
                props.row.user_package_name === "Platinum Plan" ? (
                props.row.expertise && props.row.expertise.length > 0 ? (
                  <Link
                    to={
                      url +
                      (props.userProfile && props.userProfile.user_master_id)
                    }
                    className="btn-border btn-view btn-upgrade-ranking ml-2"
                  >
                    Upgrade Ranking
                  </Link>
                ) : (
                  <button
                    onClick={() => {
                      swal({
                        title: "Error",
                        text: "Please add areas of expertise",
                        icon: "warning",
                        button: {
                          text: "Close",
                          closeModal: true,
                        },
                        dangerMode: true,
                      });
                    }}
                    className="btn-border btn-view btn-upgrade-ranking"
                  >
                    Upgrade Ranking
                  </button>
                )
              ) : (
                ""
              )}
              </div>
            }

            <div className="organigation-block">
              <div className="emp-award d-flex flex-row">
                <div className="emp-award img">
                  <img
                    src={
                      props.row.user_organization_logo ||
                      `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                    }
                    alt={
                      props.row.alt_for_org_image
                        ? props.row.alt_for_org_image
                        : ""
                    }
                  />
                </div>
                {/* <div className="card-body">
                      <h5 className="card-title">
                        {props.row.user_organizatin_name
                          ? props.row.user_organizatin_name
                          : "--"}
                      </h5>
                    </div> */}
              </div>
              <div className="organization-address d-flex flex-column">
                <label className="card-title-address">
                  {props.row.user_organizatin_name
                    ? props.row.user_organizatin_name
                    : ""}
                </label>
                <span>
                  {props.row.userFullAddress1 ? props.row.userFullAddress1 : "--"}
                  &nbsp;,&nbsp;
                  {props.row.userFullAddress2 ? props.row.userFullAddress2 : ""}
                </span>
              </div>
            </div>

            {/* <div className="emp-award d-flex flex-row " >
              {
                // props.page === "Lowyer" &&
                props.row.expertise_ranking &&
                props.row.expertise_ranking.map((item, key) => {

                  let expertieSName = props.row.expertise.filter((row) => row.user_expertise_master_id === item.expertise_master_id)
                  return (
                    <div
                      className={"emp_ranking ranking-color-" + item.rank_level}
                      key={key}
                    >  <Zoom>

                        <img
                          className="svg"
                          src={
                            item.rank_image ||
                            `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                          }
                          alt=""
                        />
                        <h4>{expertieSName && expertieSName[0].expertise_name ? expertieSName[0].expertise_name : ""}</h4>
                      </Zoom>
                    </div>
                  )
                })}
            </div> */}
          </div>
        </div>


      </div>
    </>
  );
};

export default EmployeeCardDetail;
