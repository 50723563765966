import React, { useContext, useEffect, useRef } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useState } from "react";
import SelectBox from "../../../componants/SelectBox/SelectBox";
import { useHistory } from "react-router";
import { MasterContext } from "../../../Context/index";
import { apiRequest } from "../../../Helpers/General";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import swal from "sweetalert";
import ReactToast from "../../../componants/Toast/ReactToast";

const GcSignupForm = (props) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();
  const stateRef = useRef(null);
  const cityRef = useRef(null);
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {

    setCurRouteDetails(props.componentInfo);
    if (props.userProfile) {
      history.push("/myprofile");
    }
  })
  const { countrydata, } = useContext(MasterContext);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [countryCode, setCountryCode] = useState("IN");
  const [whatsupCountryCode, setwhatsupCountryCode] = useState("IN");
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("mdi mdi-eye-off");
  const [confirmpasswordShown, setconfirmpasswordShown] = useState(false);
  const [eyeClassCpass, seteyeClassCpass] = useState("mdi mdi-eye-off");
  const [stateOption, setStateoption] = useState([]);
  const [cityOption, setcityOption] = useState([]);
  // eslint-disable-next-line
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  // eslint-disable-next-line
  const [userNameErr, setUserNameErr] = useState();
  const [emailErr, setEmailErr] = useState();
  const [loading, setLoading] = useState(false);
  const [termAccepted, setTermAccepted] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEyeClass(passwordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger");
  };

  const toggleconformPasswordVisiblity = () => {
    setconfirmpasswordShown(confirmpasswordShown ? false : true);
    seteyeClassCpass(
      confirmpasswordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger"
    );
  };
  const onchangeConfirmpassword = (e) => {
    let pass = getValues("password");
    if (pass === e.target.value) {
      clearErrors("confirm_password");
    } else {
      setError("confirm_password", {
        type: "invalid",
        message: "Confirm password not match.",
      });
    }
  };
  const changeCountry = async (e) => {
    setValue("country", e)
    clearErrors("country");
    setSelectedCountry(e);
    if (selectedState.length > 0) {
      stateRef.current.clear();
      setSelectedState([]);
    }
    if (selectedCity.length > 0) {
      cityRef.current.clear();
      setSelectedCity([]);
    }
    if (e[0] && e[0].country_id) {
      const apiSetting = {
        method: "GET",
        apiParams: { country_id: e[0].country_id },
      };
      const apiResult = await apiRequest("get_state_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setStateoption(apiResult.data.data);
      }
    } else {
      setStateoption([]);
    }
  };
  const changeState = async (e) => {
    setSelectedState(e)
    setValue("state", e)
    clearErrors("state");
    if (selectedCity.length > 0) {
      cityRef.current.clear();
      setSelectedCity([]);
    }
    if (e[0] && e[0].state_id) {
      const apiSetting = {
        method: "GET",
        apiParams: { state_id: e[0].state_id },
      };
      const apiResult = await apiRequest("get_city_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setcityOption(apiResult.data.data);
      } else {
        setcityOption([]);
      }
    } else {
      setcityOption([]);
    }
  };
  const changeMobile = (value) => {
    if (value) {
      if (isValidPhoneNumber(value) === true) {
        setValue("mobile", value);
        clearErrors("mobile");
      } else {
        setError("mobile", {
          type: "invalid",
          message: "Invalid Mobile number",
        });
      }
    } else {
      setError("mobile", {
        type: "required",
        message: "Mobile number is mandatory",
      });
    }
  };

  const changeMobileWhatsup = (value) => {
    if (value) {
      if (isValidPhoneNumber(value) === true) {
        setValue("whatsup", value);
        clearErrors("whatsup");
      } else {
        setError("whatsup", {
          type: "invalid",
          message: "Invalid Whatsapp number.",
        });
      }
    } /*else {
      setError("whatsup", {
        type: "required",
        message: "Whatsapp number is mandatory.",
      });
    }*/
  };
  const onSubmit = async (data) => {
    setLoading(true);
    if (!email) {
      ReactToast({ type: "error", message: "Email is required" });
      return false;
    }

    if (emailErr) {
      ReactToast({ type: "error", message: emailErr });
      return false;
    }

    let parms = {
      first_name: data.firstname,
      last_name: data.lastname,
      email_id: email,
      username: email,
      password: data.password,
      confirm_password: data.confirm_password,
      phone_code: countryCode,
      mobile_no: data.mobile,
      address1: data.address1,
      address2: data.address2,
      country_id: selectedCountry && selectedCountry[0].country_id,
      state_id: selectedState && selectedState[0].state_id,
      city_id: selectedCity && selectedCity[0] ? selectedCity[0].city_id : "",
      zip_code: data.zip_code,
      gstn_no: data.gst_no,
      whats_up_no_country_phone_code: whatsupCountryCode,
      whats_up_no: data.whatsup

    }
    try {
      const apiSetting = {
        method: "POST",
        apiParams: parms,
      };
      const apiResult = await apiRequest("user_signup_gc", apiSetting);
      if (apiResult.data.settings.success === "1") {
        swal({
          title: "Success",
          text: "Registered Successfully.",
          icon: "success",
          button: {
            text: "Done",
            closeModal: true,
          },
          dangerMode: false,
        });
        history.push("/login")
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onCountryChange = (e) => {
    setCountryCode(e)
  }
  const onWhatsupCountryChange = (e) => {
    setwhatsupCountryCode(e)
  }
  const checkEmail = async (e) => {
    setValue("email", e.target.value)
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
      try {
        const apiSetting = {
          method: "POST",
          apiParams: { email_id: e.target.value },
        };

        setEmail(e.target.value)

        const apiResult = await apiRequest("validate_email_and_username_exists", apiSetting);
        if (apiResult.data.settings.success === "1") {
          clearErrors("email");

          setEmailErr()
        } else {
          setEmail(e.target.value)
          setValue("email", e.target.value)
          setError("email", {
            type: "invalid",
            message: "Email Already Exists",
          })
          setEmailErr("Email Already Exists")
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    } else {
      setError("email", {
        type: "invalid",
        message: "Enter a valid Email",
      })
    }
  };
  // eslint-disable-next-line
  const checkUserName = async (e) => {
    setValue("username", e.target.value)
    if ((e.target.value).length > 5) {
      try {
        const apiSetting = {
          method: "POST",
          apiParams: { username: e.target.value },
        };
        setUserName(e.target.value)
        const apiResult = await apiRequest("validate_email_and_username_exists", apiSetting);
        if (apiResult.data.settings.success === "1") {
          clearErrors("username");
          setUserNameErr()

        } else {
          setUserName(e.target.value)
          setUserNameErr("Username Already Exists")
          setError("username", {
            type: "invalid",
            message: "Username Already Exists",
          })
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    } else {
      setUserName(e.target.value)
      setError("username", {
        type: "invalid",
        message: "Minimum 6 characters",
      })

    }
  };
  return (
    <div className="inner-page signup-page">
      <div className="container">
        <div className="row">
          <form id="documents-add-hook-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="offset-lg-2 col-lg-8  offset-md-2 col-md-8 col-sm-12 col-xs-12">
              <div className="inner-title">
                <h3>Personal Information</h3>
              </div>
              <div className="form-section">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        First Name<em>*</em>
                      </label>
                      <div className="form-input position-relative">
                        <input
                          type="text"
                          id="inputFirstName"
                          className="form-control"
                          placeholder="FirstName"
                          {...register("firstname", {
                            required: "First Name is required",
                            maxLength: {
                              value: 30,
                              message: "First Name must be less than 30 char ",
                            },
                          })}
                        />
                        <div className="input-err">{errors?.firstname?.message}</div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Last Name<em>*</em>
                      </label>
                      <div className="form-input position-relative">
                        <input
                          type="text"
                          id="inputLastName"
                          className="form-control"
                          placeholder="LastName"
                          {...register("lastname", {
                            required: "Last Name is required",
                            maxLength: {
                              value: 30,
                              message: " Last Name must be less than 30 char ",
                            },
                          })}
                        />
                        <div className="input-err">{errors?.lastname?.message}</div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Email Address<em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type="email"
                          id="inputEmail"
                          className="form-control"
                          placeholder="Email address"
                          onChange={checkEmail}
                          rules={{ ...register("email", { required: "Email Address is required" }) }}
                        />
                        <div className="input-err">{errors?.email?.message}</div>

                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Password<em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type={passwordShown ? "text" : "password"}
                          id="inputPassword"
                          className="form-control"
                          placeholder="Password"
                          {...register("password", {
                            required: "password required",
                            minLength: {
                              value: 6,
                              message: "Minimum 6 characters",
                            },
                          })}
                        />
                        <i
                          onClick={togglePasswordVisiblity}
                          className={eyeClass + " mdi password-eye"}
                        ></i>
                        <div className="input-err">
                          {/* errors will return when field validation fails  */}
                          {errors?.password?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputConfirmpassword"
                        className="float-start mb-1 d-flex"
                      >
                        Confirm Password<em>*</em>
                      </label>
                      <div className="form-input-div">
                        <input
                          type={confirmpasswordShown ? "text" : "password"}
                          id="inputConfirmPassword"
                          className="form-control"
                          placeholder="Password"
                          {...register("confirm_password", {
                            required: "Confirm Password is required",
                          })}
                          onChange={onchangeConfirmpassword}
                        />
                        <i
                          onClick={toggleconformPasswordVisiblity}
                          className={eyeClassCpass + " mdi password-eye"}
                        ></i>
                        <div className="input-err">
                          {/* errors will return when field validation fails  */}
                          {errors?.confirm_password?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label htmlFor="" className="float-start mb-1 d-flex">
                        Phone Number<em>*</em>
                      </label>
                      <div className="form-input-div form-input position-relative">
                        <div className="input-group">
                          <PhoneInput
                            id="mobile"
                            placeholder="Phone Number"
                            defaultCountry="IN"
                            onChange={changeMobile}
                            rules={{
                              ...register("mobile", {
                                required: "Phone Number is required",
                              }),
                            }}
                            onCountryChange={onCountryChange}
                          />
                        </div>
                        <div className="input-err">{errors?.mobile?.message}</div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Whatsapp Number
                      </label>
                      <div className="form-input-div form-input position-relative">
                        <div className="input-group">
                          <PhoneInput
                            id="whatsupnumber"
                            placeholder="Mobile Number"
                            defaultCountry="IN"
                            onChange={changeMobileWhatsup}
                            rules={{
                              ...register("whatsup", {
                               /* required: " Whatsapp Number is required.",*/
                              }),
                            }}
                            onCountryChange={onWhatsupCountryChange}
                          />
                        </div>
                        <div className="input-err">{errors?.whatsup?.message}</div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Address1<em>*</em>
                      </label>
                      <div className="form-input position-relative">
                        <input
                          type="text"
                          id="inputAddress1"
                          className="form-control"
                          placeholder="Address1"
                          {...register("address1", {
                            required: "Address1 is required",
                            maxLength: {
                              value: 100,
                              message: "Address1 must be less than 100 char ",
                            },
                          })}
                        />
                        <div className="input-err">    {errors?.address1?.message}</div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Address2
                      </label>
                      <div className="form-input position-relative">
                        <input
                          type="text"
                          id="inputAddress2"
                          className="form-control"
                          placeholder="Address2"
                          {...register("address2", {

                            maxLength: {
                              value: 100,
                              message: " Address2 must be less than 100 char ",
                            },
                          })}
                        />
                        <div className="input-err"> {errors?.address2?.message}</div>

                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Country<em>*</em>
                      </label>
                      <div className="form-input position-relative">
                        <SelectBox
                          className="mdi mdi-chevron-down"
                          name="country"
                          labelKey="country"
                          valueKey="country_id"
                          defaultSelected={selectedCountry}
                          defaultValue={selectedCountry}
                          options={countrydata}
                          OnChange={changeCountry}
                          placeholder="Select value from dropdown"
                          {...register("country", {
                            required: "Counrty is required",
                          })}
                        />
                        <div className="input-err">{errors?.country?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        State<em>*</em>
                      </label>
                      <div className="form-input position-relative">
                        <SelectBox
                          className="mdi mdi-chevron-down"
                          name="state"
                          labelKey="state"
                          valueKey="state_id"
                          defaultSelected={selectedState}
                          // defaultValue={selectedState}
                          options={stateOption}
                          OnChange={changeState}
                          placeholder="Select value from dropdown"
                          {...register("state", {
                            required: " State is required",
                          })}
                          ref={stateRef}
                        />
                        <div className="input-err">{errors?.state?.message}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        City
                      </label>
                      <div className="form-input position-relative">
                        <SelectBox
                          className="mdi mdi-chevron-down"
                          name="city"
                          labelKey="city"
                          valueKey="city_id"
                          defaultSelected={selectedCity}
                          defaultValue={selectedCity}
                          options={cityOption}
                          OnChange={(e) => setSelectedCity(e)}
                          placeholder="Select value from dropdown"
                          {...register("city",)}
                          ref={cityRef}
                        />

                        <div className="input-err"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-row custom-dropdown">
                      <label
                        htmlFor="inputZipCode"
                        className="float-start mb-1 d-flex"
                      >
                        Zip Code
                      </label>
                      <div className="form-input position-relative">
                        <input
                          type="text"
                          id="inputZipCode"
                          className="form-control"
                          placeholder="Zip Code"
                          {...register("zip_code")}
                        />
                        <div className="input-err"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <div className="checkbox">
                        <label className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="termAccepted"
                            className="me-2"
                            onClick={(e) => {
                              const tmp = e.target.checked;
                              setTermAccepted(tmp);
                            }}
                          /* {...register("termAccepted")} */
                          />
                          By clicking "Checkbox", you agree to the <Link to="/TermsCondition" target="_blank" className="ms-2 terms-link">Terms</Link> &nbsp; & <Link to="/PrivacyPolicy" target="_blank" className="ms-2 terms-link">Privacy Policy</Link>
                        </label>
                        <div className="input-err">{errors?.acceptTerms?.message}</div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row"></div>
                <div className="row"></div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-row mt-4 text-center">
                  {loading ? <button
                    form="contact-person-add-hook-form"
                    className="w-20 btn btn-primary m-auto">
                    <div className="spinner-border text-white  text-center" role="status">
                      <span className="sr-only">Loading...</span>
                    </div> </button> :
                    termAccepted ?
                      <button className="btn-primary m-auto" type="submit">
                        Submit
                      </button>
                      :
                      <button disabled className="btn-primary m-auto" type="submit">
                        Submit
                      </button>
                  }
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GcSignupForm;
