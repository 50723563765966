import {NavLink} from 'react-router-dom';
import RoutesArr from '../../RoutesArr';

const Navigation = (props) => {
 return (
  <ul className="navbar-nav me-auto">
    {RoutesArr.map((row,index) => {
      if(props.settings.userProfile && props.settings.userProfile.user_master_id > 0 && row.hideAfterLogin) return;
      return  row.menuItem && <li key={index} className="nav-item">
                <NavLink to={row.path} className="nav-link" onClick={()=>props.setIsOpen(!props.isOpen)}>
                  <i className={row.className}></i>
                  {row.title}
                </NavLink>
              </li>
    })}
   
  </ul>)
};
export default Navigation;